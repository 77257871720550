import styled from "styled-components";
import { Styles } from "../enums/Styles";

export const ListTable = styled.table`
  margin: 0 auto;
  border: 1px solid #BBB;
  border-spacing: 0;
  width: 100%;
  text-align: left;
  th, td {
    padding-left: 5px;
    padding-right: 5px;
    height: 41px;
    border: 1px solid #BBB;
    border-bottom: none;
    border-right: none;
  }
  th {
    font-weight: 500;
    text-align:center;
    color: #444;
    border-top: none;
    border-bottom: 1px solid #BBB;
  }
  tr:nth-child(even) {
    background: ${Styles.AsuGray6};
  }
  tr:nth-child(odd) {
    background: ${Styles.AsuWhite};
  }

  caption {
    text-align: left;
    margin-bottom: 10px;
  }
`;

export const ListTableHeaderRow = styled.tr`
  height: 35px;
  width: 100%;
  border-left: none;
`;

export const NoResultsRow = styled.td`
  border-left: none !important; 
  text-align: center;
`;