import React from 'react';
import {useIntegrationDispatch, useIntegrationStore} from "../integration-store";

const PostSignRedirect = () => {
  const dispatch = useIntegrationDispatch();
  const {config, formError} = useIntegrationStore();

  const handlePostSignUrlChange = (value: string) => {
    validateUrl(value);
    dispatch({type: 'setConfig', payload: {
      config: {
        ...config,
        postSignRedirect: value || ''
      }
    }});
  }

  const validateUrl = (url: string) => {
    const adobeUrlValidation = /^(http|https):\/\/[^ "]+$/;
    let hasError = false;
    if (url !== "" && !adobeUrlValidation.test(url)) {
      hasError = true;
    }
    dispatch({type: 'setFormError', errorField: {
      postSignRedirect: hasError
    }});
  }

  return (
    <div className={'form-group'}>
      <label htmlFor={'integration-post-sign-url-field'}>Post-Sign Redirect URL: </label>
      <input
        id={"integration-post-sign-url-field"}
        inputMode={'text'}
        value={config.postSignRedirect || ''}
        onChange={(event) => handlePostSignUrlChange(event.target.value)}
      />
      {formError.postSignRedirect ? (
        <div className={'error-info'}>Must use an absolute URL. (e.g. https://asu.edu)</div>
      ) : null}
    </div>
  );
}

export { PostSignRedirect };