import React from 'react';
import {QueueOptions} from "./enums/queueOptions";
import {useIntegrationDispatch, useIntegrationStore} from "../../integration-store";

const integrationLabels: {[key: string]: string} = {
  [QueueOptions.BfitArchive]: 'BFIT Archive',
  [QueueOptions.DataWarehouse]: 'Data Warehouse Archive',
  [QueueOptions.PlusAllianceStudentGroup]: 'Plus Alliance Student Group',
  [QueueOptions.WorkdaySupplierDomestic]: 'Workday Supplier - Domestic',
  [QueueOptions.WorkdaySupplierForeign]: 'Workday Supplier - Foreign'
}

const IntegrationQueues = () => {
  const dispatch = useIntegrationDispatch();
  const {integrationQueues} = useIntegrationStore();

  const handleQueueChange = (queue: string) => {
    dispatch({type: 'setIntegrationQueues', payload: {
      integrationQueues: [queue]
    }});
  }

  return (
      <div className={'form-group'}>
        <label htmlFor="external-system">Send to External System:</label>
        <select
          id="external-system"
          style={{background: "#fff"}}
          value={integrationQueues[0]}
          onChange={(event) => handleQueueChange(event.target.value)}
        >
          <option value={''}>None</option>
          {Object.keys(integrationLabels).map(integrationKey => {
            return <option key={'option-'+integrationKey} value={integrationKey}>{integrationLabels[integrationKey]}</option>
          })}
        </select>
      </div>
  );
}

export { IntegrationQueues };