import styled from "styled-components";
import { TraingleExclamation } from "../ActionIcon";

export interface FieldAlert {
  id: string,
  message: string
}

export enum FieldAlertsRole {
  Error,
  Warning
}

interface Props {
  showAlerts?: boolean,
  alerts: FieldAlert[],
  role?: FieldAlertsRole
}

const AlertList = styled.ul`
  margin: 5px 0;
  padding: 0;
  list-style-type: none;
`;

const FieldAlerts: React.FC<Props> = ({alerts, showAlerts = true, role = FieldAlertsRole.Error}) => {
  if (!showAlerts || !alerts.length) {
    return null;
  }

  const className = role === FieldAlertsRole.Error
    ? 'error-info'
    : 'warning-info';
  const idPart = role === FieldAlertsRole.Error
    ? 'error'
    : 'warning';
  const icon = role === FieldAlertsRole.Error
    ? <TraingleExclamation />
    : null;

  return (
    <div role="alert" aria-atomic="true">
      <AlertList>
        {alerts.map((alert, index) => (
          <li key={index}>
            <a href={`#${alert.id}`} id={`${idPart}_${index}_${alert.id}`} className={className}>
              {icon} <b>{alert.message}</b>
            </a>
          </li>
        ))}
      </AlertList>
    </div>
  );
};

export {FieldAlerts};