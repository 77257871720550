import { useState } from "react";
import styled from "styled-components";
import { ParticipantRole } from "../../../../hooks/use-templates/enums";
import { ListTable, ListTableHeaderRow } from "../../../Table";
import { DeleteIcon, EditIcon } from "../../../ActionIcon";
import {ActionButtonsContainer, ConfirmDeletePrompt, TextButton, ToolTipButton, ToolTipIconButton} from "../../../Button";
import { useIntegrationDispatch, useIntegrationStore } from "../../integration-store";
import { ParticipantSet } from "./ParticipantSet";
import { ParticipantSetProvider } from "./participant-set-store";
import { setFocusOn } from "../../../../utils/document-interactions";
import { ParticipantSetConfig, TemplateConfig } from "asu-types";

const ParticipantTable = styled(ListTable)`
  margin-top: 10px;
  margin-bottom: 10px;
  table-layout:fixed
`;

const HeaderRow = styled(ListTableHeaderRow)`
  th:nth-child(1) { // Participant Number
    width: 5%;
    border-left: none;
    text-align: left;
  }
  th:nth-child(2) { // Order
    width: 10%;
  }
  th:nth-child(3) { // Role
    width: 20%;
  }
  th:nth-child(4) { // Email
    width: 50%;
  }
  th:nth-child(5) { // Actions
    width: 15%;
  }
`;

const OverflowDiv = styled.div`
  overflow-x: auto;
`;

const Participants = () => {
  const dispatch = useIntegrationDispatch();
  const {config}: {config: TemplateConfig} = useIntegrationStore();
  const [editParticipantSet, setEditParticipantSet] = useState<ParticipantSetConfig | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<number|'new'>(-1);
  const [editParticipantSetIndex, setEditParticipantSetIndex] = useState<number|'new'>(-1);
  const [editParticipantSetNumber, setEditParticipantSetNumber] = useState<number>(-1);

  const cancelEditParticipantSet = () => {
    if (editParticipantSetIndex === 'new') {
      setFocusOn('add-participant-set');
    }
    else {
      setFocusOn(`edit-participant-${editParticipantSetIndex}`);
    }

    setEditParticipantSet(null);
    setEditParticipantSetIndex(-1);
  }
  
  const setEditMode = (index: number) => {
    setEditParticipantSetIndex(index);
    setEditParticipantSet(config.participantSets[index]);
    setEditParticipantSetNumber(index+1);
  }

  const addParticipantSet = () => {
    /**
     * Currently, all scenarios require the first participant to
     * be the authenticated user that visits the link because they
     * are redirected to the agreement when visiting an integration url
     */
    if (!config.participantSets.length) {
      config.participantSets.push({
        role: ParticipantRole.SIGNER,
        label: undefined,
        memberInfos: [],
        includeAuthenticatedUser: true,
        order: 1
      });
    }

    setEditParticipantSetIndex('new');
    setEditParticipantSetNumber(config.participantSets.length + 1);
    setEditParticipantSet({
      role: ParticipantRole.SIGNER,
      label: undefined,
      memberInfos: [],
      includeAuthenticatedUser: false,
      order: config.participantSets.length + 1
    });
  }

  const saveParticipantSet = (index: number|'new', participantSetData: ParticipantSetConfig) => {
    if (index === 'new') {
      config.participantSets.push(participantSetData);
    } else {
      setFocusOn(`edit-participant-${index}`);
      config.participantSets[index] = participantSetData;
    }

    dispatch({
      type: 'setConfig',
      payload: {
        config
      }
    });
    cancelEditParticipantSet();
  }

  const deleteParticipantSet = (index: number) => {
    setConfirmDelete(-1);
    config.participantSets.splice(index, 1);
    dispatch({
      type: 'setConfig',
      payload: {
        config
      }
    });
  }

  const cancelDelete = () => {
    setFocusOn(`delete-participant-${confirmDelete}`);
    setConfirmDelete(-1)
  };

  return (
    <div className={'form-group'}>
      <label aria-label={'Template Participants'}>Participants: </label>
      {config.participantSets.length ? (
        <ParticipantTable>
          <tbody>
            <HeaderRow>
              <th>#</th>
              <th>Order</th>
              <th>Role</th>
              <th>Participant Email</th>
              <th>Actions</th>
            </HeaderRow>
            {config.participantSets.map((participantSet: ParticipantSetConfig, index: number) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{participantSet.order}</td>
                  <td>
                    <OverflowDiv>{participantSet.role}</OverflowDiv>
                  </td>
                  <td>
                    <OverflowDiv>{participantSet.includeAuthenticatedUser ? 'Initiator Email' : participantSet.memberInfos.map(x => x.email).join(',')}</OverflowDiv>
                  </td>
                  <td>
                    <ActionButtonsContainer>
                      {confirmDelete === index ? (
                          <ConfirmDeletePrompt>
                            <div>
                              Delete?
                            </div>
                            <div className={'prompt-buttons'}>
                              <TextButton
                                autoFocus
                                textColor={'orange'}
                                onClick={() => deleteParticipantSet(index)}
                                aria-label="Confirm delete participant set"
                              >Yes</TextButton>
                              <TextButton
                                textColor={'blue'}
                                onClick={cancelDelete}
                                aria-label="Cancel delete participant set"
                              >No</TextButton>
                            </div>
                          </ConfirmDeletePrompt>
                      ) : index !== 0 ? (
                        <>
                          <div>
                            <ToolTipIconButton
                              id={`edit-participant-${index}`}
                              onClick={() => setEditMode(index)}
                              toolTip={`Edit participant set`}
                              disabled={editParticipantSet}
                            ><EditIcon /></ToolTipIconButton>
                          </div>
                          <div>
                            <ToolTipIconButton
                              id={`delete-participant-${index}`}
                              onClick={() => setConfirmDelete(index)}
                              toolTip={`Delete participant set`}
                              disabled={editParticipantSet}
                            ><DeleteIcon /></ToolTipIconButton>
                          </div>
                        </>
                      ) : null}
                    </ActionButtonsContainer>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </ParticipantTable>
      ) : null}
      
      {editParticipantSet ? (
        <ParticipantSetProvider>
          <ParticipantSet
            participantNumber={editParticipantSetNumber}
            objectIndex={editParticipantSetIndex}
            objectData={editParticipantSet}
            cancelEdit={cancelEditParticipantSet}
            saveObject={saveParticipantSet}
          />
        </ParticipantSetProvider>
      ) : null}
      {!editParticipantSet ? (
        <div className={'add-button-container'}>
          <ToolTipButton
              id="add-participant-set"
              toolTip='Add Participant to this integration.'
              variant={'secondary'}
              className={editParticipantSetIndex === 'new' ? 'disabled' : ''}
              disabled={editParticipantSetIndex === 'new'}
              onClick={() => addParticipantSet()}>Add Participant</ToolTipButton>
        </div>
      ) : null}
    </div>
  );
}

export { Participants }