import { RecipientConfig, WorkflowFullConfig } from "asu-types";
import { useCallback, useReducer } from "react";

enum WorkflowStoreAction {
  SetEnabled,
  SetRequireSSO,
  SetRedirectSenderToSignerUrl,
  SetHideRecipients,
  SetSendOnBehalfOfSsoUser,
  SetSendOnBehalfOf,
  SetSenderFields,
  SetRecipientConfigs,
  SetInlcudeODSData,
  SetPostSignRedirect,
  SetAvailabilityStart,
  SetAvailabilityEnd
}

const workflowReducer = (state: WorkflowFullConfig, action: {payload: Partial<WorkflowFullConfig>, type: WorkflowStoreAction}): WorkflowFullConfig => {
  switch (action.type) {
    case WorkflowStoreAction.SetEnabled:
      return {
        ...state,
        enabled: action.payload.enabled ?? state.enabled
      };
    case WorkflowStoreAction.SetRequireSSO:
      const requireSSO = action.payload.requireSSO ?? state.requireSSO;
      return {
        ...state,
        requireSSO,
        sendOnBehalfOfSsoUser: false,
        includeODSData: false
      }
    case WorkflowStoreAction.SetRedirectSenderToSignerUrl:
      return {
        ...state,
        redirectSenderToSignerUrl: action.payload.redirectSenderToSignerUrl ?? state.redirectSenderToSignerUrl
      }
    case WorkflowStoreAction.SetHideRecipients:
      return {
        ...state,
        hideRecipients: action.payload.hideRecipients ?? state.hideRecipients
      }
    case WorkflowStoreAction.SetSendOnBehalfOfSsoUser:
      return {
        ...state,
        sendOnBehalfOfSsoUser: action.payload.sendOnBehalfOfSsoUser ?? state.sendOnBehalfOfSsoUser,
        sendOnBehalfOf: ''
      }
    case WorkflowStoreAction.SetSendOnBehalfOf:
      return {
        ...state,
        sendOnBehalfOf: action.payload.sendOnBehalfOf ?? state.sendOnBehalfOf,
      }
    case WorkflowStoreAction.SetSenderFields:
      return {
        ...state,
        senderFields: action.payload.senderFields ?? state.senderFields,
      }
    case WorkflowStoreAction.SetRecipientConfigs:
      return {
        ...state,
        recipientConfigs: action.payload.recipientConfigs ?? state.recipientConfigs,
      }
    case WorkflowStoreAction.SetInlcudeODSData:
      return {
        ...state,
        includeODSData: action.payload.includeODSData ?? state.includeODSData,
      }
    case WorkflowStoreAction.SetPostSignRedirect:
      return {
        ...state,
        postSignRedirect: action.payload.postSignRedirect || null,
      }
    case WorkflowStoreAction.SetAvailabilityStart:
      return {
        ...state,
        availabilityStart: action.payload.availabilityStart || null,
      }
    case WorkflowStoreAction.SetAvailabilityEnd:
      return {
        ...state,
        availabilityEnd: action.payload.availabilityEnd || null,
      }
    default:
      throw new Error(`Supplied action type ${action.type} not supported`);
  }
};

const useWorkflowStore = (intialState: WorkflowFullConfig) => {
  const [workflow, dispatch] = useReducer(workflowReducer, intialState);

  return {
    workflow,
    setEnabled: useCallback((enabled: boolean) =>
      dispatch({payload: {enabled}, type: WorkflowStoreAction.SetEnabled}), []),
    setRequireSSO: useCallback((requireSSO: boolean) =>
      dispatch({payload: {requireSSO}, type: WorkflowStoreAction.SetRequireSSO}), []),
    setRedirectSenderToSignerUrl: useCallback((redirectSenderToSignerUrl: boolean) =>
      dispatch({payload: {redirectSenderToSignerUrl}, type: WorkflowStoreAction.SetRedirectSenderToSignerUrl}), []),
    setHideRecipients: useCallback((hideRecipients: boolean) =>
      dispatch({payload: {hideRecipients}, type: WorkflowStoreAction.SetHideRecipients}), []),
    setSendOnBehalfOfSsoUser: useCallback((sendOnBehalfOfSsoUser: boolean) =>
      dispatch({payload: {sendOnBehalfOfSsoUser}, type: WorkflowStoreAction.SetSendOnBehalfOfSsoUser}), []),
    setSendOnBehalfOf: useCallback((sendOnBehalfOf: string) =>
      dispatch({payload: {sendOnBehalfOf}, type: WorkflowStoreAction.SetSendOnBehalfOf}), []),
    setSenderFields: useCallback((senderFields: string[]) =>
      dispatch({payload: {senderFields}, type: WorkflowStoreAction.SetSenderFields}), []),
    setRecipientConfigs: useCallback((recipientConfigs: RecipientConfig[]) =>
      dispatch({payload: {recipientConfigs}, type: WorkflowStoreAction.SetRecipientConfigs}), []),
    setIncludeODSData: useCallback((includeODSData: boolean) =>
      dispatch({payload: {includeODSData}, type: WorkflowStoreAction.SetInlcudeODSData}), []),
    setPostSignRedirect: useCallback((postSignRedirect: string) =>
      dispatch({payload: {postSignRedirect}, type: WorkflowStoreAction.SetPostSignRedirect}), []),
    setAvailabilityStart: useCallback((availabilityStart: number|null) =>
      dispatch({payload: {availabilityStart}, type: WorkflowStoreAction.SetAvailabilityStart}), []),
    setAvailabilityEnd: useCallback((availabilityEnd: number|null) =>
      dispatch({payload: {availabilityEnd}, type: WorkflowStoreAction.SetAvailabilityEnd}), []),
  }
};

export { WorkflowStoreAction, useWorkflowStore };