import { AgreementCreateCCInfo, AgreementCreateFileInfo, AgreementCreateMergeFieldInfo, AgreementCreateState, ParticipantSetInfoCreate, SignatureType } from "adobe-sign-types";
import { WorkflowFullConfig } from "asu-types";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSendWorkflow } from "../../hooks/workflows/use-workflows";
import { Button } from "../Button";
import { CenterMessage, IntegrationForm } from "../CommonElements";
import { Participants } from "./Participants/Participants";
import { useSenderFormStore } from "./sender-form-store";
import { SenderFields } from "./SenderFields/SenderFields";

const SubmitButtonDiv = styled.div`
  padding: 20px;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

interface Props {
  workflowConfig: WorkflowFullConfig,
  senderEmail?: string
}

const SenderForm: React.FC<Props> = ({workflowConfig, senderEmail}) => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [formErrors, setFormErrors] = useState<{[key: string]: boolean}>({});
  const {mutate: sendWorkflow, isLoading: workflowSending, isSuccess: workflowSent, isError: workflowSendErrored, data: workflowSendResponse} = useSendWorkflow();
  const {
    senderFormData,
    setSenderFieldAnswers,
    setMemebers
  } = useSenderFormStore(workflowConfig, senderEmail);

  const setFormError = useCallback((formError: {[key:string]: boolean}) => {
    setFormErrors(previousValue => ({
      ...previousValue,
      ...formError
    }));
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).find(key => formErrors[key])) {
      setFormIsValid(false);
      return;
    }

    setFormIsValid(true);
  }, [formErrors]);

  const submitForm = () => {
    let fileInfos: AgreementCreateFileInfo[] = workflowConfig.adobeSignWorkflow.fileInfos
      .filter(fileInfo => fileInfo.workflowLibraryDocumentSelectorList)
      .map(fileInfo => ({
        label: fileInfo.label,
        libraryDocumentId: fileInfo.workflowLibraryDocumentSelectorList![0].workflowLibDoc
      }));
    let mergeFieldInfo: AgreementCreateMergeFieldInfo[] = Object.keys(senderFormData.senderFieldAnswers).map(fieldName => ({
      fieldName,
      defaultValue: senderFormData.senderFieldAnswers[fieldName]
    }));
    let participantSetsInfo: ParticipantSetInfoCreate[] = senderFormData.participantSets
      .filter(participantSet => participantSet.memberInfos.find(member => member.email))
      .map(participantSet => ({
        label: participantSet.label,
        memberInfos: participantSet.memberInfos.filter(member => member.email),
        role: participantSet.role
      }));
    let ccs: AgreementCreateCCInfo[] = [];

    if (workflowConfig.adobeSignWorkflow.ccsListInfo) {
      workflowConfig.adobeSignWorkflow.ccsListInfo.forEach(ccsListInfo => {
        if (ccsListInfo.defaultValues) {
          ccs.push(...(ccsListInfo.defaultValues.map(email => ({
            label: ccsListInfo.label,
            email
          }))))
        }
      });
    }

    sendWorkflow({
      workflowId: workflowConfig.id,
      fileInfos,
      name: workflowConfig.adobeSignWorkflow.displayName,
      participantSetsInfo,
      mergeFieldInfo,
      signatureType: SignatureType.ESIGN,
      state: AgreementCreateState.InProcess,
      postSignOption: workflowConfig.postSignRedirect ? {
        redirectDelay: 1,
        redirectUrl: workflowConfig.postSignRedirect
      } : undefined,
      ccs
    });
  };

  if (workflowSendResponse && workflowSendResponse.signingUrl && workflowConfig.redirectSenderToSignerUrl) {
    setTimeout(() => {
      window.location.assign(workflowSendResponse.signingUrl!);
    }, 1000);
    return <CenterMessage message="Workflow sent successfully. Redirect to Adobe Sign..." />
  }
  else if (workflowSending) {
    return <CenterMessage message="Workflow sending..." />;
  }
  else if (workflowSent) {
    return <CenterMessage message="Workflow sent successfully" />
  }
  else if (workflowSendErrored) {
    return <CenterMessage message="Encountered error while sending workflow" />
  }

  return (
    <IntegrationForm>
      <SenderFields
        senderFields={senderFormData.senderFields}
        onFormErrorsChange={setFormError}
        senderFieldAnswers={senderFormData.senderFieldAnswers}
        onSenderFieldAnswersChange={setSenderFieldAnswers} 
      />
      <Participants
        participantSets={senderFormData.participantSets}
        onFormErrorsChange={setFormError}
        onMembersChange={setMemebers}
        hideNonEditableMembers={workflowConfig.hideRecipients}
      />
      <SubmitButtonDiv>
        <SubmitButton disabled={!formIsValid} onClick={submitForm}>Submit</SubmitButton>
      </SubmitButtonDiv>
    </IntegrationForm>
  );
};

export { SenderForm };