import { ComparatorType } from "../enums/comparator-type"

export class ConditionalRoutingCondition {
  fieldName: string;
  comparatorType: ComparatorType;
  comparatorValue: string;

  constructor(data: ConditionalRoutingCondition) {
    this.fieldName = data.fieldName;
    this.comparatorType = data.comparatorType;
    this.comparatorValue = data.comparatorValue;
  }
}