import { useEffect } from "react";

export const useRemoveFormErrorOnUnmount = (id: string, onFormErrorsChange?: (formError: {[key:string]: boolean}) => void) => {
  useEffect(() => {
    /**
     * On unmount we want to remove any errors, we want this
     * to happen only on unmount so we have an empty dependency
     * list and ignore the react-hooks/exhaustive-deps warning
     */
    return () => {
      if (onFormErrorsChange) {
        onFormErrorsChange({
          [id]: false
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};