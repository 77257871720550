import React from 'react';
import {useIntegrationDispatch, useIntegrationStore} from "../integration-store";

const SenderEmail = () => {
  const dispatch = useIntegrationDispatch();
  const {config} = useIntegrationStore();

  const handleSenderEmailChange = (value: string) => {
    dispatch({type: 'setConfig', payload: {
        config: {
          ...config,
          senderEmail: value
        }
      }});
  }

  return (
    <div className={'form-group'}>
      <label htmlFor={'integration-sender-email-field'}>Send on Behalf of: </label>
      <input
        id={"integration-sender-email-field"}
        inputMode={'text'}
        value={config.senderEmail || ''}
        onChange={(event) => handleSenderEmailChange(event.target.value)}
      />
    </div>
  );
}

export { SenderEmail };