import { ParticipantSetConfig } from "./participant-set-config";

export class TemplateConfig {
  owners: string[];
  postSignRedirect: string | null;
  senderEmail: string | null;
  workflowId: string | null;
  workflowLabel: string | null;
  participantSets: ParticipantSetConfig[];
  disableInitEmails: boolean;
  ccs: string[];

  constructor (data?: Partial<TemplateConfig>) {
    this.owners = data?.owners || [];
    this.postSignRedirect = data?.postSignRedirect || null;
    this.senderEmail = data?.senderEmail || null;
    this.workflowId = data?.workflowId || null;
    this.workflowLabel = data?.workflowLabel || null;
    this.participantSets = data?.participantSets?.map(x => new ParticipantSetConfig(x)) || [];
    this.disableInitEmails = data?.disableInitEmails ?? false;
    this.ccs = data?.ccs || [];
  }
}