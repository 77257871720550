import React, {useState} from 'react';
import {Link, Navigate} from "react-router-dom";
import {Button} from "../../../../components/Button";
import {useValidateAdobeTemplate} from "../../../../hooks/use-templates";
import styled from "styled-components";
import { TemplateType } from '../../../../hooks/use-templates/enums';
import { Styles } from '../../../../enums/Styles';

const AddIntegrationContainer = styled.div`
  padding-bottom: 10px;
  
  .getStartedMessage {
    width: 80%; 
    padding-bottom: 5px;
    label {
      font-size: 12px;
      font-weight: 600;
    }
  }
  
  .validationFormContainer {
    display: flex;
    justify-content: space-between;
    input {
      width: 80%;
    }
    .validationSubmit {
      width: 20%; 
      text-align: center;
    }
  }
  
  .validationMessageContainer {
    width: 80%; 
    text-align: center;
    padding-top: 10px;
    
    .message {
      font-size: 12px; 
      font-weight: 600;
    }
    
    .failed {
      color: ${Styles.ErrorRed};
    }
  }
`;

interface Props {
  existingIds: string[];
}

const AddTemplate: React.FC<Props> = ({existingIds}) => {
  const [fieldOpen, setFieldOpen] = useState(false);
  const [validatingTemplate, setValidatingTemplate] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const [templateExists, setTemplateExists] = useState(false);
  const adobeInfo = useValidateAdobeTemplate(templateId);

  const handleTextChange = (value: string) => {
    setTemplateExists(false);
    setValidatingTemplate(false);
    setTemplateId(value);
    setFailureMessage('');
  }

  const validateAdobeTemplate = () => {
    adobeInfo.remove();
    setFailureMessage('');
    if (existingIds.includes(templateId)) {
      setTemplateExists(true);
    } else {
      adobeInfo.refetch();
      setValidatingTemplate(true);
    }
  }

  if (!failureMessage && validatingTemplate && adobeInfo.status === 'success' && adobeInfo.data) {
    if (adobeInfo.data.templateTypes?.includes(TemplateType.DOCUMENT)) {
      return <Navigate to={`/template/new`} state={{ templateId: templateId }} />
    }
    else {
      setFailureMessage(`Template type must be either 'Resuable document' or 'Both'.  Please modify template type in Adobe Sign.`);
    }
  }

  return (
    <AddIntegrationContainer>
      {fieldOpen ? (
        <>
          <div className={'getStartedMessage'}>
            <label htmlFor={'adobe-template-id-field'}>To begin, enter the Adobe Sign Template ID</label>
          </div>
          <div className={'validationFormContainer'}>
            <input
              id={"adobe-template-id-field"}
              inputMode={'text'}
              value={templateId}
              autoComplete="off"
              autoFocus
              onChange={(event) => handleTextChange(event.target.value)}
            />
            <div className={'validationSubmit'}>
              <Button onClick={validateAdobeTemplate}>Submit</Button>
            </div>
          </div>
          {validatingTemplate ? (
            <div className={'validationMessageContainer'}>
              {adobeInfo.status === 'loading' ? (
                <div className={'message'}>Validating template id...</div>
              ) : (
                <div className={'message failed'}>{failureMessage || 'Failed to validate template'}</div>
              )}
            </div>
          ) : null}
          {templateExists ? (
            <div className={'validationMessageContainer'}>
              <div className={'message'}>ID already associated with an integration.</div>
              <div className={'message'}>Click <Link to={`/template/${templateId}/edit`}>here</Link> to edit.</div>
            </div>
          ) : null}
        </>
      ) : (
        <Button onClick={() => setFieldOpen(true)}>Create</Button>
      )}
    </AddIntegrationContainer>
  );
}

export default AddTemplate;