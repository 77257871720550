import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {useAuth} from "../auth/controller";
import useErrorHandler from "./use-error-handler";
import { GetTemplatesModel, Template } from "asu-types";

const API_URL = process.env.REACT_APP_ADSIGN_API_URL;

const useGetTemplate = (id: string) => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  return useQuery<Template, Error>(["template", id], async () => {
    const {data} = await axios.get(`${API_URL}/templates/${id}`, {
      headers: {
        'Authorization': 'Bearer ' + await auth.getToken()
      }
    });
    return new Template(data);
  },{
    retry: ((failureCount) => failureCount < 1),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: errorHandler.handleError,
  });
}

const useGetTemplates = () => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  return useQuery<GetTemplatesModel[], Error>(["templates"], async () => {
    const {data} = await axios.get(`${API_URL}/templates`, {
      headers: {
        'Authorization': 'Bearer ' + await auth.getToken()
      }
    });
    return data;
  }, {
    retry: ((failureCount) => failureCount < 1),
    onError: errorHandler.handleError,
  });
}

const useExportTemplates = () => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  return useQuery<string, Error>(["exportTemplates"], async () => {
    console.log('exporting templates');
    const {data} = await axios.get(`${API_URL}/templates/export?timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, {
      headers: {
        'Authorization': 'Bearer ' + await auth.getToken()
      }
    });
    return data;
  }, {
    enabled: false,
    onError: errorHandler.handleError
  });
}

const useGetAdobeTemplate = (id: string) => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  return useQuery<any, Error>(["adobe-template", id], async () => {
    const {data} = await axios.get(`${API_URL}/templates/external/${id}`, {
      headers: {
        'Authorization': 'Bearer ' + await auth.getToken()
      }
    });
    return data;
  }, {
    retry: ((failureCount) => failureCount < 1),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: errorHandler.handleError,
  });
}

const useValidateAdobeTemplate = (id: string) => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  return useQuery<any, Error>(["adobe-template", id], async () => {
    const {data} = await axios.get(`${API_URL}/templates/external/${id}`, {
      headers: {
        'Authorization': 'Bearer ' + await auth.getToken()
      }
    });
    return data;
  }, {
    retry: ((failureCount) => failureCount < 1),
    refetchOnWindowFocus: false,
    onError: errorHandler.handleError,
    enabled: false
  });
}

const useUpdateTemplate = (id: string) => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();
  return useMutation<void, Error, Template>( async (mutationData: Template) =>
      axios.put(`${API_URL}/templates`, mutationData, {
        headers: {
          'Authorization': 'Bearer ' + await auth.getToken()
        }
      }), {
        onError: (error) => {
          errorHandler.handleError(error);
          return;
        },
        onSuccess: () => {
          queryClient.invalidateQueries(["template", id])
        }
      }
  );
}

const useDeleteTemplate = (id: string) => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();
  return useMutation<void, Error>( async () => axios.delete(`${API_URL}/templates/${id}`, {
    headers: {
      'Authorization': 'Bearer ' + await auth.getToken()
    }
  }), {
    onError: (error) => {
      errorHandler.handleError(error);
      return;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["templates"]);
      queryClient.invalidateQueries(["template", id]);
    }
  });
}

const useEnableTemplate = (id: string) => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();
  return useMutation<void, Error, void>( async () =>
    axios.post(`${API_URL}/templates/${id}/enable`, null, {
      headers: {
        'Authorization': 'Bearer ' + await auth.getToken()
      }
    }), {
      onError: (error) => {
        errorHandler.handleError(error);
        return;
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["templates"]);
        queryClient.invalidateQueries(["template", id]);
      }
    }
  );
}

const useDisableTemplate = (id: string) => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();
  return useMutation<void, Error, void>( 
    async () => {
      const {data}: {data: Template} = await axios.get(`${API_URL}/templates/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + await auth.getToken()
        }
      });
      await axios.put(`${API_URL}/templates`, {...data, enabled: false}, {
        headers: {
          'Authorization': 'Bearer ' + await auth.getToken()
        }
      });
    },
    {
      onError: (error) => {
        errorHandler.handleError(error);
        return;
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["templates"]);
        queryClient.invalidateQueries(["template", id]);
      }
    }
  );
}

export {
  useGetTemplates,
  useGetTemplate,
  useUpdateTemplate,
  useDeleteTemplate,
  useValidateAdobeTemplate,
  useGetAdobeTemplate,
  useEnableTemplate,
  useDisableTemplate,
  useExportTemplates
}