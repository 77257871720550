import styled from "styled-components";
import { Styles } from "../../enums/Styles";

export const CheckboxInput = styled.input`
  vertical-align: middle;
  margin-right: 12px;
`;

export const IntegrationForm = styled.div`
  .form-group {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    .error-field {
      border: solid 1px ${Styles.ErrorRed};
    }

    .error-field:focus-visible {
      border-width: 2px;
    }

    .error-field:focus-within {
      border-width: 2px;
    }
  }

  .form-group label {
    font-weight: 600;
    padding: 3px;
  }

  .form-group input {
    height: 30px;
    border: 1px solid ${Styles.AsuGray4};
    border-radius: 3px;
    padding-left: 8px;
  }

  .form-group textarea {
    border: 1px solid ${Styles.AsuGray4};
    border-radius: 3px;
    padding-left: 8px;
    font-family: inherit;
    font-size: inherit;
  }

  .form-group select {
    height: 34px;
    border: 1px solid ${Styles.AsuGray4};
    border-radius: 3px;
    padding-left: 4px;
  }
  
  .form-group .add-button-container {
    padding-top: 5px;
  }
  
  .error-info {
    color: ${Styles.ErrorRed};
    font-size: 12px;
    padding-top: 2px;
    padding-left: 5px;
  }

  .warning-info {
    color: #5C5757;
    font-size: 12px;
    padding-top: 2px;
    padding-left: 5px;
  }

  .disabled {
    opacity: 50%;
    cursor: default;
  }

  fieldset {
    border-radius: 3px;
    margin: 0;
    border: 1px solid ${Styles.AsuGray4};
  }

  input[type=radio] {
    outline: none;
  }
`;



export const CancelLink = styled.a`
  float: right;
  color: #747474;
  cursor: pointer;
`;

export const CenterMessage = ({message}: {message: string}) => {
  return (
    <div className={'container'}>
      <div style={{paddingTop: 100, textAlign: 'center', fontSize: 'x-large'}}>{message}</div>
    </div>
  );
}

export const AlignCenter = styled.div`
  text-align: center;
`;

export const RequiredAsterisk = ({required}: {required: boolean}) => {
  return (
    <>
      {required ? (
        <span style={{color: Styles.ErrorRed}}>* </span>
      ) : null}
    </>
  )
}

export const SectionHeaderDiv = styled.div`
  font-size: x-large;
  font-weight: bold;
  margin-bottom: 10px;

  hr {
    border: solid 1px black;
  }
`;

export const SectionHeader: React.FC<any> = ({children, ...props}) => {
  return (
    <SectionHeaderDiv {...props}>
      {children}
      <hr />
    </SectionHeaderDiv>
  );
};
