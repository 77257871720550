const jwtStorageKey = 'jwtKey';
const refreshStorageKey = 'jwtRefresh';

const storeToken = (jwt: string) => {
  sessionStorage.setItem(jwtStorageKey, jwt);
};

const fetchToken = (): string|null => {
  return sessionStorage.getItem(jwtStorageKey);
};

const deleteToken = () => {
  sessionStorage.removeItem(jwtStorageKey);
};

const storeRefreshToken = (jwt: string) => {
  sessionStorage.setItem(refreshStorageKey, jwt);
};

const fetchRefreshToken = (): string|null => {
  return sessionStorage.getItem(refreshStorageKey);
};

const deleteRefreshToken = () => {
  sessionStorage.removeItem(refreshStorageKey);
};

export { fetchToken, storeToken, deleteToken, storeRefreshToken, fetchRefreshToken, deleteRefreshToken };
