export enum FormFieldValidation {
  None = "NONE",
  Number = "NUMBER",
  Email = "EMAIL",
  Currency = "CURRENCY",
  Date = "DATE",
  Time = "TIME",
  Zip = "ZIP",
  UsSsn = "SOCIAL_SEC",
  Phone = 'PHONE'
}