import React from "react";
import styled from "styled-components";
import { Styles } from "../enums/Styles";

const BaseButton = styled.button`
  width: 112px;
  max-width: 280px;
  height: 30px;
  line-height: 30px;
  margin: 0 auto;
  padding: 0;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
`;

interface ButtonProps {
  variant?: 'primary' | 'secondary';
}
const Button = styled(BaseButton)<ButtonProps>`
  background: ${props => props.variant === 'secondary' ? "#bfbfbf" : "#8C1D40"};
  color: ${props => props.variant === 'secondary' ? "#000" : "#FFF"};
  border: 1px solid ${props => props.variant === 'secondary' ? "#bfbfbf" : "#8C1D40"};
  border-radius: 15px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  opacity: ${props => props.disabled ? '0.4' : '1'};
`;

const ToolTipButtonSpan = styled.span`
  position: relative;
  display: inline-block;
  margin-left: 10px;

  :hover .tooltiptext {
    visibility: visible;
  }

  :focus-within .tooltiptext {
    visibility: visible;
  }
`;

interface TooltipProps {
  toolTip: string,
  [x:string]: any;
}

const ToolTipButton: React.FC<TooltipProps> = ({toolTip, ...props}) => {
  if (!props['aria-label']) {
    props['aria-label'] = toolTip;
  }

  return (
    <ToolTipButtonSpan className="tool-tip-span">
      <Button {...props}>{props.children}</Button>
      {toolTip ? (
        <span className="tooltiptext">{toolTip}</span>
      ) : null}
    </ToolTipButtonSpan>
  )
};

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  width: 20px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  opacity: ${props => props.disabled ? '0.4' : '1'};
`;

const ToolTipIconButton: React.FC<TooltipProps> = ({toolTip, ...props}) => {
  if (!props['aria-label']) {
    props['aria-label'] = toolTip;
  }
  
  return (
    <ToolTipButtonSpan className="tool-tip-span">
      <IconButton {...props}>{props.children}</IconButton>
      {toolTip ? (
        <span className="tooltiptext">{toolTip}</span>
      ) : null}
    </ToolTipButtonSpan>
  )
};

interface TextButtonProps {
  textColor?: 'orange'|'blue';
}
const TextButton = styled(IconButton)<TextButtonProps>(props => {
  let color  = '#8C1D40';
  switch (props.textColor) {
    case 'orange':
      color = Styles.AsuOrangeDarker;
      break;
    case 'blue':
      color = Styles.AsuBlueDarker;
      break;
    default:
      break;
  }
  return `
    width: fit-content;
    color: ${color};
  `;
});

const RadioButtonLabel = styled.label`
  text-align: left;
  font-weight: normal !important;
  letter-spacing: 0.08px;
  color: #191919;
`;

const RadioButton = styled.input`
  background: #FFFFFF 0% 0% no-repeat padding-box;
  accent-color: black;
  border: 1px solid #747474;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-top: -1px;
  vertical-align: middle;
`;

const ActionButtonsContainer = styled.div`
  min-width: 60px;
  display: flex;
  padding: 5px;
  justify-content: space-between;
  justify-items: center;
  align-content: center;
  align-items: center;
`;

const ConfirmDeletePrompt = styled.div`
  width: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  
  .prompt-buttons {
    display: flex;
    gap: 20px;
  }
`;

export {Button, IconButton, TextButton, RadioButtonLabel, RadioButton, ActionButtonsContainer, ConfirmDeletePrompt, ToolTipButton, ToolTipIconButton};