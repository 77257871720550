import { FormDataMappingMode } from "../enums/form-data-mapping-mode";
import { SqsQueue } from "../enums/sqs-queue";
import { FormDataMapping } from "./form-data-mapping";
import { TemplateConfig } from "./template-config";

export class Template {
  adobeSignId: string;
  name: string;
  formDataMappingMode: FormDataMappingMode;
  formDataMappings: FormDataMapping[];
  s3Dir: string;
  integrationQueues: SqsQueue[];
  config: TemplateConfig;
  enabled: boolean;
  createdBy: string;
  lastUpdatedBy: string;
  lastUsed: number;
  availabilityStart: number|null;
  availabilityEnd: number|null;

  constructor (data?: Partial<Template>) {
    this.adobeSignId = data?.adobeSignId || '';
    this.name = data?.name || '';
    this.formDataMappingMode = data?.formDataMappingMode || FormDataMappingMode.None;
    this.formDataMappings = data?.formDataMappings?.map(x => new FormDataMapping(x)) || [];
    this.s3Dir = data?.s3Dir || '';
    this.integrationQueues = data?.integrationQueues || [];
    this.config = new TemplateConfig(data?.config);
    this.enabled = data?.enabled ?? false;
    this.createdBy = data?.createdBy || '';
    this.lastUpdatedBy = data?.lastUpdatedBy || '';
    this.lastUsed = data?.lastUsed || 0;
    this.availabilityStart = data?.availabilityStart || null;
    this.availabilityEnd = data?.availabilityEnd || null;
  }
}