import { useEffect, useState } from "react";
import { useRemoveFormErrorOnUnmount } from "../../hooks/remove-form-error-on-unmount";
import { RequiredAsterisk } from ".";
import { ToolTip } from "../ToolTip";
import { FieldAlert, FieldAlerts } from "./FieldAlerts";

interface Props {
  id: string,
  label: string,
  formKey: string,
  value: string,
  onValueChange: (key: string, value: string) => void,
  required: boolean,
  disabled: boolean,
  options: [string, string][],
  onFormErrorsChange: (formError: {[key:string]: boolean}) => void,
  toolTip?: string,
  autoFocus?: boolean
}

const DropDownField: React.FC<Props> = ({id, label, formKey, value, onValueChange, required, disabled, options, onFormErrorsChange, toolTip, autoFocus = false}) => {
  const [errors, setErrors] = useState<FieldAlert[]>([]);
  const [fieldAttempted, setFieldAttempted] = useState(false);
  useRemoveFormErrorOnUnmount(id, onFormErrorsChange);

  const updateValue = (value: string) => {
    setFieldAttempted(true);
    onValueChange(formKey, value);
  };

  useEffect(() => {
    const newErrors: FieldAlert[] = [];

    if (required && !value) {
      newErrors.push({id: id, message: `${label} is required`});
    }

    if (value && !options.some(([key]) => key === value)) {
      newErrors.push({id: id, message: `The previously selected value for ${label} is no longer an option`});
    }

    setErrors(newErrors);
    onFormErrorsChange({
      [id]: !!newErrors.length
    });
  }, [id, label, onFormErrorsChange, options, required, value]);

  return (
    <div className={'form-group'}>
      <label htmlFor={id}><RequiredAsterisk required={required} />{label}{toolTip ? <ToolTip text={toolTip} /> : null}</label>
      <select
        autoFocus={autoFocus}
        id={id}
        inputMode={'text'}
        required={required}
        value={value || ''}
        onChange={(event) => updateValue(event.target.value)}
        disabled={disabled}
        className={errors.length && fieldAttempted ? 'error-field' : ''}
        onBlur={() => setFieldAttempted(true)}
      >
        <option value={''}>- Select Value -</option>
        {options
          .map(([key, display]) =>
            <option key={`${id}-${key}`} value={key}>
              {display}
            </option>
        )}
      </select>
      {!disabled ? (
        <FieldAlerts alerts={errors} showAlerts={fieldAttempted} />
      ) : null}
    </div>
  );
};

export { DropDownField }