import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { CenterMessage } from "../../components/CommonElements";
import { PageTitle } from "../../components/Layout";
import { WorkflowIntegration } from "../../components/WorkflowIntegration/WorkflowIntegration";
import { useGetWorkflow } from "../../hooks/workflows/use-workflows";

const WorkflowEdit = () => {
  const {workflowId} = useParams();
  const {isLoading, data} = useGetWorkflow(workflowId || '');

  return (
    <>
      <Breadcrumbs currentPageTitle="Edit Workflow" parentPage="Workflows" />
      <main id="skip-to-content">
        <PageTitle>
          Edit Workflow Integration
        </PageTitle>
        {isLoading ? (
          <CenterMessage message="Loading..." />
        ) : data ? (
          <WorkflowIntegration workflow={data}></WorkflowIntegration>
        ) : (
          <div>An error has occurred while loading the Workflow.</div>
        )}
      </main>
    </>
  );
};

export { WorkflowEdit };