import React, {useEffect, useState} from 'react';
import styled from "styled-components";

const MessageContainer = styled.div<{variant?: 'success'|'error'}>`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 500; 
  font-size: 12px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  background: ${props => props.variant === 'success' ? "#e9f5db" : "#f7dddd"};
  border: 1px solid ${props => props.variant === 'success' ? "#446d12" : "#cc2f2f"};
  color: ${props => props.variant === 'success' ? "#446d12" : "#cc2f2f"};
`;

interface Props {
  type: 'success' | 'error'
  message: string;
  timeout?: number;
}

const InfoAlert: React.FC<Props> = ({type, message, timeout}) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if(!message){
      setVisible(false)
      return
    }

    setVisible(true);
    if (timeout) {
      const timer = setTimeout(() => {
        setVisible(false)
      }, timeout);
      return () => clearTimeout(timer);
    }
  }, [message, timeout])

  if(!visible) return null;
  return (
      <MessageContainer variant={type} role="alert" onTransitionEnd={() => setVisible(false)}>
        {message}
      </MessageContainer>
  );
}

export default InfoAlert;