import React, {useEffect, useState} from 'react';
import TemplateFieldName from "./components/TemplateFieldName/TemplateFieldName";
import DataSource from "./components/DataSource/DataSource";
import styled from "styled-components";
import {useMappingDispatch, useMappingStore} from "./mapping-store";
import {ActionButtonsContainer, ConfirmDeletePrompt, TextButton, ToolTipIconButton} from "../../../../../Button";
import {EditIcon, CancelIcon, SaveIcon, DeleteIcon} from "../../../../../ActionIcon";
import {SourceOption} from "./components/DataSource/enums/sourceOption";
import { setFocusOn } from '../../../../../../utils/document-interactions';
import { FormDataMapping } from 'asu-types';

const SectionContainer = styled.div`
  width: 600px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

const MappingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const DataContainer = styled.div`
  width: 550px;
`;

interface Props {
  mappingIndex: number|'new';
  mappingData?: FormDataMapping;
  editMode: boolean;
  confirmDelete: boolean;
  setEditMode(index: number|'new'): void;
  setConfirmDelete(index: number|'new'): void;
  saveMapping(index: number|'new', mappingData: FormDataMapping): void;
  deleteMapping(index: number): void;
  cancelNewMapping(): void;
}

const DataMapping: React.FC<Props> = ({
  mappingIndex,
  mappingData,
  editMode, 
  confirmDelete,
  setConfirmDelete, 
  setEditMode,
  cancelNewMapping,
  saveMapping,
  deleteMapping
}) => {
  const mappingDispatch = useMappingDispatch();
  const {targetField, source, sourceField, defaultValue} = useMappingStore();
  const [errorMessage, setErrorMessage] = useState<null|string>(null);

  useEffect(() => {
    mappingDispatch({
      type: 'hydrateMapping',
      payload: {
        editMode: editMode,
        targetField: mappingData?.targetField || '',
        source: mappingData?.source || '',
        sourceField: mappingData?.sourceField || '',
        defaultValue: mappingData?.defaultValue || ''
      }
    });
  }, [mappingDispatch, mappingData, editMode]);

  const saveEdits = () => {
    if (targetField === '') {
      setErrorMessage('You must select a template field.');
    } else if (source === '') {
      setErrorMessage('You must select a data source.');
    } else if (sourceField === '' && defaultValue === '') {
      if (source === SourceOption.DefaultValue) {
        setErrorMessage('You must provide a default value.');
      } else if (source === SourceOption.PostData) {
        setErrorMessage('You must provide a POST body field name.');
      } else {
        setErrorMessage('You must select a source field.');
      }
    } else {
      setFocusOn(`edit-mapping-${mappingIndex}`);
      setErrorMessage(null);
      saveMapping(mappingIndex, {
        targetField,
        source,
        sourceField,
        defaultValue
      });
    }
  }

  const cancelEdits = () => {
    setFocusOn(`edit-mapping-${mappingIndex}`);
    if (mappingIndex === 'new') {
      cancelNewMapping();
    } else {
      setEditMode(-1);
    }
    setErrorMessage(null);
  }

  const deleteMapEntry = () => {
    setConfirmDelete(-1);
    if (mappingIndex === 'new') {
      cancelEdits();
    } else {
      deleteMapping(mappingIndex);
    }
  }

  const cancelDelete = () => {
    setFocusOn(`delete-mapping-${mappingIndex}`);
    setConfirmDelete(-1);
  }

  return (
    <SectionContainer>
      <MappingContainer>
        <DataContainer>
          <TemplateFieldName />
          <DataSource />
        </DataContainer>
        <ActionButtonsContainer>
          {editMode ? (
            <>
              <div>
                <ToolTipIconButton
                  onClick={saveEdits}
                  toolTip={`Save data mapping`}
                ><SaveIcon /></ToolTipIconButton>
              </div>
              <div>
                <ToolTipIconButton
                  onClick={cancelEdits}
                  toolTip={`Cancel editing data mapping`}
                ><CancelIcon /></ToolTipIconButton>
              </div>
            </>
          ) : confirmDelete ? (
              <ConfirmDeletePrompt>
                <div>
                  Delete?
                </div>
                <div className={'prompt-buttons'}>
                  <TextButton autoFocus textColor={'orange'} onClick={deleteMapEntry} aria-label="Confirm delete data mapping">Yes</TextButton>
                  <TextButton textColor={'blue'} onClick={cancelDelete} aria-label="Cancel delete data mapping">No</TextButton>
                </div>
              </ConfirmDeletePrompt>
          ) : (
            <>
              <div>
                <ToolTipIconButton
                  id={`edit-mapping-${mappingIndex}`}
                  onClick={() => setEditMode(mappingIndex)}
                  toolTip={`Edit data mapping`}
                ><EditIcon /></ToolTipIconButton>
              </div>
              <div>
                <ToolTipIconButton
                  id={`delete-mapping-${mappingIndex}`}
                  onClick={() => setConfirmDelete(mappingIndex)}
                  toolTip={`Delete data mapping`}
                ><DeleteIcon /></ToolTipIconButton>
              </div>
            </>
          )}
        </ActionButtonsContainer>
      </MappingContainer>
      {errorMessage ? <div className={'error-info'}>{errorMessage}</div> : null}
    </SectionContainer>
  );
}

export default DataMapping;