import Breadcrumbs from "../../components/Breadcrumbs";
import IntegrationsList from "../../components/IntegrationsList/IntegrationsList";
import { Integration } from "../../components/IntegrationsList/ListRow";
import { ListContainer, PageTitle } from "../../components/Layout";
import { useDeleteWorkflow, useDisableWorkflow, useEnableWorkflow, useExportWorkflows, useGetWorkflows } from "../../hooks/workflows/use-workflows";
import { CenterMessage } from "../../components/CommonElements";

const Workflows = () => {
  const {isLoading, isSuccess, data} = useGetWorkflows();
  const workflowIntegrations = data?.map(workflow => {
    return {
      id: workflow.id,
      name: workflow.displayName,
      url: `${window.location.origin}/workflow/${workflow.id}/sender-form${workflow.requireSSO ? '/sso' : ''}`,
      editPage: `/workflow/${workflow.id}/edit`,
      enabled: workflow.enabled,
      createdBy: workflow.createdBy,
      lastUpdateBy: workflow.lastUpdatedBy,
      lastUsed: workflow.lastUsed,
      groupName: workflow.groupName
    } as Integration;
  }).sort((a, b) => a.name.localeCompare(b.name)) || [];

  return (
    <>
      <Breadcrumbs currentPageTitle="Workflows" />
      <main id="skip-to-content">
        <PageTitle>Workflows</PageTitle>
        {isLoading ? (
          <CenterMessage message='Loading workflows...' />
        ) : isSuccess && data ? (
          <ListContainer>
            <IntegrationsList
              listKey="workflows"
              integrations={workflowIntegrations}
              noIntegrationsMessage="You do not have access to any workflows.  Please create or gain access to a workflow in Adobe Sign."
              urlTitle="Initiator Form Url"
              useDeleteIntegration={useDeleteWorkflow}
              useDisableIntegration={useDisableWorkflow}
              useEnableIntegration={useEnableWorkflow}
              useExportIntegrations={useExportWorkflows}
            />
          </ListContainer>
        ) : (
          <div>Failed to load workflows</div>
        )}
      </main>
    </>
  );
};

export { Workflows };