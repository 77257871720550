import React from 'react';

const Unauthorized = () => {
  return (
    <div className={'container'}>
      <div className={'page-message'}>You are not authorized to access this application</div>
    </div>
  );
}

export { Unauthorized };