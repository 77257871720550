import { IWorkflow } from '../interfaces/i-workflow';
import { RecipientConfig } from './recipient-config';
export class Workflow implements IWorkflow {
  id: string;
  enabled: boolean;
  requireSSO: boolean;
  redirectSenderToSignerUrl: boolean;
  hideRecipients: boolean;
  sendOnBehalfOfSsoUser: boolean;
  sendOnBehalfOf: string | null;
  senderFields: string[];
  recipientConfigs: RecipientConfig[];
  includeODSData: boolean;
  postSignRedirect: string | null;
  createdBy: string;
  lastUpdatedBy: string;
  lastUsed: number;
  availabilityStart: number|null;
  availabilityEnd: number|null;

  constructor(data?: Partial<IWorkflow>) {
    this.id = data?.id || '';
    this.enabled = data?.enabled ?? false;
    this.requireSSO = data?.requireSSO ?? true;
    this.redirectSenderToSignerUrl = data?.redirectSenderToSignerUrl ?? false;
    this.hideRecipients = data?.hideRecipients ?? true;
    this.sendOnBehalfOfSsoUser = data?.sendOnBehalfOfSsoUser ?? false;
    this.sendOnBehalfOf = data?.sendOnBehalfOf || null;
    this.senderFields = data?.senderFields || [];
    this.recipientConfigs = data?.recipientConfigs || [];
    this.includeODSData = data?.includeODSData ?? false;
    this.postSignRedirect = data?.postSignRedirect || null;
    this.createdBy = data?.createdBy || '';
    this.lastUpdatedBy = data?.lastUpdatedBy || '';
    this.lastUsed = data?.lastUsed || 0;
    this.availabilityStart = data?.availabilityStart || null;
    this.availabilityEnd = data?.availabilityEnd || null;
  }
}