import React from 'react';
import {Link} from "react-router-dom";

interface Props {
  currentPageTitle: string;
  parentPage?: string;
}

const Breadcrumbs: React.FC<Props> = ({currentPageTitle, parentPage}) => {
  return (
    <nav style={{paddingTop: 20}} aria-label="Breadcrumbs">
      <Link to="/">Home</Link>&nbsp;
      { parentPage ? (<>{`> `}<Link to={`/${parentPage.toLowerCase()}`}>{parentPage}</Link>&nbsp;</>) : null }
      {`> ${currentPageTitle}`}
    </nav>
  );
}

export default Breadcrumbs;