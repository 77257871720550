import { DateFormat, FormField, FormFieldValidation, InputType } from "adobe-sign-types";
import { ComparatorType, ConditionalRoutingCondition, RecipientConfig, WorkflowFullConfig } from "asu-types";
import { useCallback, useReducer } from "react";
import { Constants } from "../../enums/Constants";
import { SenderFormMemberInfo, SenderFormParticipantSet } from "../../hooks/use-templates/types";
import { adobeDateFormatFactory } from "../../utils/date-format-factory";

export interface SenderFormData {
  participantSets: SenderFormParticipantSet[],
  senderFields: FormField[],
  senderFieldAnswers: {[key: string]: string},
  recipientConfigs: RecipientConfig[]
}

enum SenderFormAction {
  SetSenderFieldAnswers,
  SetParticipantSets
}

const senderFormReducer = (state: SenderFormData, action: {payload: Partial<SenderFormData>, type: SenderFormAction}): SenderFormData => {
  switch (action.type) {
    case SenderFormAction.SetSenderFieldAnswers:
      return {
        ...state,
        senderFieldAnswers: action.payload.senderFieldAnswers || state.senderFieldAnswers
      }
    case SenderFormAction.SetParticipantSets:
      return {
        ...state,
        participantSets: action.payload.participantSets || state.participantSets
      }
    default:
      throw new Error(`Supplied action type ${action.type} not supported`);
  }
};

/**
 * Should create SenderFormParticipantSet objects from the given
 * workflowConfig's recipientsListInfo.
 * 
 * @param workflowConfig 
 * @param senderEmail 
 * @returns 
 */
const initializeParticipantSets = (workflowConfig: WorkflowFullConfig, senderEmail?: string): SenderFormParticipantSet[] => {
  return workflowConfig.adobeSignWorkflow.recipientsListInfo.map(recipient => {
    const isSender = recipient.defaultValue === Constants.SenderEmailDefaultValue;
    const hasConditionalRouting = workflowConfig.recipientConfigs
      .some(recipientConfig => recipientConfig.label === recipient.label && recipientConfig.conditionalMembers.length);
    const editable = recipient.editable && (!isSender || !senderEmail) && !hasConditionalRouting;
    const defaultMemberEmails: string[] = [];

    if (isSender && senderEmail) {
      defaultMemberEmails.push(senderEmail);
    }
    else if (recipient.defaultValue && !isSender) {
      defaultMemberEmails.push(...recipient.defaultValue.split(','))
    }
    else if (!hasConditionalRouting) {
      defaultMemberEmails.push('');
    }

    return {
      role: recipient.role,
      label: recipient.label,
      memberInfos: defaultMemberEmails.map(email => {return {email, editable}}),
      adobeConfig: recipient,
      isSender,
      required: recipient.minListCount > 0,
      maxMemberCount: recipient.maxListCount,
      hasConditionalRouting
    }
  });
};

/**
 * Create FormFields from the AdobeSign workflow's editable mergeFields and
 * merge that with form fields that are marked as sender fields.
 * 
 * @param workflowConfig
 * @returns 
 */
const initializeSenderFields = (workflowConfig: WorkflowFullConfig) => {
  const senderFieldsFromAdobeSignSenderFields: FormField[] = workflowConfig.adobeSignWorkflow.mergeFieldsInfo?.filter(field => field.editable)
    .map(adobeSignSenderField => {
      return {
        inputType: InputType.TextField,
        tooltip: adobeSignSenderField.displayName,
        defaultValue: adobeSignSenderField.defaultValue,
        required: adobeSignSenderField.required,
        minLength: -1,
        maxLength: -1,
        minValue: -1,
        maxValue: -1,
        validation: FormFieldValidation.None,
        name: adobeSignSenderField.fieldName,
        assignee: 'sender',
        locations: []
      }
    }) || [];

  return workflowConfig.formFields
    .filter(formField => workflowConfig.senderFields.includes(formField.name))
    .concat(senderFieldsFromAdobeSignSenderFields);
};

const initializeSenderFieldAnswers = (senderFields: FormField[]) => {
  const defaultValues: {[key: string]: string} = {};

  senderFields.forEach(field => {
    defaultValues[field.name] = field.defaultValue || '';
  });

  return defaultValues;
};

const conditionMet = (senderFieldsAnswers: {[key: string]: string}, condition: ConditionalRoutingCondition, senderFields: FormField[]) => {
  const senderField = senderFields.find(senderField => senderField.name === condition.fieldName);
  const value: string | null = senderFieldsAnswers[condition.fieldName]?.trim() || null;
  let numberValue: number | null;
  let numberComparator: number | null;

  if (senderField && [FormFieldValidation.Date, FormFieldValidation.Time].includes(senderField.validation)) {
    const {getParsableDate} = adobeDateFormatFactory(senderField.validationData || DateFormat.HH_MiMi);
    numberValue = (new Date(getParsableDate(value || ''))).valueOf();
    numberComparator = (new Date(getParsableDate(condition.comparatorValue || ''))).valueOf()
  }
  else {
    numberValue = parseFloat(value?.replace(',', '') || '') ?? null;
    numberComparator = parseFloat(condition.comparatorValue.replace(',', '') || '') ?? null;
  }

  switch (condition.comparatorType) {
    case ComparatorType.IsEmpty:
      return !Boolean(value);
    case ComparatorType.IsNotEmpty:
      return Boolean(value);
    case ComparatorType.StringEqualTo:
      return value?.toLowerCase().trim() === condition.comparatorValue.toLowerCase().trim();
    case ComparatorType.StringNotEqualTo:
      return value?.toLowerCase().trim() !== condition.comparatorValue.toLowerCase().trim();
    case ComparatorType.NumberEqualTo:
      return numberValue === numberComparator;
    case ComparatorType.NumberNotEqualTo:
      return numberValue !== numberComparator;
    case ComparatorType.NumberGreaterThan:
      return numberValue > numberComparator;
    case ComparatorType.NumberGreaterThanOrEqualTo:
      return numberValue >= numberComparator;
    case ComparatorType.NumberLessThan:
      return numberValue < numberComparator;
    case ComparatorType.NumberLessThanOrEqualTo:
      return numberValue <= numberComparator;
    case ComparatorType.IsChecked:
      return value === Constants.CheckboxCheckedValue;
    case ComparatorType.IsUnchecked:
      return value !== Constants.CheckboxCheckedValue;
    default:
      return false;
  }
};

const changedConditionalParticipantSets = (existingParticipantSets: SenderFormParticipantSet[], senderFieldAnswers: {[key: string]: string}, recipientConfigs: RecipientConfig[], senderFields: FormField[]) => {
  const changedConditionalParticipantSets: SenderFormParticipantSet[] = [];

  recipientConfigs.forEach((recipientConfig) => {
    const members: SenderFormMemberInfo[] = [];
    const existingParticipantSet = existingParticipantSets
      .find(participantSet => participantSet.label === recipientConfig.label);

    if (!existingParticipantSet || !recipientConfig.conditionalMembers || !recipientConfig.conditionalMembers.length) {
      return;
    }

    recipientConfig.conditionalMembers.forEach(conditionalMember => {
      if (conditionalMember.conditions.every(condition => conditionMet(senderFieldAnswers, condition, senderFields))) {
        members.push({
          email: conditionalMember.email,
          editable: conditionalMember.senderFilled
        });
      }
    });

    if (JSON.stringify(existingParticipantSet!.memberInfos.sort()) !== JSON.stringify(members.sort())) {
      changedConditionalParticipantSets.push({
        ...existingParticipantSet!,
        required: true,
        memberInfos: members
      });
    }
  });

  return changedConditionalParticipantSets;
};

const mergeParticipantSets = (originalParticipantSets: SenderFormParticipantSet[], participantSetsToMergeIn: SenderFormParticipantSet[]) => {
  return originalParticipantSets.map(originalParticipantSet => {
    const changedParticipantSet = participantSetsToMergeIn.find(participantSetToMergeIn => participantSetToMergeIn.label === originalParticipantSet.label);
    return changedParticipantSet || originalParticipantSet;
  });
};

const useSenderFormStore = (workflowConfig: WorkflowFullConfig, senderEmail?: string) => {
  
  const [senderFormData, dispatch] = useReducer(senderFormReducer, null, () => {
    const senderFields = initializeSenderFields(workflowConfig);
    const senderFieldAnswers = initializeSenderFieldAnswers(senderFields);
    const participantSets = initializeParticipantSets(workflowConfig, senderEmail);
    const recipientConfigs = workflowConfig.recipientConfigs
      .filter(recipientConfig => workflowConfig.adobeSignWorkflow.recipientsListInfo.some(
        workflowRecipient => workflowRecipient.label === recipientConfig.label
      ));
    const conditionalRoutingParticipantSets = changedConditionalParticipantSets(participantSets, senderFieldAnswers, recipientConfigs, senderFields)

    return {
      participantSets: mergeParticipantSets(participantSets, conditionalRoutingParticipantSets),
      senderFields: senderFields,
      senderFieldAnswers: senderFieldAnswers,
      recipientConfigs
    }
  });

  const setSenderFieldAnswers = useCallback((senderFieldAnswers: {[key: string]: string}) => {
    dispatch({type: SenderFormAction.SetSenderFieldAnswers, payload: {senderFieldAnswers}});
    
    const changedParticipantSets = changedConditionalParticipantSets(senderFormData.participantSets, senderFieldAnswers, senderFormData.recipientConfigs, senderFormData.senderFields);
    
    if (changedParticipantSets.length) {
      dispatch({type: SenderFormAction.SetParticipantSets, payload: {participantSets: mergeParticipantSets(senderFormData.participantSets, changedParticipantSets)}});
    }
  }, [senderFormData.participantSets, senderFormData.recipientConfigs, senderFormData.senderFields]);

  const setMemebers = useCallback((participantLabel: string, memberInfos: SenderFormMemberInfo[]) => {
    const updatedParticipantSets = [...senderFormData.participantSets];
    const participantSet = updatedParticipantSets.find(p => p.label === participantLabel);

    if (participantSet) {
      participantSet.memberInfos = memberInfos;
    }

    dispatch({type: SenderFormAction.SetParticipantSets, payload: {participantSets: updatedParticipantSets}});
  }, [senderFormData.participantSets]);

  return {
    senderFormData,
    setSenderFieldAnswers,
    setMemebers
  }
};

export { SenderFormAction, useSenderFormStore };