import React, {useRef} from 'react';
import {useIntegrationStore} from "../../../../../../integration-store";
import {useGetAdobeTemplate} from "../../../../../../../../hooks/use-templates";
import {useMappingDispatch, useMappingStore} from "../../mapping-store";
import styled from "styled-components";
import { FormDataMapping } from 'asu-types';

const TemplateFieldContainer = styled.div`
  .templateFieldSelect {
    width: 536px;
    background: #fff;
  }
  .templateFieldDisplay {
    width: 524px;
    background: #FAFAFA;
  }
`;

const TemplateFieldName = () => {
  const { editMode, targetField } = useMappingStore();
  const mappingDispatch = useMappingDispatch();
  const currentFieldSelection = useRef<null|string>(null);
  if (!currentFieldSelection.current && targetField) {
    currentFieldSelection.current = targetField;
  }

  const {adobeSignId, formDataMappings} = useIntegrationStore();
  const template = useGetAdobeTemplate(adobeSignId);

  const usedFields = new Set(formDataMappings.map((dataMapping: FormDataMapping) => {
    //Don't include the already used field name for this mapping so that it won't
    //be filtered out as an option and can be auto-selected while editing
    if (dataMapping.targetField === currentFieldSelection.current) {
      return null;
    } else {
      return dataMapping.targetField;
    }
  }));

  let availableOptions = [];
  if (template.status === 'success' && template.data) {
    availableOptions = template.data.fields.filter( (field: string) => !usedFields.has(field));
  }

  const handleSelectionChange = (optionValue: string) => {
    mappingDispatch({
      type: 'setTargetField',
      payload: {
        targetField: optionValue
      }
    })
  }

  return (
    <TemplateFieldContainer>
      {editMode ? (
        <select
            autoFocus
            className={'templateFieldSelect'}
            value={targetField}
            onChange={(event)=>handleSelectionChange(event.target.value)}>

          { targetField && template.status === 'loading' ? (
              <>
                <option value={targetField}>{targetField}</option>
                <option value={''}>Loading template fields...</option>
              </>

          ) : template.status === 'loading' ? (
              <option value={''}>Loading template fields...</option>

          ) : template.status === 'error' ? (
              <option value={''}>Error: Could not retrieve fields</option>

          ) : template.status === 'success' && availableOptions.length === 0 ? (
              <option value={''}>No options available</option>

          ) : (
              <>
                <option value={''}>- Select Template Field -</option>
                {availableOptions.map((option: string) => {
                  return <option key={option} value={option}>{option}</option>
                })};
              </>
          )}

        </select>
      ) : (
        <input
            className={'templateFieldDisplay'}
            inputMode={'text'}
            value={targetField}
            readOnly
        />
      )}
    </TemplateFieldContainer>
  );
}

export default TemplateFieldName;