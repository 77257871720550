import styled from "styled-components";
import { CenterMessage, IntegrationForm } from "../components/CommonElements";
import { PageTitle } from "../components/Layout";
import { useGetStatusPageAgreements } from "../hooks/use-agreements";
import { StatusPageAgreement } from "asu-types";
import { Styles } from "../enums/Styles";
import { AgreementStatus } from "adobe-sign-types";
import { useState } from "react";
import { CheckboxField } from "../components/CommonElements/CheckboxField";

const AgreementsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .progress-bar {
    height: 10px;
    border-radius: 6px;
  }

  .in-progress {
    border: solid 2px ${Styles.AsuGoldDarker};

    .progress-bar {
      width: 61%;
      background-color: ${Styles.AsuGoldDarker};
    }
  }

  .complete {
    border: solid 2px ${Styles.AsuGreenDarker};

    .progress-bar {
      width: 100%;
      background-color: ${Styles.AsuGreenDarker};
    }
  }

  .cancelled {
    border: solid 2px ${Styles.AsuMaroon};

    .progress-bar {
      width: 100%;
      background-color: ${Styles.AsuMaroon};
    }
  }

  .agreement {
    @media only screen and (max-width: 740px) {
      width: 100%;
    }

    @media only screen and (min-width: 741px) and (max-width: 1000px) {
      width: 43%;
    }

    @media only screen and (min-width: 1001px) {
      width: 29%;
    }

    padding: 10px;
    margin-bottom: 10px;
    border-radius: 6px;
    height: 200px;

    .header {
      height: 120px;
      .name {
        font-size: large;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }

    .status {
      margin: 20px 0 10px 0;
      width: 100%;
      text-align: center;
      font-size: large;
    }
  }
`;

const ClassificationSelector = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .selection {
    width: 30%;
    text-align: center;
    background-color: ${Styles.AsuWhite};
    color: ${Styles.AsuBlack}
    border: solid 1px black;
    padding: 20px;
    font-size: large;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
  }

  .selection.selected {
    background-color: ${Styles.AsuBlack};
    color: ${Styles.AsuWhite}
  }
`;

enum StatusClassification {
  Complete = 'complete',
  InProgress = 'in-progress',
  Cancelled = 'cancelled'
}

const getStatusClassification = (status: AgreementStatus): StatusClassification => {
  switch (status) {
    case AgreementStatus.Signed:
      return StatusClassification.Complete;
    case AgreementStatus.Cancelled:
      return StatusClassification.Cancelled;
    default:
      return StatusClassification.InProgress;
  }
}

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
};

const oldAgreementCutOff = (new Date()).valueOf() - (1000*60*60*24*30);

const getNumberOfAgreements = (agreements?: Agreement[]) => {
  return agreements ? `(${agreements.length})` : '(0)';
};

interface Agreement extends StatusPageAgreement {
  createdDateObj: Date,
  statusDisplay: string,
  timeStamp: number
}

const AgreementsStatus = () => {
  const {isLoading, isError, data: agreementsRaw} = useGetStatusPageAgreements();
  const [selectedClassification, setSelectedClassification] = useState(StatusClassification.InProgress);
  const [viewOldAgreements, setViewOldAgreements] = useState(false);

  let agreements: Agreement[] = [];
  let agreementGroups: {[key: string]: Agreement[]} = {};

  if (agreementsRaw) {
    agreements = agreementsRaw.map(agreement => {
      const dateObj = new Date(agreement.createdDate);
      return {
        ...agreement,
        createdDateObj: dateObj,
        statusDisplay: agreement.status.split('_').map(statusPart => capitalize(statusPart)).join(' '),
        timeStamp: dateObj.valueOf()
      }
    })
      .filter(a => a.name && a.timeStamp && a.status && (viewOldAgreements || a.timeStamp > oldAgreementCutOff))
      .sort((a, b) => b.timeStamp - a.timeStamp);

    agreementGroups = agreements.reduce((map: {[key: string]: Agreement[]}, currentAgreement) => {
      let classification = getStatusClassification(currentAgreement.status);
      
      if (map[classification] === undefined) {
        map[classification] = [];
      }

      map[classification].push(currentAgreement);
      return map;
    }, {});
  }

  if (isLoading) {
    return <CenterMessage message="Loading Agreements..." />
  }
  else if (isError) {
    return <CenterMessage message="Error loading agreements" />
  }

  return (
    <main id="skip-to-content">
      <PageTitle>Agreement Statuses</PageTitle>
      <ClassificationSelector>
        <div
          className={`selection ${selectedClassification === StatusClassification.InProgress ? 'selected' : ''}`}
          onClick={() => setSelectedClassification(StatusClassification.InProgress)}
        >
          {capitalize(StatusClassification.InProgress)} {getNumberOfAgreements(agreementGroups[StatusClassification.InProgress])}
        </div>
        <div className={`selection ${selectedClassification === StatusClassification.Complete ? 'selected' : ''}`}
          onClick={() => setSelectedClassification(StatusClassification.Complete)}
        >
          {capitalize(StatusClassification.Complete)} {getNumberOfAgreements(agreementGroups[StatusClassification.Complete])}
        </div>
        <div className={`selection ${selectedClassification === StatusClassification.Cancelled ? 'selected' : ''}`}
          onClick={() => setSelectedClassification(StatusClassification.Cancelled)}
        >
          {capitalize(StatusClassification.Cancelled)} {getNumberOfAgreements(agreementGroups[StatusClassification.Cancelled])}
        </div>
      </ClassificationSelector>
      <IntegrationForm>
        <CheckboxField
          id='view-older-agreements'
          label='View agreements initiated over 30 days ago.'
          value={viewOldAgreements}
          onValueChange={(_key, value) => setViewOldAgreements(value)}
          checkedValue={true}
          uncheckedValue={false}
        />
      </IntegrationForm>
      {!(agreementGroups[selectedClassification]?.length) ? (
        <CenterMessage message={`You have no ${selectedClassification} agreements to display at this time.`} />
      ) : (
        <AgreementsDiv>
          {agreementGroups[selectedClassification].map(agreement => (
            <div className={`agreement ${selectedClassification}`} key={agreement.adobeSignId}>
              <div className="header">
                <div className="name">{agreement.name}</div>
                <div className="date">Initiated: {agreement.createdDateObj.toLocaleString()}</div>
              </div>
              <div className="status">{agreement.statusDisplay}</div>
              <div className="progress-bar"></div>
            </div>
          ))}
        </AgreementsDiv>
      )}
    </main>
  );
};

export { AgreementsStatus };