import { DateFormat, FormField, FormFieldValidation, InputType } from "adobe-sign-types";
import { ComparatorType, ConditionalRoutingMember } from "asu-types";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { Constants } from "../../enums/Constants";
import { comparatorDisplayValue } from "../../utils/comparator-display";
import { DeleteIcon } from "../ActionIcon";
import { ToolTipButton, ToolTipIconButton } from "../Button";
import { AlignCenter } from "../CommonElements";
import { CheckboxField } from "../CommonElements/CheckboxField";
import { DropDownField } from "../CommonElements/DropDownField";
import { TextField } from "../CommonElements/TextField";

const ConditionRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
  margin-bottom: 15px;

  .form-group {
    padding-bottom: 0;
  }

  .field-name {
    flex: 1 50%;
  }

  .comparator-type {
    flex: 1;
  }

  .comparator-value {
    flex: 1 70%;
  }

  .delete-condition {
    flex: 1;
    display: flex;
    width: 10%;
    align-items: center;
    justify-content: center;
    padding-top: 20px
  }
`;

const ConditionalMemberEmailDiv = styled.div`
  display: flex;

  .email-div {
    flex: 1 20%;
  }

  .button-div {
    padding-top: 20px;
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-left: 5px;
  }

  .sender-filled-div {
    padding-top: 20px;

    fieldset {
      border: 0;
    }
  }
`;

interface FormFieldData {
  config: FormField,
  options: [string, string][],
  comparatorTypes: ComparatorType[]
}

interface Props {
  index: number,
  member: ConditionalRoutingMember,
  onMemberChange: (index: number, member: ConditionalRoutingMember) => void,
  onFormErrorsChange: (formError: {[key:string]: boolean}) => void,
  formFields: FormField[],
  onRemoveMember: (index: number) => void,
  showConditions: boolean,
  onShowConditionsForMemberChange: (index: number | null) => void,
  formIsValid: boolean
}

const ConditionalMemberConfig: React.FC<Props> = ({index: memberIndex, member, onMemberChange, onFormErrorsChange, formFields, onRemoveMember, showConditions, onShowConditionsForMemberChange, formIsValid}) => {
  const [addingConditions, setAddingConditions] = useState(false);
  const fieldNameOptions: [string, string][] = useMemo(() => {
    return formFields.map(formField => {
      return [formField.name, formField.name];
    });
  }, [formFields]);

  const comparatorValueOptions = (formField: FormField): [string, string][] => {
    switch (formField.inputType) {
      case InputType.Checkbox:
        return [
          [Constants.CheckboxCheckedValue, Constants.CheckboxCheckedValue],
          [Constants.CheckboxUncheckedValue, Constants.CheckboxUncheckedValue]
        ];
      case InputType.DropDown:
      case InputType.Radio:
        return formField.hiddenOptions?.map((key, index) => [key, formField.visibleOptions?.[index] || key]) || []
      default:
        return [];
    }
  };

  const comparatorTypeOptions = (formField: FormField): ComparatorType[] => {
    if (formField.inputType === InputType.Checkbox) {
      return [
        ComparatorType.IsChecked,
        ComparatorType.IsUnchecked
      ];
    }
    else if ([FormFieldValidation.Currency, FormFieldValidation.Number, FormFieldValidation.Date, FormFieldValidation.Time].includes(formField.validation)) {
      return [
        ComparatorType.IsEmpty,
        ComparatorType.IsNotEmpty,
        ComparatorType.NumberEqualTo,
        ComparatorType.NumberNotEqualTo,
        ComparatorType.NumberGreaterThan,
        ComparatorType.NumberGreaterThanOrEqualTo,
        ComparatorType.NumberLessThan,
        ComparatorType.NumberLessThanOrEqualTo
      ];
    }
    else {
      return [
        ComparatorType.IsEmpty,
        ComparatorType.IsNotEmpty,
        ComparatorType.StringEqualTo,
        ComparatorType.StringNotEqualTo
      ];
    }
  };

  const formFieldDataMap = useMemo<{[fieldName: string]: FormFieldData}>(() => {
    const formFieldDataMap: {[fieldName: string]: FormFieldData} = {};

    formFields.forEach(formField => {
      formFieldDataMap[formField.name] = {
        config: formField,
        options: comparatorValueOptions(formField),
        comparatorTypes: comparatorTypeOptions(formField)
      }
    });

    return formFieldDataMap;
  }, [formFields]);  
  
  const changeEmail = (_key: string, email: string) => {
    onMemberChange(memberIndex, {
      ...member,
      email
    });
  };

  const updateConditionFieldName = (indexString: string, fieldName: string) => {
    const index = parseInt(indexString);
    const updatedConditions = [...member.conditions];
    updatedConditions[index].fieldName = fieldName;
    updatedConditions[index].comparatorType = formFieldDataMap[fieldName].comparatorTypes.includes(updatedConditions[index].comparatorType)
      ? updatedConditions[index].comparatorType
      : formFieldDataMap[fieldName].comparatorTypes[0];
    onMemberChange(memberIndex, {
      ...member,
      conditions: updatedConditions
    });
  };

  const updateConditionComparatorType = (indexString: string, comparatorType: string) => {
    const index = parseInt(indexString);
    const updatedConditions = [...member.conditions];
    updatedConditions[index].comparatorType = comparatorType as ComparatorType;
    onMemberChange(memberIndex, {
      ...member,
      conditions: updatedConditions
    });
  };

  const updateConditionComparatorValue = (indexString: string, comparatorValue: string) => {
    const index = parseInt(indexString);
    const updatedConditions = [...member.conditions];
    updatedConditions[index].comparatorValue = comparatorValue;
    onMemberChange(memberIndex, {
      ...member,
      conditions: updatedConditions
    });
  };

  const addCondition = () => {
    const updatedMember = {...member};
    updatedMember.conditions.push({
      fieldName: '',
      comparatorType: ComparatorType.StringEqualTo,
      comparatorValue: ''
    });
    onMemberChange(memberIndex, updatedMember);
    setAddingConditions(true);
  };

  const deleteCondition = (conditionIndex: number) => {
    const updatedConditions = [...member.conditions];
    updatedConditions.splice(conditionIndex, 1);
    onMemberChange(memberIndex, {
      ...member,
      conditions: updatedConditions
    });
  };

  const updateSenderFilled = (checked: boolean) => {
    onMemberChange(memberIndex, {
      ...member,
      senderFilled: checked,
      email: checked ? '' : member.email
    });
  };

  return (
    <>
      <ConditionalMemberEmailDiv>
        <div className="email-div">
          <TextField
              autoFocus={memberIndex === 0 || !member.email}
              id={`member-${memberIndex}`}
              label={'Member Email'}
              formKey={`${memberIndex}`}
              value={member.email}
              onValueChange={changeEmail}
              required={!member.senderFilled}
              validationType={FormFieldValidation.Email}
              onFormErrorsChange={onFormErrorsChange}
              disabled={member.senderFilled}
              preferAsuriteEmail={true}
              toolTip={`This email will be assigned to the recipient if all of its conditions are met.${showConditions ? '' : ' To show this members conditions, click on `Show Conditions` to the right.'}`}
            />
        </div>
        <div className="sender-filled-div">
          <CheckboxField 
            id={`sender-filled-${memberIndex}`}
            label="Initiator Filled"
            value={member.senderFilled}
            onValueChange={(_key, value) => updateSenderFilled(value)}
            onFormErrorsChange={onFormErrorsChange}
            checkedValue={true}
            uncheckedValue={false}
            toolTip="If checked, the initiator will fill out the member's email."
          />
        </div>
        <div className="button-div">
          <ToolTipButton 
            variant="secondary"
            onClick={() => onRemoveMember(memberIndex)}
            toolTip={"Remove this member and all of its conditions from the conditional routing group."}
          >Remove Member</ToolTipButton>
          {showConditions ? (
            <ToolTipButton
              variant="primary"
              onClick={() => onShowConditionsForMemberChange(null)}
              disabled={!formIsValid}
              toolTip={!formIsValid ? "Please complete all required fields" : "Hide this conditional routing member's conditions."}
            >Hide Conditions</ToolTipButton>
          ) : (
            <ToolTipButton 
              variant="primary"
              onClick={() => onShowConditionsForMemberChange(memberIndex)}
              disabled={!formIsValid}
              toolTip={!formIsValid ? "Please complete all required fields" : "Show this conditional routing member's conditions."}
            >Show Conditions</ToolTipButton>
          )}
        </div>
      </ConditionalMemberEmailDiv>
      {showConditions ? member.conditions.map((condition, conditionIndex) => {
        const isCheckbox = formFieldDataMap[condition.fieldName]?.config.inputType === InputType.Checkbox;
        const requiresComparatorValue = ![ComparatorType.IsEmpty, ComparatorType.IsNotEmpty].includes(condition.comparatorType);
        return (
          <ConditionRow key={conditionIndex}>
            <div className="field-name">
              <DropDownField
                autoFocus={addingConditions}
                id={`member-${memberIndex}-condition-${conditionIndex}-field-name`}
                label={'Field Name'}
                formKey={`${conditionIndex}`}
                value={condition.fieldName}
                onValueChange={updateConditionFieldName}
                required={true}
                onFormErrorsChange={onFormErrorsChange}
                options={fieldNameOptions}
                disabled={false}
                toolTip="This drop down is populated with fields that are marked as initiator fields in the section above.  If you do not see the field you expect to see in this list, check to see if it is marked as an initiator field above."
              />
            </div>
            <div className="comparator-type">
              <DropDownField
                id={`member-${memberIndex}-condition-${conditionIndex}-comparator`}
                label={'Comparator'}
                formKey={`${conditionIndex}`}
                value={condition.comparatorType}
                onValueChange={updateConditionComparatorType}
                required={true}
                onFormErrorsChange={onFormErrorsChange}
                options={formFieldDataMap[condition.fieldName]?.comparatorTypes.map(type => [type, comparatorDisplayValue(type) || type]) || []}
                disabled={false}
              />
            </div>
            {!isCheckbox && condition.fieldName && requiresComparatorValue ? (
              <div className="comparator-value">
                {formFieldDataMap[condition.fieldName]?.options.length ? (
                  <DropDownField
                    id={`member-${memberIndex}-condition-${conditionIndex}-comparator-dropDownValue`}
                    label={'Comparator Value'}
                    formKey={`${conditionIndex}`}
                    value={condition.comparatorValue}
                    onValueChange={updateConditionComparatorValue}
                    required={true}
                    onFormErrorsChange={onFormErrorsChange}
                    options={formFieldDataMap[condition.fieldName].options}
                    disabled={false}
                    toolTip='This value will be compared to the value the initiator enters using the selected Comparator'
                  />
                ) : (
                  <TextField
                    id={`member-${memberIndex}-condition-${conditionIndex}-comparator-textValue`}
                    label={'Comparator Value'}
                    formKey={`${conditionIndex}`}
                    value={condition.comparatorValue}
                    onValueChange={updateConditionComparatorValue}
                    required={true}
                    validationType={formFieldDataMap[condition.fieldName]?.config.validation || FormFieldValidation.None}
                    onFormErrorsChange={onFormErrorsChange}
                    disabled={false}
                    toolTip='This value will be compared to the value the initiator enters using the selected Comparator'
                    validationData={formFieldDataMap[condition.fieldName]?.config.validationData || DateFormat.HH_MiMi}
                  />
                )}
              </div>
            ) : null}
            {member.conditions.length > 1 ? (
              <div className="delete-condition">
                <ToolTipIconButton
                  onClick={() => {deleteCondition(conditionIndex)}}
                  toolTip={`Delete condition`}
                ><DeleteIcon /></ToolTipIconButton>
              </div>
            ) : null}
          </ConditionRow>
        )
      }) : null}
      {showConditions ? (
        <AlignCenter>
          <ToolTipButton 
            variant="primary"
            onClick={addCondition}
            disabled={!formIsValid}
            toolTip={!formIsValid ? "Please complete all required fields" : "Add a condition that must be met in order for this conditional routing member to be assigned to this recipient."}
          >Add Condition</ToolTipButton>
        </AlignCenter>
      ) : null}
      <hr />
    </>
  );
};

export { ConditionalMemberConfig };