import { FormField, WorkflowRecipientInfo } from 'adobe-sign-types';
import { ComparatorType, ConditionalRoutingMember, RecipientConfig } from 'asu-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Constants } from '../../enums/Constants';
import { comparatorDisplayValue } from '../../utils/comparator-display';
import { setFocusOn } from '../../utils/document-interactions';
import { EditIcon } from '../ActionIcon';
import { ToolTipButton, ToolTipIconButton } from '../Button';
import { AlignCenter, SectionHeader } from '../CommonElements';
import { ToolTip } from '../ToolTip';
import { ConditionalMemberConfig } from './ConditionalMemberConfig';

const RecipientRow = styled.div`
  display: flex;
  flex-flow: row;
  gap: 5px;
  padding: 10px;

  .recipient-label {
    flex: 1 20%;
  }

  .recipient-members-description {
    flex: 1 80%;

    .recipient-member-description {
      margin-bottom: 10px;

      :last-child {
        margin-bottom: 0px;
      }
    }
  }

  .edit-recipient {
    flex: 1 5%;
  }
`;

const EditConfigDiv = styled.div`
  border: 1px solid black;
  padding: 10px;

  hr {
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

interface MemberDescription {
  email: string,
  conditionStrings: string[]
}

interface RecipientDecription {
  defaultDescription: string
  memberDescriptions: MemberDescription[]
}

interface Props {
  recipients: WorkflowRecipientInfo[],
  recipientConfigs: RecipientConfig[],
  onRecipientConfigsChanged: (recipientConfigs: RecipientConfig[]) => void,
  formFields: FormField[],
  recipientConfigValid: boolean,
  onRecipientConfigValidChanged: (valid: boolean) => void,
  onEditingRecipientChanged: (editing: boolean) => void,
  senderFields: string[]
}

const ConditionalRoutingConfig: React.FC<Props> = ({recipients, recipientConfigs, onRecipientConfigsChanged, formFields, recipientConfigValid, onRecipientConfigValidChanged, onEditingRecipientChanged, senderFields}) => {
  const [configBeingEdited, setConfigBeingEdited] = useState<RecipientConfig | undefined>();
  const [formErrors, setFormErrors] = useState<{[key: string]: boolean}>({});
  const [showConditionsForMember, setShowConditionsForMember] = useState<number | null>(null);
  const fieldsForConditionalRouting = useMemo(
    () => formFields.filter(formField => senderFields.includes(formField.name)), 
    [formFields, senderFields]
  );
  const recipientDescriptionMap: {[label: string]: RecipientDecription} = useMemo(() => {
    const map: {[label: string]: RecipientDecription} = {};

    recipients.forEach(recipient => {
      const config = recipientConfigs.find(config => config.label === recipient.label);
      const memberDescriptions: MemberDescription[] = config?.conditionalMembers
        .filter(member => member.conditions.length)
        .map(member => ({
          email: member.senderFilled ? '(Filled by Initiator)' : member.email,
          conditionStrings: member.conditions.map(condition => {
            return `${condition!.fieldName} ${comparatorDisplayValue(condition!.comparatorType)} ${condition!.comparatorValue}`
          })
        })) || [];
      const defaultDescription = memberDescriptions.length || !recipient.defaultValue
        ? '(Will be entered by initiator)'
        : recipient.defaultValue === Constants.SenderEmailDefaultValue
          ? '(This recipient is the initiator)'
          : recipient.defaultValue;
      map[recipient.label] = {
        defaultDescription,
        memberDescriptions
      };
    });

    return map;
  }, [recipientConfigs, recipients]);

  const copyRecipientConfig = (config: RecipientConfig) => ({
    ...config,
    conditionalMembers: config.conditionalMembers.map(member => ({
      ...member,
      conditions: member.conditions.map(condition => ({...condition}))
    }))
  });

  const editConfig = (label: string) => {
    let configToEdit: RecipientConfig;
    const existingConfig = recipientConfigs.find(config => config.label === label);

    if (existingConfig) {
      configToEdit = existingConfig;
    }
    else {
      configToEdit = {
        label,
        conditionalMembers: []
      };
      onRecipientConfigsChanged([...recipientConfigs, configToEdit]);
    }

    setConfigBeingEdited(copyRecipientConfig(configToEdit));
    onEditingRecipientChanged(true);
  }

  const saveConfig = () => {
    if (!configBeingEdited || !recipientConfigValid) {
      return;
    }

    const newConfigs = recipientConfigs.map(config => {
      if (config.label === configBeingEdited.label) {
        return configBeingEdited;
      }
      else {
        return config;
      }
    });

    setFocusOn(`edit-recipient-${configBeingEdited.label}`);
    onRecipientConfigsChanged(newConfigs);
    setConfigBeingEdited(undefined);
    setShowConditionsForMember(null);
    onEditingRecipientChanged(false);
  };

  const cancelEditConfig = () => {
    setFocusOn(`edit-recipient-${configBeingEdited?.label}`);
    setConfigBeingEdited(undefined);
    setShowConditionsForMember(null);
    onEditingRecipientChanged(false);
  };

  const addConditionalMember = () => {
    if (!configBeingEdited) {
      return;
    }
    setShowConditionsForMember(configBeingEdited.conditionalMembers.length);
    setConfigBeingEdited({
      ...configBeingEdited,
      conditionalMembers: [...configBeingEdited.conditionalMembers, {
        email: '',
        senderFilled: false,
        conditions: [{
          fieldName: '',
          comparatorType: ComparatorType.StringEqualTo,
          comparatorValue: ''
        }]
      }]
    });
  };
  
  const setMember = (index: number, member: ConditionalRoutingMember) => {
    if (!configBeingEdited) {
      return;
    }
    const updatedConfig = {...configBeingEdited};
    updatedConfig.conditionalMembers[index] = member;
    setConfigBeingEdited(updatedConfig);
  };

  const removeMember = (index: number) => {
    if (!configBeingEdited) {
      return;
    }
    const updatedConfig = {...configBeingEdited};
    updatedConfig.conditionalMembers.splice(index, 1);
    setConfigBeingEdited(updatedConfig);
  };

  const setFormError = useCallback((formError: {[key:string]: boolean}) => {
    setFormErrors(previousValue => ({
      ...previousValue,
      ...formError
    }));
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).find(key => formErrors[key])) {
      onRecipientConfigValidChanged(false);
      return;
    }

    onRecipientConfigValidChanged(true);
  }, [formErrors, onRecipientConfigValidChanged]);

  return (
    <>
      <SectionHeader>
        Conditional Routing
        <ToolTip text='This is a list of recipients from the workflow configuration in Adobe Sign.  Only recipients that are marked as "Editable" and not marked as "This recipient is the sender" will be able to have conditional routing added to them, and are indicated with an edit icon.'/>
      </SectionHeader>
      {recipients.map(recipient => {
        const recipientDescription = recipientDescriptionMap[recipient.label];
        return(
          <RecipientRow key={recipient.label}>
            <div className='recipient-label'>
              <strong>{recipient.label}</strong>
            </div>
            <div className='recipient-members-description'>
              {recipientDescription.memberDescriptions.length ? recipientDescription.memberDescriptions.map((memberDescription, index) => {
                return (
                  <div className='recipient-member-description' key={index}>
                    <div>{memberDescription.email}</div>
                    {memberDescription.conditionStrings.map((conditionString, index) => <div key={index}>{index === 0 ? 'When ' : 'And '}{conditionString}</div>)}
                  </div>
                )
              }) : recipientDescription.defaultDescription}
            </div>
            <div className='edit-recipient'>
              {recipient.editable && recipient.defaultValue !== Constants.SenderEmailDefaultValue ? (
                  <ToolTipIconButton
                    id={`edit-recipient-${recipient.label}`}
                    onClick={() => editConfig(recipient.label)}
                    disabled={Boolean(configBeingEdited)}
                    toolTip={Boolean(configBeingEdited) ? 'Please confirm or cancel current conditional routing changes for the recipient below' : `Modify conditional routing for ${recipient.label}`}
                    aria-label={`Modify conditional routing for ${recipient.label}`}
                  ><EditIcon /></ToolTipIconButton>
                ) : null}
            </div>
          </RecipientRow>
        )
      })}
      {configBeingEdited ? (
        <EditConfigDiv>
          <h4>Conditional Routing Configuration for {configBeingEdited.label}</h4>
          <hr />
          {configBeingEdited.conditionalMembers.map((member, memberIndex) => (
            <ConditionalMemberConfig
              key={memberIndex}
              index={memberIndex}
              member={member}
              onMemberChange={setMember}
              onFormErrorsChange={setFormError}
              formFields={fieldsForConditionalRouting}
              onRemoveMember={removeMember}
              showConditions={memberIndex === showConditionsForMember}
              onShowConditionsForMemberChange={setShowConditionsForMember}
              formIsValid={recipientConfigValid}
            />
          ))}
          <AlignCenter>
            <ToolTipButton
              autoFocus={!configBeingEdited.conditionalMembers.length}
              variant="primary"
              onClick={addConditionalMember}
              disabled={!recipientConfigValid}
              toolTip={!recipientConfigValid ? "Please complete all required fields" : "Add a member to this conditional routing group."}
            >Add Member</ToolTipButton>
          </AlignCenter>
          <hr />
          <AlignCenter>
            <ToolTipButton
              variant="primary"
              onClick={saveConfig}
              disabled={!recipientConfigValid}
              toolTip={!recipientConfigValid ? "Please complete all required fields" : "Confirm changes made to the conditional routing configuration."}
            >Confirm Edits</ToolTipButton>
            <ToolTipButton
              variant="secondary"
              onClick={cancelEditConfig}
              toolTip="Cancel editing conditional routing configuration"
            >Cancel Edits</ToolTipButton>
          </AlignCenter>
        </EditConfigDiv>
        
      ) : null}
    </>
  );
};

export { ConditionalRoutingConfig }