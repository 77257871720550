import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {QueryClient, QueryClientProvider} from "react-query";
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./auth/controller";
import ScrollToTop from './utils/scroll-to-top';
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <App />
        </QueryClientProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);