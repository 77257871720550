import makeStore from "../../../../../../utils/make-store";

interface MappingState {
  editMode: boolean;
  targetField: string;
  source: string;
  sourceField: string;
  defaultValue: string;
}

interface Action {
  type: 'hydrateMapping' | 'setTargetField' | 'setSource' | 'setSourceField' | 'setDefaultValue';
  payload: MappingState
}

const initialState = {
  editMode: false,
  targetField: '',
  source: '',
  sourceField: '',
  defaultValue: ''
}

const mappingReducer = (state: MappingState, action: Action) => {
  switch(action.type) {
    case 'hydrateMapping':
      return {
        editMode: action.payload.editMode,
        targetField: action.payload.targetField,
        source: action.payload.source,
        sourceField: action.payload.sourceField,
        defaultValue: action.payload.defaultValue,
      }
    case 'setTargetField':
      return {
        ...state,
        targetField: action.payload.targetField
      }
    case 'setSource':
      return {
        ...state,
        source: action.payload.source,
        sourceField: '',
        defaultValue: ''
      }
    case 'setSourceField':
      return {
        ...state,
        sourceField: action.payload.sourceField,
        defaultValue: ''
      }
    case 'setDefaultValue':
      return {
        ...state,
        defaultValue: action.payload.defaultValue,
        sourceField: ''
      }
    default:
      console.log(`Unhandled reducer action: ${action.type}`);
      return state;
  }
}

const [
  MappingProvider,
  useMappingStore,
  useMappingDispatch
] = makeStore(mappingReducer, initialState, 'FormDataMapping');

export { MappingProvider, useMappingStore, useMappingDispatch };