import React from "react";
import { UseMutationResult } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DeleteIcon, EditIcon } from "../ActionIcon";
import { TextButton, ToolTipIconButton } from "../Button";
import { CopyContent } from "../CommonElements/CopyContent";
import { DeleteListRow } from "./DeleteListRow";

const IntegrationRow = styled.tr`
  td:first-child {
    border-left: none
  }
  td div {
    overflow-wrap: break-word;
  }
`;

const UrlContainer = styled.div`
  display: flex;

  .url {
    width: 90%;
    text-align: center;
    font-size: 11px;
    overflow-wrap: break-word;
    word-break: break-all;
  }

  .copy {
    display: flex;
    width: 10%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;

const ManageContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 20%;
`;

const MetaDataContainer = styled.div`
  font-size: 11px;
`;

export interface Integration {
  id: string,
  name: string,
  url: string,
  editPage: string,
  enabled: boolean,
  createdBy: string,
  lastUpdateBy: string,
  lastUsed: number,
  groupName: string
}

interface Props {
  integration: Integration;
  showDeletePrompt: boolean;
  promptForDelete(arg0: null|string): void;
  useDeleteIntegration(id: string): UseMutationResult<void, Error, void>;
  useDisableIntegration(id: string): UseMutationResult<void, Error, void>;
  useEnableIntegration(id: string): UseMutationResult<void, Error, void>;
  disableManageButtons: boolean
}

const ListRow: React.FC<Props> = ({
  integration,
  showDeletePrompt,
  promptForDelete,
  useDeleteIntegration,
  useDisableIntegration,
  useEnableIntegration,
  disableManageButtons
}) => {
  const deleteText = 'Disable/Delete';
  const navigate = useNavigate();
  const {
    isLoading: enabling,
    isSuccess: enableIsSuccess,
    mutate: enableIntegration
  } = useEnableIntegration(integration.id);
  const lastUsedDate = integration.lastUsed ? new Date(Date.now()) : null;
  
  return (
    <>
      <IntegrationRow>
        <td>
          <div>
            {integration.name}
          </div>
        </td>
        <td>
          <UrlContainer>
            {integration.enabled ? (
              <>
                <div className="url">
                  {integration.url}
                </div>
                <div className="copy">
                  <CopyContent content={integration.url} toolTip={`Copy Url for ${integration.name}`} />
                </div>
              </>
            ) : null}
          </UrlContainer>
        </td>
        <td>
          <MetaDataContainer>
            <div>Created By: {integration.createdBy}</div>
            <div>Last Updated By: {integration.lastUpdateBy}</div>
            <div>Last Used: {lastUsedDate ? lastUsedDate.toLocaleDateString('en-US') : 'N/A'}</div>
          </MetaDataContainer>
        </td>
        <td>
          <ManageContainer>
            {integration.enabled ? (
              <>
                <div>
                  <ToolTipIconButton
                    onClick={() => navigate(integration.editPage)}
                    toolTip={disableManageButtons ? '' : `Edit ${integration.name}`}
                    disabled={disableManageButtons}
                  ><EditIcon /></ToolTipIconButton>
                </div>
                <div>
                  <ToolTipIconButton
                    onClick={() => promptForDelete(integration.id)}
                    toolTip={disableManageButtons ? '' : `${deleteText} ${integration.name}`}
                    disabled={disableManageButtons}
                  ><DeleteIcon /></ToolTipIconButton>
                </div>
              </>
            ) : (
              <TextButton
                disabled={enabling || enableIsSuccess}
                onClick={() => enableIntegration()}
                aria-label={`Enable ${integration.name}`}
              >{enabling ? 'Enabling...' : enableIsSuccess ? 'Enabled!' : 'Enable'}</TextButton>
            )}
          </ManageContainer>
        </td>
      </IntegrationRow>
      {showDeletePrompt ? (
        <DeleteListRow 
          integration={integration}
          promptForDelete={promptForDelete}
          useDeleteIntegration={useDeleteIntegration}
          useDisableIntegration={useDisableIntegration}
        />
      ) : null}
    </>
  );
};

export { ListRow };