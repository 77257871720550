import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPen, faTrash, faXmark, faQuestionCircle, faTriangleExclamation, faCopy} from "@fortawesome/free-solid-svg-icons";
import { Styles } from "../enums/Styles";

const EditIcon = () => <FontAwesomeIcon style={{color: Styles.AsuBlueDarker}} icon={faPen} role="presentation" />;
const SaveIcon = () => <FontAwesomeIcon style={{color: Styles.AsuGreenDarker}} icon={faCheck} role="presentation" />;
const CancelIcon = () => <FontAwesomeIcon style={{color: Styles.AsuMaroon}} icon={faXmark} role="presentation" />;
const DeleteIcon = () => <FontAwesomeIcon style={{color: Styles.AsuMaroon}} icon={faTrash} role="presentation" />;
const QuestionCircle = () => <FontAwesomeIcon style={{height: '16px'}} icon={faQuestionCircle} role="presentation" />;
const CopyIcon = () => <FontAwesomeIcon style={{color: Styles.AsuBlueDarker}} icon={faCopy} role="presentation" />;
const TraingleExclamation = () => <FontAwesomeIcon icon={faTriangleExclamation} role="presentation" />;

export {EditIcon, SaveIcon, CancelIcon, DeleteIcon, QuestionCircle, TraingleExclamation, CopyIcon};