export enum FormDataMappingMode {
  None,
  ODS,
  Custom
}

export const FormDataMappingModeDisplay = {
  [FormDataMappingMode.None]: 'None',
  [FormDataMappingMode.ODS]: 'Employee Data',
  [FormDataMappingMode.Custom]: 'Custom',
}