import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { setFocusOn } from "../../utils/document-interactions";
import { TextButton, ToolTipButton } from "../Button";

const SaveCancelContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const ConfirmCancel = styled.div`
  margin-top: 5px;
`;

export const NoLink = styled.a`
  cursor: pointer;
  padding-left: 10px;
`;

interface Props {
  onSaveClicked: () => void,
  cancelLink: string,
  saveText?: string,
  cancelText?: string,
  disableSave?: boolean,
  disableCancel?: boolean,
  saveTooltip?: string,
  cancelTooltip?: string
}

const IntegrationSaveCancel: React.FC<Props> = ({
  onSaveClicked,
  cancelLink,
  saveText = 'Save',
  cancelText = 'Cancel',
  disableSave = false,
  disableCancel = false,
  saveTooltip = 'Save integration',
  cancelTooltip = 'Cancel edits'
}) => {
  const [cancelEdit, setCancelEdit] = useState(false);
  const navigate = useNavigate();

  const confirmCancel = () => {
    navigate(cancelLink);
  };

  const continueEditing = () => {
    setFocusOn("cancel-edit-integration")
    setCancelEdit(false);
  };

  return (
    <SaveCancelContainer>
      {cancelEdit ? (
        <ConfirmCancel>
          <div>
            Cancel and lose changes?&nbsp;
            <TextButton
              autoFocus
              textColor={'orange'}
              onClick={confirmCancel}
              aria-label="Confirm cancel"
            >Yes</TextButton>
            &nbsp;
            <TextButton 
              textColor={'blue'}
              onClick={continueEditing}
              aria-label="Continue editing integration"
            >No</TextButton>
          </div>
        </ConfirmCancel>
      ) : (
        <div>
          <ToolTipButton
            variant="primary"
            onClick={onSaveClicked}
            disabled={disableSave}
            toolTip={saveTooltip}
          >{saveText}</ToolTipButton>
          <ToolTipButton
            id="cancel-edit-integration"
            variant="secondary"
            onClick={()=>setCancelEdit(true)}
            style={{marginLeft: '10px'}}
            disabled={disableCancel}
            toolTip={cancelTooltip}
          >{cancelText}</ToolTipButton>
        </div>
      )}
    </SaveCancelContainer>
  );
};

export {IntegrationSaveCancel};