import React, {useState} from 'react';
import styled from "styled-components";
import Fuse from 'fuse.js';
import FuseResult = Fuse.FuseResult;
import { ListTable, ListTableHeaderRow } from '../Table';
import { Integration, ListRow } from './ListRow';
import { UseMutationResult, UseQueryResult } from 'react-query';
import { TextField } from '../CommonElements/TextField';
import { CenterMessage, IntegrationForm } from '../CommonElements';
import { ToolTipButton } from '../Button';
import { useSessionStorageState } from '../../auth/utils/session-storage';
import { SessionStorageKey } from '../../enums/SessionStorageKey';
import { useAuth } from '../../auth/controller';
import { CheckboxField } from '../CommonElements/CheckboxField';

const HeaderRow = styled(ListTableHeaderRow)`
  th:nth-child(1) { // Integration Name Header
    width: 30%;
    border-left: none;
    text-align: left;
  }
  th:nth-child(2) { // Sign URL Header
    width: 35%;
  }
  th:nth-child(3) { // Meta Data Header
    width: 20%;
  }
  th:nth-child(4) { // Manage Header
    width: 15%;
  }
`;

const SearchRow = styled.div`
  display: flex;
  align-items: center;

  .form-group {
    width: 90%;
  }
`;

const GroupHeader = styled.div`
  font-size: x-large;
  margin: 20px 0 0 0;
`;

interface ListFilters {
  searchInput: string,
  viewMine: boolean
}

interface Props {
  listKey: string,
  integrations: Integration[],
  noIntegrationsMessage: string,
  useDeleteIntegration(id: string): UseMutationResult<void, Error, void>,
  useDisableIntegration(id: string): UseMutationResult<void, Error, void>,
  useEnableIntegration(id: string): UseMutationResult<void, Error, void>,
  urlTitle: string,
  useExportIntegrations: () => UseQueryResult<string, Error>
}

const IntegrationsList: React.FC<Props> = ({
  listKey,
  integrations,
  noIntegrationsMessage,
  useDeleteIntegration,
  useDisableIntegration,
  useEnableIntegration,
  urlTitle,
  useExportIntegrations
}) => {
  const {userId} = useAuth();
  const [showDeletePromptFor, setShowDeletePromptFor] = useState<null|string>(null);
  const {isLoading: exporting, isError: exportError, isSuccess: exportSuccess, data: exportData, refetch: exportIntegrations, remove: resetExport} = useExportIntegrations();
  const [listFilters, setListFilters] = useSessionStorageState<ListFilters>(`${SessionStorageKey.ListFilters}-${listKey}`, {
    searchInput: "",
    viewMine: true
  });

  let filteredList: Integration[] = integrations
    .filter(integration => !listFilters.viewMine || userId === integration.createdBy);

  if (listFilters.searchInput) {
    const options = {
      isCaseSensitive: false,
      shouldSort: true,
      keys: [
        "name",
        "id"
      ]
    };
    const fuse = new Fuse(filteredList, options);
    const searchResults: FuseResult<Integration>[] = fuse.search(listFilters.searchInput);
    filteredList = searchResults.map(result => result.item);
  }

  const groupedIntegrations = filteredList.reduce((map: {[groupName: string]: Integration[]}, currentIntegration) => {
    if (map[currentIntegration.groupName] === undefined) {
      map[currentIntegration.groupName] = [];
    }
    map[currentIntegration.groupName].push(currentIntegration);
    return map;
  }, {});

  const promptForDelete = (id: string) => {
    setShowDeletePromptFor(id);
  }

  if (exportSuccess && exportData) {
    const blob = new Blob([exportData], { type: 'text/tsv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'integrations.tsv');
    a.click();
    resetExport();
  }

  return (
    <>
      <IntegrationForm>
        <SearchRow>
          <TextField
            id={`integration-search-field`}
            label={'Search:'}
            value={listFilters.searchInput}
            onValueChange={(_key, searchInput) => setListFilters({...listFilters, searchInput})}
            toolTip={`This input will filter the list of integrations below`}
          />
          <ToolTipButton
            disabled={exporting || exportError}
            toolTip="Export all integrations to tsv"
            onClick={exportIntegrations}
          >{exporting ? 'Exporting...' : exportError ? 'Export Error' : 'Export All'}</ToolTipButton>
        </SearchRow>
        <CheckboxField
          id='view-my-integrations'
          label='View Only My Integrations'
          value={listFilters.viewMine}
          onValueChange={() => setListFilters({...listFilters, viewMine: !listFilters.viewMine})}
          checkedValue={true}
          uncheckedValue={false}
        />
      </IntegrationForm>
      {!filteredList.length ? <CenterMessage message={listFilters.searchInput === "" ? noIntegrationsMessage : "No results for current search"} /> : null}
      {Object.keys(groupedIntegrations).sort().map(groupName => {
        const groupDisplayName = groupName ? `${groupName}${groupName.toLowerCase().includes('group') ? '' : ' Group'}` : 'Ungrouped';
        return (
          <div key={groupName}>
            <GroupHeader>{groupDisplayName}</GroupHeader>
            <hr />
            <ListTable>
              <caption>List of {groupDisplayName} Integrations</caption>
              <tbody>
                <HeaderRow>
                  <th>Integration Name</th>
                  <th>{urlTitle}</th>
                  <th>Metadata</th>
                  <th>Manage</th>
                </HeaderRow>
                {groupedIntegrations[groupName].map(integration => {
                  return (
                    <ListRow
                      key={integration.id}
                      integration={integration}
                      showDeletePrompt={showDeletePromptFor === integration.id}
                      promptForDelete={promptForDelete}
                      useDeleteIntegration={useDeleteIntegration}
                      useDisableIntegration={useDisableIntegration}
                      useEnableIntegration={useEnableIntegration}
                      disableManageButtons={Boolean(showDeletePromptFor)}
                    />
                  );
                })}
              </tbody>
            </ListTable>
          </div>
        )
      })}
    </>
  );
}

export default IntegrationsList;