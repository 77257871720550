import { DateFormat, FormFieldValidation } from "adobe-sign-types";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { adobeDateFormatFactory } from "../../utils/date-format-factory";
import { TextField } from "./TextField";

const AvailabilityWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .date-div {
    width: 48%;
  }
`;

interface Props {
  startDate: number|null,
  setStartDate: (value: number|null) => void,
  endDate: number|null,
  setEndDate: (value: number|null) => void,
  onFormErrorsChange: (formError: {[key:string]: boolean}) => void
}

const startAndEndDateFormat = DateFormat.MM_DD_YYYY;
const {getFormattedDate, getParsableDate} = adobeDateFormatFactory(startAndEndDateFormat);
const dateStringFromNumber = (dateNumber: number | null): string => {
  if (!dateNumber) {
    return '';
  }

  try {
    return getFormattedDate(new Date(dateNumber));
  } catch (error) {
    return '';
  }
};
const dateToNumber = (dateString: string): number | null => {
  if (!dateString) {
    return null;
  }

  try {
    return (new Date(getParsableDate(dateString))).valueOf();
  } catch (error) {
    return null;
  }
};

const IntegrationAvailabilityRange: React.FC<Props> = ({onFormErrorsChange, startDate, setStartDate, endDate, setEndDate}) => {
  const [startDateString, setStartDateString] = useState(dateStringFromNumber(startDate));
  const [endDateString, setEndDateString] = useState(dateStringFromNumber(endDate));

  const onValidStartDateValueChange = useCallback((_key: string, value: string) => {
    setStartDate(dateToNumber(value))
  }, [setStartDate]);

  const onValidEndDateValueChange = useCallback((_key: string, value: string) => {
    setEndDate(dateToNumber(value))
  }, [setEndDate]);

  return (
    <AvailabilityWrapper>
      <div className="date-div">
        <TextField 
          id="availability-start-date"
        label="Availability Start Date"
          value={startDateString}
          onValueChange={(_key: string, value: string) => setStartDateString(value)}
          onValidValueChange={onValidStartDateValueChange}
          validationType={FormFieldValidation.Date}
          onFormErrorsChange={onFormErrorsChange}
          toolTip="Date that this integration link will start to be available. If this field is filled, and the current date is less than the given value, anyone who visits the integration link will get an error."
          validationData={DateFormat.MM_DD_YYYY}
        />
      </div>
      <div className="date-div">
        <TextField 
          id="availability-end-date"
          label="Availability End Date"
          value={endDateString}
          onValueChange={(_key: string, value: string) => setEndDateString(value)}
          onValidValueChange={onValidEndDateValueChange}
          validationType={FormFieldValidation.Date}
          onFormErrorsChange={onFormErrorsChange}
          toolTip="The last day that this integration link will remain available. If this field is filled, and the current date is greater than the given value, anyone who visits the integration link will get an error."
          validationData={DateFormat.MM_DD_YYYY}
        />
      </div>
    </AvailabilityWrapper>
  );
};

export { IntegrationAvailabilityRange }