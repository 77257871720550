import { Link } from "react-router-dom";
import styled from "styled-components";
import { CopyContent } from "../components/CommonElements/CopyContent";
import { PageTitle } from "../components/Layout";

const NavBox = styled.div`
  padding-top: 20px;
  text-align: center;
  margin-bottom: 70px;

  a {
    width: 100%;
    border: 1px solid black;
    padding: 30px;
    font-size: x-large;
    text-align: center;
    border-radius: 10px;
    margin: 10px;
  }
`;

const agreementStatusPageUrl = `${window.location.origin}/agreements/status`;

const Home = () => {
  return (
    <main id="skip-to-content">
      <PageTitle>Home</PageTitle>
      <hr />
      <h1>Admin Pages</h1>
      <p style={{fontSize: 'large'}}>
        Welcome to the Adobe Sign Integrations application. As an admin, you can navigate to the Templates or Workflows pages below to modify template and workflow integrations.
      </p>
      <br />
      <NavBox>
        <nav aria-label="Integrations Navigation">
          <Link to="/templates">
            Templates
          </Link>
          <Link to="/workflows">
            Workflows
          </Link>
        </nav>
      </NavBox>
      <hr />
      <h1>Agreement Status Page</h1>
      <p style={{fontSize: 'large'}}>
        Initiators of template or workflow integrations can visit the Agreement Status page to see what status their agreement is in.
        Copy this link an display it on your site along side your template or workflow integration urls to give intitiators easy access to the status of their agreements. 
      </p>
      <p style={{fontSize: 'large'}}>
        <br />{agreementStatusPageUrl} <CopyContent content={agreementStatusPageUrl} toolTip="Copy Agreement Status Page Link" />
      </p>
      <hr />
    </main>
  );
}

export { Home };