import styled from "styled-components";

export const ListContainer = styled.div`
  display: flex; 
  flex-direction: column;
`;

export const PageTitle = styled.h1`
  font-size: 50px;
  margin-top: 50px;
  margin-bottom: 20px;
`;