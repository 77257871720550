import { StatusPageAgreement } from "asu-types";
import axios from "axios";
import { useQuery } from "react-query";
import { useAuth } from "../auth/controller";
import useErrorHandler from "./use-error-handler";

const API_URL = process.env.REACT_APP_ADSIGN_API_URL;

const useGetStatusPageAgreements = () => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  return useQuery<StatusPageAgreement[], Error>(["agreements"], async () => {
    const {data} = await axios.get(`${API_URL}/agreements/statusPage`, {
      headers: {
        'Authorization': 'Bearer ' + await auth.getToken()
      }
    });
    return data;
  },{
    retry: ((failureCount) => failureCount < 1),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: errorHandler.handleError,
  });
}

export { useGetStatusPageAgreements }