import React, {useState} from 'react';
import {useIntegrationDispatch, useIntegrationStore} from "../../integration-store";
import DataMapping from "./components/DataMapping/DataMapping";
import {MappingProvider} from "./components/DataMapping/mapping-store";
import {ToolTipButton} from "../../../Button";
import { FormDataMapping } from 'asu-types';
import { setFocusOn } from '../../../../utils/document-interactions';

const FormDataMappings = () => {
  const dispatch = useIntegrationDispatch();
  const {formDataMappings} = useIntegrationStore();
  const [addMapping, setAddMapping] = useState(false);
  const [editMapping, setEditMapping] = useState<number|'new'>(-1);
  const [confirmDelete, setConfirmDelete] = useState<number|'new'>(-1);

  const addNewMapping = () => {
    setEditMapping('new');
    setAddMapping(true);
  }

  const cancelNewMapping = () => {
    setEditMapping(-1);
    setAddMapping(false);
    setFocusOn('add-data-mapping');
  }

  const setEditMode = (index: number|'new') => {
    setAddMapping(false);
    setEditMapping(index);
  }

  const saveMapping = (index: number|'new', mappingData: FormDataMapping) => {
    if (index === 'new') {
      formDataMappings.push(mappingData);
    } else {
      formDataMappings[index] = mappingData;
    }

    dispatch({
      type: 'setFormDataMappings',
      payload: {
        formDataMappings
      }
    });
    setEditMapping(-1);
    setAddMapping(false);
  }

  const deleteMapping = (index: number) => {
    formDataMappings.splice(index, 1);
    dispatch({
      type: 'setFormDataMappings',
      payload: {
        formDataMappings: formDataMappings
      }
    });
  }

  return (
    <div className={'form-group'}>
      <label aria-label={'Template Field Data Mappings'}>Template Field Data Mappings: </label>
      {formDataMappings.map((dataMapping: FormDataMapping, index: number) => {
        return (
          <MappingProvider key={'mapping-provider-'+index}>
            <DataMapping
              key={'data-mapping-'+index}
              mappingIndex={index}
              mappingData={dataMapping}
              editMode={editMapping === index}
              confirmDelete={confirmDelete === index}
              setEditMode={setEditMode}
              setConfirmDelete={setConfirmDelete}
              cancelNewMapping={cancelNewMapping}
              saveMapping={saveMapping}
              deleteMapping={deleteMapping}
            />
          </MappingProvider>
        )
      })}
      {addMapping ? (
          <MappingProvider key={'mapping-provider-new'}>
            <DataMapping
              key={'data-mapping-new'}
              mappingIndex={'new'}
              editMode={true}
              confirmDelete={false}
              setEditMode={setEditMode}
              setConfirmDelete={setConfirmDelete}
              cancelNewMapping={cancelNewMapping}
              saveMapping={saveMapping}
              deleteMapping={deleteMapping}
            />
          </MappingProvider>
      ) : null }
      <div className={'add-button-container'}>
        <ToolTipButton
            id='add-data-mapping'
            toolTip='Add a data mapping to this integration.'
            variant={'secondary'}
            className={addMapping ? 'disabled' : ''}
            disabled={addMapping}
            onClick={addNewMapping}>Add Data</ToolTipButton>
      </div>
    </div>
  );
}

export { FormDataMappings };