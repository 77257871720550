import {useLocation} from "react-router-dom";
import InfoAlert from "../../../components/InfoAlert";
import { ListContainer, PageTitle } from "../../../components/Layout";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Integration } from "../../../components/IntegrationsList/ListRow";
import IntegrationsList from "../../../components/IntegrationsList/IntegrationsList";
import { useDeleteTemplate, useDisableTemplate, useEnableTemplate, useExportTemplates, useGetTemplates } from "../../../hooks/use-templates";
import AddTemplate from "./components/AddTemplate";
import { CenterMessage } from "../../../components/CommonElements";

interface LocationState {
  alert?: {
    message: string;
    type: 'success' | 'error'
  };
}

const TemplatesMain = () => {
  const location = useLocation();
  const {alert} = location.state as LocationState || '';
  if (alert) {
    // clear state after retrieving alert to avoid reshowing alerts on refresh
    window.history.replaceState({}, document.title);
  }

  const templates = useGetTemplates();
  let existingTemplateIds: string[] = [];
  if (templates.data) {
    existingTemplateIds = templates.data.map(template => template.adobeSignId);
    templates.data = templates.data.sort((a, b) => a.name.localeCompare(b.name));
  }

  const templateIntegrations = templates.data?.map(template => {
    return {
      id: template.adobeSignId,
      name: template.name,
      url: `${process.env.REACT_APP_ADSIGN_API_URL}/agreements/create/${template.adobeSignId}`,
      editPage: `/template/${template.adobeSignId}/edit`,
      enabled: template.enabled,
      createdBy: template.createdBy,
      lastUpdateBy: template.lastUpdatedBy,
      lastUsed: template.lastUsed,
      groupName: template.groupName
    } as Integration;
  }) || [];

  return (
    <>
      <Breadcrumbs currentPageTitle={'Templates'} />
      <main id="skip-to-content">
        <PageTitle>Template Integrations</PageTitle>
        {alert ? <InfoAlert type={alert.type} message={alert.message} timeout={8000} /> : null}
        {templates.status === 'loading' ? (
          <CenterMessage message='Loading template integrations...' />
        ) : templates.status === 'success' && templates.data ? (
          <ListContainer>
            <AddTemplate existingIds={existingTemplateIds} />
            <IntegrationsList
              listKey="templates"
              integrations={templateIntegrations}
              noIntegrationsMessage="No integrations exist. Create one!"
              useDeleteIntegration={useDeleteTemplate}
              useDisableIntegration={useDisableTemplate}
              useEnableIntegration={useEnableTemplate}
              urlTitle="Signing Url"
              useExportIntegrations={useExportTemplates}
            />
          </ListContainer>
        ): (
          <div>Failed to load template integrations</div>
        )}
      </main>
    </>
  );
}

export { TemplatesMain };