import { FormField } from "adobe-sign-types";
import { WorkflowFullConfig } from "asu-types";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { setFocusOn } from "../../utils/document-interactions";
import { DeleteIcon } from "../ActionIcon";
import { TextButton, ToolTipButton, ToolTipIconButton } from "../Button";
import { SectionHeader } from "../CommonElements";
import { ToolTip } from "../ToolTip";

const AddSenderFieldButtonDiv = styled.div`
  padding: 20px;
  text-align: center;
`;

const SenderFieldDiv = styled.div`
  .form-group {
    width: 70%;
    display: inline-block;
    
    select {
      width: 100%;
    }
  }

  .button-div {
    display: inline-block;
    width: 30%;
    text-align: center;
  }
`;

const ConfirmDeletePrompt = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center; 
  gap: 20px;
`;

interface Props {
  formFields: FormField[],
  senderFields: string[],
  onSenderFieldsChange: (senderFields: string[]) => void,
  workflowConfig: WorkflowFullConfig
}

const SenderFieldsConfig: React.FC<Props> = ({formFields, senderFields, onSenderFieldsChange, workflowConfig}) => {
  const [confirmDelete, setConfirmDelete] = useState(-1);

  const senderFieldOptions = useMemo(() => {
    return formFields
      .filter(formField => formField.assignee === 'recipient0' && !senderFields.includes(formField.name))
      .sort((a, b) => (a.name > b.name) ? 1 : -1);
  }, [formFields, senderFields]);

  const addSenderField = () => {
    if (!senderFields.some(senderField => !senderField)) {
      onSenderFieldsChange([...senderFields, '']);
    }
  };

  const setSenderField = (index: number, value: string) => {
    const newSenderFields = [...senderFields];
    newSenderFields[index] = value;
    onSenderFieldsChange(newSenderFields);
  };

  const deleteSenderField = (index: number) => {
    setConfirmDelete(-1);
    const newSenderFields = [...senderFields];
    newSenderFields.splice(index, 1);
    onSenderFieldsChange(newSenderFields);
  };

  const cancelDelete = () => {
    setFocusOn(`delete-sender-field-${confirmDelete}`);
    setConfirmDelete(-1);
  }

  return (
    <>
      <SectionHeader id="sender-field-header">
        Initiator Fields
        <ToolTip text="List of fields that you would like the initiator to answer on the initiator form. When you add an initiator field here, it becomes available for use in recipient conditional routing below."></ToolTip>
      </SectionHeader>
      {formFields.length ? (
        <>
          {senderFields.map((senderField, index) => (
            <SenderFieldDiv key={senderField}>
              <div className={'form-group'}>
                <select 
                  autoFocus={!senderField}
                  value={senderField}
                  onChange={(event) => setSenderField(index, event.target.value)}
                  aria-labelledby="sender-field-header"
                >
                  <option value={''}>- Select Field -</option>
                  {!senderField ? null : (
                    <option value={senderField}>{senderField}</option>
                  )}
                  {senderFieldOptions
                    .map(formField =>
                      <option key={'form-field-name-'+formField.name} value={formField.name}>
                        {formField.name}
                      </option>
                  )}
                </select>
              </div>
              <div className="button-div">
                {confirmDelete === index ? (
                  <ConfirmDeletePrompt>
                    <div>Delete?</div>
                    <TextButton
                      autoFocus
                      textColor={'orange'}
                      onClick={() => deleteSenderField(index)}
                      aria-label={`Confirm delete initiator field ${senderField}`}
                    >Yes</TextButton>
                    <TextButton
                      textColor={'blue'}
                      onClick={cancelDelete}
                      aria-label={`Cancel delete initiator field ${senderField}`}
                    >No</TextButton>
                  </ConfirmDeletePrompt>
                ) : (
                  <ToolTipIconButton
                    id={`delete-sender-field-${index}`}
                    onClick={() => setConfirmDelete(index)}
                    toolTip={`Delete initiator field`}
                  ><DeleteIcon /></ToolTipIconButton>
                )}
              </div>
            </SenderFieldDiv>
          ))}
          <AddSenderFieldButtonDiv>
            <ToolTipButton
              toolTip="Add additional initiator field to initiator form"
              variant="primary"
              onClick={addSenderField}
            >Add Initiator Field</ToolTipButton>
          </AddSenderFieldButtonDiv>
          {workflowConfig.sendOnBehalfOfSsoUser ? (
            <p>
              Since <b>Send on Behalf of Initiator</b> is enabled, your email is being used to fetch the template fields that are displayed above.
              If an initiator is unable to see these fields on the form, their Adobe Sign account may not have access to the workflow or its templates.
              Please make sure their account has access to the workflow and its templates so that they can use the initiator form.
            </p>
          ) : null}
        </>
      ) : (
        <>
          <p>Looks like there were no initiator fields loaded.</p>
          {workflowConfig.sendOnBehalfOfSsoUser ? (
            <p>
              Since <b>Send on Behalf of Initiator</b> is enabled, your email is being used to fetch the template fields for this page.
              Please verify you have access to the templates that are in this workflow.
              You should also make sure that any user who initiates this form also has access to those templates, or the initiator form will not work.
            </p>
          ): workflowConfig.sendOnBehalfOf ? (
            <p>
              The <b>{workflowConfig.sendOnBehalfOf}</b> account is being used to fetch the template fields because it is set in the <b>Send on Behalf of</b> field.
              Please verify that account has access to the templates that are in this workflow.
            </p>
          ): (
            <p>
              The Adobe Sign Admin account is being used to fetch the template fields because there is no value in the <b>Send on Behalf of</b> field.
              Please verify the Adobe Sign Admin account has access to the templates that are in this workflow.
            </p>
          )}
          <p>
            If you make any changes to the workflow config to alleviate this issue, please save them and return to edit the workflow to see your changes.
          </p>
        </>
      )}
    </>
  );
};

export { SenderFieldsConfig };