import { TemplateConfig } from 'asu-types';
import React from 'react';
import { CheckboxField } from '../../../CommonElements/CheckboxField';
import {useIntegrationDispatch, useIntegrationStore} from "../../integration-store";

const DisableEmails = () => {
  const dispatch = useIntegrationDispatch();
  const {config}: {config: TemplateConfig} = useIntegrationStore();

  const handleChange = (value: boolean) => {
    dispatch({type: 'setConfig', payload: {
      config: {
        ...config,
        disableInitEmails: value
      }
    }});
  }

  return (
    <CheckboxField 
      id="disable-emails"
      label="Disable Initiation Emails for All Participants"
      value={config.disableInitEmails}
      onValueChange={(_key, value) => handleChange(value)}
      onFormErrorsChange={() => {}}
      checkedValue={true}
      uncheckedValue={false}
    />
  );
}

export { DisableEmails };