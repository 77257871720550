import { DateFormat } from "adobe-sign-types";

enum DateLocale {
  US = 'en-US',
  GB = 'en-GB'
}

enum DatePartFormat {
  Numeric = "numeric",
  TwoDigit = '2-digit',
  Long = 'long',
  Short = 'short'
}

enum DatePart {
  Month = 'month',
  Year = 'year',
  Weekday = 'weekday',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute'
}

const datePartConfigMap: {[key: string]: {part: DatePart, format: DatePartFormat}} = {
  "M": {
    part: DatePart.Month,
    format: DatePartFormat.Numeric
  },
  "MM": {
    part: DatePart.Month,
    format: DatePartFormat.TwoDigit
  },
  "MMM": {
    part: DatePart.Month,
    format: DatePartFormat.Short
  },
  "MMMM": {
    part: DatePart.Month,
    format: DatePartFormat.Long
  },
  "YY": {
    part: DatePart.Year,
    format: DatePartFormat.TwoDigit
  },
  "YYYY": {
    part: DatePart.Year,
    format: DatePartFormat.Numeric
  },
  "D": {
    part: DatePart.Day,
    format: DatePartFormat.Numeric
  },
  "DD": {
    part: DatePart.Day,
    format: DatePartFormat.TwoDigit
  },
  "HH": {
    part: DatePart.Hour,
    format: DatePartFormat.TwoDigit
  },
  "MiMi": {
    part: DatePart.Minute,
    format: DatePartFormat.TwoDigit
  },
}

const commonFormat = (date: Date, format: string): string => {
  const locale = format[0]?.toLowerCase() === 'd'
    ? DateLocale.GB
    : DateLocale.US;
  const dateParts = format.split('_');
  const options: {[key: string]: any} = dateParts.reduce((state, datePart) => {
    const datePartConfig = datePartConfigMap[datePart];
    
    if (!datePartConfig) {
      return {...state};
    }

    return {
      ...state,
      [datePartConfig.part]: datePartConfig.format
    }
  }, {});

  if (Object.keys(options).some(key => key === 'hour')) {
    options['hour12'] = true;
  }

  return date.toLocaleString(locale, options);
}

interface FormatConfig {
  parsableDate: (dateString: string) => string,
  formattedDate: (date: Date) => string,
  formatDisplay: string
}

const adobeFormatConfigMap: {[key: string]: FormatConfig} = {
  [DateFormat.MMM_DD_YYYY]: {
    parsableDate: dateString => dateString,
    formattedDate: date => commonFormat(date, DateFormat.MMM_DD_YYYY),
    formatDisplay: 'mmm dd, yyyy'
  },
  [DateFormat.MM_DD_YY]: {
    parsableDate: dateString => dateString,
    formattedDate: date => commonFormat(date, DateFormat.MM_DD_YY),
    formatDisplay: 'mm/dd/yy'
  },
  [DateFormat.MM_DD_YYYY]: {
    parsableDate: dateString => dateString,
    formattedDate: date => commonFormat(date, DateFormat.MM_DD_YYYY),
    formatDisplay: 'mm/dd/yyyy'
  },
  [DateFormat.DD_MM_YY]: {
    parsableDate: dateString => {
      const [day, month, year] = dateString.split('/');
      return `${month}/${day}/${year}`;
    },
    formattedDate: date => commonFormat(date, DateFormat.DD_MM_YY),
    formatDisplay: 'dd/mm/yy'
  },
  [DateFormat.DD_MM_YYYY]: {
    parsableDate: dateString => {
      const [day, month, year] = dateString.split('/');
      return `${month}/${day}/${year}`;
    },
    formattedDate: date => commonFormat(date, DateFormat.DD_MM_YYYY),
    formatDisplay: 'dd/mm/yyyy'
  },
  [DateFormat.MM_YY]: {
    parsableDate: dateString => {
      const [month, year] = dateString.split('/');
      return `${month}/01/${year}`;
    },
    formattedDate: date => commonFormat(date, DateFormat.MM_YY),
    formatDisplay: 'mm/yy'
  },
  [DateFormat.DD_MMM_YYYY]: {
    parsableDate: (dateString) => {
      const [day, month, year] = dateString.split('-');
      return `${month} ${day}, ${year}`;
    },
    formattedDate: (date) => {
      if (!(date instanceof Date)) {
        return '';
      }
      return `${getD(date, DatePartFormat.TwoDigit)}-${getM(date, DatePartFormat.Short)}-${getY(date, DatePartFormat.Numeric)}`;
    },
    formatDisplay: 'dd-mmm-yyyy'
  },
  [DateFormat.HH_MiMi]: {
    parsableDate: dateString => `01/01/2020 ${dateString}`,
    formattedDate: date => commonFormat(date, DateFormat.HH_MiMi),
    formatDisplay: 'HH:MM PM'
  },
}

const getDatePart = (date: Date, options: object) => {
  return date.toLocaleDateString(DateLocale.US, options);
}

const getM = (date: Date, format: DatePartFormat) => {
  return getDatePart(date, {month: format});
}

const getD = (date: Date, format: DatePartFormat) => {
  return getDatePart(date, {day: format});
}

const getY = (date: Date, format: DatePartFormat) => {
  return getDatePart(date, {year: format});
}

export const adobeDateFormatFactory = (format: string) => {
  const config = adobeFormatConfigMap[format]
    ? adobeFormatConfigMap[format]
    : adobeFormatConfigMap.DD_MMM_YYYY;

  return {
    getParsableDate: config.parsableDate,
    getFormattedDate: config.formattedDate,
    formatDisplay: config.formatDisplay
  };
};