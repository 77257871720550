import { DateFormat, FormField, FormFieldValidation, InputType } from "adobe-sign-types";
import { useMemo } from "react";
import { Constants } from "../../../enums/Constants";
import { SectionHeader } from "../../CommonElements";
import { CheckboxField } from "../../CommonElements/CheckboxField";
import { DropDownField } from "../../CommonElements/DropDownField";
import { RadioField } from "../../CommonElements/RadioField";
import { TextField } from "../../CommonElements/TextField";

const singleRowHeight = 16;

interface Props {
  senderFields: FormField[],
  onFormErrorsChange: (formError: {[key:string]: boolean}) => void,
  onSenderFieldAnswersChange: (senderFieldAnswers: {[key:string]: string}) => void,
  senderFieldAnswers: {[key: string]: string}
}

const SenderFields: React.FC<Props> = ({senderFields, onFormErrorsChange, onSenderFieldAnswersChange, senderFieldAnswers}) => {
  const setFieldValue = (key: string, value: string) => {
    onSenderFieldAnswersChange({
      ...senderFieldAnswers,
      [key]: value
    });
  };

  const getNumberOfRows = (formField: FormField) => {
    const largestHeight = formField.inputType === InputType.Multiline && formField.locations.length
      ? Math.max(...formField.locations.map(l => l.height))
      : singleRowHeight;
    return Math.ceil(largestHeight / singleRowHeight);
  };

  const fieldOptionsMap = useMemo(() => {
    const fieldOptionsMap: {[key: string]: [string, string][] | undefined} = {};

    senderFields.forEach(field => {
      fieldOptionsMap[field.name] = field.hiddenOptions?.map((key, index) => [key, field.visibleOptions?.[index] || key])
    });

    return fieldOptionsMap;
  }, [senderFields]);

  return (
    <>
      {senderFields.length ? (
        <SectionHeader>Initiator Fields</SectionHeader>
      ) : null}
      {senderFields.map(field => {
        const label = field.tooltip || field.name;
        const id = `sender-field-${field.name}`;
        const value = senderFieldAnswers[field.name];

        if (field.inputType === InputType.DropDown) {
          return <DropDownField
            key={id}
            id={id}
            label={label}
            formKey={field.name}
            value={value}
            onValueChange={setFieldValue}
            required={field.required}
            onFormErrorsChange={onFormErrorsChange}
            options={fieldOptionsMap[field.name] || []}
            disabled={false}
          />;
        }
        else if (field.inputType === InputType.Checkbox) {
          return <CheckboxField
            key={id}
            id={id}
            label={label}
            formKey={field.name}
            value={value}
            onValueChange={setFieldValue}
            required={field.required}
            onFormErrorsChange={onFormErrorsChange}
            disabled={false}
            checkedValue={Constants.CheckboxCheckedValue}
            uncheckedValue={Constants.CheckboxUncheckedValue}
          />;
        }
        else if (field.inputType === InputType.Radio) {
          return <RadioField
            key={id}
            id={id}
            label={label}
            formKey={field.name}
            value={value}
            onValueChange={setFieldValue}
            required={field.required}
            onFormErrorsChange={onFormErrorsChange}
            options={fieldOptionsMap[field.name] || []}
            disabled={false}
          />;
        }
        else if (field.validation === FormFieldValidation.Time) {
          return <TextField
            key={id}
            id={id}
            label={label}
            formKey={field.name}
            value={value}
            onValueChange={setFieldValue}
            required={field.required}
            validationType={field.validation}
            onFormErrorsChange={onFormErrorsChange}
            validationData={DateFormat.HH_MiMi}
          />;
        }
        else {
          return <TextField
            key={id}
            id={id}
            label={label}
            formKey={field.name}
            value={value}
            onValueChange={setFieldValue}
            required={field.required}
            validationType={field.validation}
            onFormErrorsChange={onFormErrorsChange}
            minLength={field.minLength}
            maxLength={field.maxLength}
            minValue={field.minValue}
            maxValue={field.maxValue}
            numberOfRows={getNumberOfRows(field)}
            validationData={field.validationData}
          />;
        }
      })}
    </>
  );
};

export { SenderFields }