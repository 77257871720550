import { ConditionalRoutingCondition } from "./conditional-routing-condition";

export class ConditionalRoutingMember {
  email: string;
  conditions: ConditionalRoutingCondition[];
  senderFilled: boolean;

  constructor(data: ConditionalRoutingMember) {
    this.email = data.email;
    this.conditions = data.conditions;
    this.senderFilled = data.senderFilled ?? false;
  }
}