import { MemberInfo as IMemberInfo } from 'adobe-sign-types'
export class MemberInfo implements IMemberInfo {
  name: string;
  id: string;
  email: string;

  constructor (data?: Partial<IMemberInfo>) {
    this.name = data?.name || '';
    this.id = data?.id || '';
    this.email = data?.email || '';
  }
}