import { FormDataMappingSource } from "../enums/form-data-mapping-source";

export class FormDataMapping {
  source: FormDataMappingSource;
  sourceField: string | null;
  targetField: string;
  defaultValue: string | null;

  constructor (data?: Partial<FormDataMapping>) {
    this.source = data?.source || FormDataMappingSource.DefaultValue;
    this.sourceField = data?.sourceField || null;
    this.targetField = data?.targetField || '';
    this.defaultValue = data?.defaultValue || null;
  }
}