import { ParticipantRole, ParticipantSet as IParticipantSet } from 'adobe-sign-types';
import { MemberInfo } from './member-info';

export class ParticipantSet implements IParticipantSet {
  role: ParticipantRole;
  label?: string;
  id: string;
  status: string;
  memberInfos: MemberInfo[];

  constructor (data?: Partial<IParticipantSet>) {
    this.role = data?.role || ParticipantRole.SIGNER;
    this.label = data?.label;
    this.id = data?.id || '';
    this.status = data?.status || '';
    this.memberInfos = data?.memberInfos?.map(x => new MemberInfo(x)) || [];
  }
}