import { ParticipantSetConfig } from "asu-types";
import { ParticipantRole } from "../../../../hooks/use-templates/enums";
import makeStore from "../../../../utils/make-store";

interface Action {
  type: 'hydrateParticipantSet' | 'setRole' | 'setLabel' | 'setMemberInfos' | 'setIncludeAuthenticatedUser' | 'setOrder';
  payload: ParticipantSetConfig
}

const initialState: ParticipantSetConfig = {
  role: ParticipantRole.SIGNER,
  label: '',
  memberInfos: [],
  includeAuthenticatedUser: false,
  order: 1
}

const participantSetReducer = (state: ParticipantSetConfig, action: Action): ParticipantSetConfig => {
  switch(action.type) {
    case 'hydrateParticipantSet':
      return {
        role: action.payload.role || null,
        label: action.payload.label,
        memberInfos: action.payload.memberInfos || [],
        includeAuthenticatedUser: action.payload.includeAuthenticatedUser || false,
        order: action.payload.order || 1
      }
    case 'setOrder':
      return {
        ...state,
        order: action.payload.order
      }
    case 'setRole':
      return {
        ...state,
        role: action.payload.role
      }
    case 'setIncludeAuthenticatedUser':
      return {
        ...state,
        includeAuthenticatedUser: action.payload.includeAuthenticatedUser
      }
    case 'setMemberInfos':
      return {
        ...state,
        memberInfos: action.payload.memberInfos
      }
    default:
      return state;
  }
}

const [
  ParticipantSetProvider,
  useParticipantSetStore,
  useParticipantSetDispatch
] = makeStore(participantSetReducer, initialState, 'ParticipantSet');

export { ParticipantSetProvider, useParticipantSetStore, useParticipantSetDispatch}