import { ParticipantRole } from "adobe-sign-types";
import { MemberInfoBase } from "./member-info-base";

export class ParticipantSetConfig {
  role: ParticipantRole;
  label?: string;
  memberInfos: MemberInfoBase[];
  includeAuthenticatedUser: boolean;
  order?: number;

  constructor (data?: Partial<ParticipantSetConfig>) {
    this.role = data?.role || ParticipantRole.SIGNER;
    this.label = data?.label;
    this.memberInfos = data?.memberInfos?.map(x => new MemberInfoBase(x)) || [];
    this.includeAuthenticatedUser = data?.includeAuthenticatedUser ?? false;
    this.order = data?.order;
  }
}