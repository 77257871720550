import React from 'react';
import styled from "styled-components";
import {useMappingDispatch, useMappingStore} from "../../mapping-store";
import {SourceOption} from "./enums/sourceOption";

const DataSourceContainer = styled.div`
  width: 535px;
  display: flex;
  gap: 15px;
  padding-top: 2px;
  align-items: center;
  //border: solid 2px #00a3e0;
  
  select {
    height: 34px;
    background: #fff;
    margin-right: -11px;
  }
  
  .sourceContainer {
    min-width: 137px;
    width: 137px;
    
    .sourceSelect {
      min-width: 137px;
      width: 148px;
    }
    .sourceDisplay {
      min-width: 137px;
      width: 137px;
      background: #FAFAFA; 
      padding-left: 8px;
    }
  }
  
  .valueContainer {
    width: 413px;
    .valueInput {
      width: 372px;
      background: #FAFAFA;
    }
    .valueSelect {
      min-width: 384px;
      width: 384px;
    }
  }
`;

const sourceOptions: string[] = [
  SourceOption.DefaultValue,
  SourceOption.ODSPerson,
  SourceOption.ODSEmployee,
  SourceOption.PostData
]

const sourceLabels: {[key: string]: string} = {
  [SourceOption.DefaultValue]: 'Default Value',
  [SourceOption.ODSPerson]: 'Person',
  [SourceOption.ODSEmployee]: 'Employee',
  [SourceOption.PostData]: 'POST body',
}

const sourceValues: {[key: string]: string[]} = {
  [SourceOption.ODSPerson]: [
    'FIRST_NAME',
    'MIDDLE_NAME',
    'LAST_NAME',
    'EMPLID',
    'ASURITE_ID',
    'EMAIL_ADDRESS',
    'DEGREE',
    'DEGREE_ABBR',
    'MAJOR',
    'MAJOR_ABBR'
  ],
  [SourceOption.ODSEmployee]: [
    'TITLE',
    'EMPL_CLASS',
    'FTE',
    'FTE_PERCENT',
    'LOCATION',
    'MAILCODE',
    'DEPARTMENT',
    'DEPTID',
    'PHONE_WORK',
  ],
}

const DataSource = () => {
  const mappingDispatch = useMappingDispatch();
  const {editMode, source , sourceField , defaultValue} = useMappingStore();

  const handleSourceChange = (optionValue: string) => {
    mappingDispatch({
      type: 'setSource',
      payload: {
        source: optionValue
      }
    })
  }

  const handleSourceFieldSelect = (optionValue: string) => {
    mappingDispatch({
      type: 'setSourceField',
      payload: {
        sourceField: optionValue
      }
    })
  }

  const handleSourceFieldText = (value: string) => {
    mappingDispatch({
      type: 'setSourceField',
      payload: {
        sourceField: value
      }
    })
  }

  const handleTextValueChange = (value: string) => {
    mappingDispatch({
      type: 'setDefaultValue',
      payload: {
        defaultValue: value
      }
    })
  }

  return (
    <DataSourceContainer>
      <div className={'sourceContainer'}>
        {editMode ? (
          <select className={'sourceSelect'} value={source} onChange={(event) => handleSourceChange(event.target.value)}>
            <option value={''}>- Select Source -</option>
            {sourceOptions.map(sourceOption =>
                <option key={'source-'+sourceOption} value={sourceOption}>
                  {sourceLabels[sourceOption]}
                </option>
            )}
          </select>
        ) : (
          <input className={'sourceDisplay'} inputMode={'text'} value={sourceLabels[source] || ''} readOnly/>
        )}
      </div>
      <div className={'valueContainer'}>
        {source === SourceOption.DefaultValue ? (
          <input
            className={'valueInput'}
            inputMode={'text'}
            value={defaultValue}
            readOnly={!editMode}
            placeholder={'enter a default value'}
            onChange={(event) => handleTextValueChange(event.target.value)}
          />
        ) : source === SourceOption.PostData ? (
          <input
            className={'valueInput'}
            inputMode={'text'}
            value={sourceField}
            readOnly={!editMode}
            placeholder={'must match the field name of the POST body'}
            onChange={(event) => handleSourceFieldText(event.target.value)}
          />
        ) : (
          <>
            {editMode ? (
              <select className={'valueSelect'} value={sourceField}
                  onChange={(event) => handleSourceFieldSelect(event.target.value)}>
                <option value={''}>- Select Source Field -</option>
                {source !== '' && sourceValues[source].map(valueOption =>
                    <option key={'value-option-' + valueOption} value={valueOption}>{valueOption}</option>
                )}
              </select>
            ) : (
              <input className={'valueInput'} inputMode={'text'} value={sourceField} readOnly/>
            )}
          </>
        )}
      </div>
    </DataSourceContainer>
  );
}

export default DataSource;