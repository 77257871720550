import axios from 'axios';
import {decode} from 'jsonwebtoken';
import {createRandomString, generateCodeChallenge} from "./utils/utils";
import {deleteRefreshToken, deleteToken, fetchRefreshToken, fetchToken, storeRefreshToken, storeToken} from "./utils/token-storage";
import pk from './utils/pk';
import {verify} from 'jsonwebtoken';

export enum AuthType {
  Admin = 'ADMIN',
  General = 'General'
}

const tokenExpiryAdjustmentSeconds = 15;

export class AuthClientService {
  private readonly redirectUrl: string;
  private readonly authUrl: string;
  private readonly tokenUrl: string;
  private readonly refreshTokenUrl: string;
  private readonly logoutUrl: string;
  private readonly clientId: string;
  private readonly authScope: string;
  private readonly codeVerifierKey: string;
  private readonly returnToKey: string;

  constructor() {
    this.authUrl = 'https://weblogin.asu.edu/serviceauth/oauth2/native/allow';
    this.tokenUrl = 'https://weblogin.asu.edu/serviceauth/oauth2/native/token';
    this.refreshTokenUrl = 'https://weblogin.asu.edu/serviceauth/oauth2/token';
    this.logoutUrl = 'https://weblogin.asu.edu/cas/logout';
    this.redirectUrl = process.env.REACT_APP_SERVICEAUTH_REDIRECT_URL || '';
    this.clientId = process.env.REACT_APP_SERVICEAUTH_CLIENT_ID || '';
    this.authScope = process.env.REACT_APP_SERVICEAUTH_AUTH_SCOPE || '';

    // session storage keys
    this.codeVerifierKey = 'codeVerifierKey';
    this.returnToKey = 'returnToKey';
  }

  public async loginWithRedirect() {
    const url = await this.buildAuthorizeUrl();
    window.location['assign'](url);
  }

  private async buildAuthorizeUrl() {
    const codeVerifier = createRandomString();
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    sessionStorage.setItem(this.codeVerifierKey, codeVerifier);

    let url = this.authUrl;
    url += '?response_type=code';
    url += '&client_id=' + encodeURIComponent(this.clientId);
    url += '&redirect_uri=' + encodeURIComponent(this.redirectUrl);
    url += '&state=' + encodeURIComponent(sessionStorage.getItem(this.returnToKey) || '/');
    url += '&code_challenge_method=S256';
    url += '&code_challenge=' + codeChallenge;
    url += '&scope=' + encodeURIComponent(this.authScope);

    return url;
  }

  public async handleRedirectCallback(code: string, authType: AuthType) {
    const codeVerifier = sessionStorage.getItem(this.codeVerifierKey);
    sessionStorage.removeItem(this.codeVerifierKey);

    let url = this.tokenUrl;
    url += '?grant_type=authorization_code';
    url += '&code=' + code;
    url += '&redirect_uri=' + encodeURIComponent(this.redirectUrl);
    url += '&client_id=' + encodeURIComponent(this.clientId);
    url += '&code_verifier=' + codeVerifier;

    // Authenticate with ServiceAuth
    const serviceAuthResponse = await axios.post(url, {},
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
    const { data: serviceAuth } = serviceAuthResponse;

    storeRefreshToken(serviceAuth.refresh_token);

    // Authorize with API Auth Handler
    const token = await this.getAdminAuthTokenFromToken(serviceAuth.access_token, authType);

    if (token) {
      storeToken(token);
    }
  }

  private async getAdminAuthTokenFromToken(accessToken: string, authType: AuthType): Promise<string|null> {
    const adminAuthResponse = await axios.get(process.env.REACT_APP_ADSIGN_API_URL + `/auth/${authType === AuthType.Admin ? 'admin' : 'general'}`, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
    });
    return adminAuthResponse.data.access_token || null;
  }

  public async getUser() {
    const decodedToken = decode(fetchToken());
    return (decodedToken && decodedToken.sub) || (decodedToken && decodedToken.email);
  }

  public async getToken(authType: AuthType): Promise<string|null> {
    const token = fetchToken();

    if (!token) {
      console.log('No token returning null');
      return null;
    }

    try {
      const decodedToken = verify(token, pk, {algorithms: ['RS256']});

      // If token is not expiring soon, return it
      const nowSeconds = Math.floor(Date.now() / 1000);
      if (decodedToken.exp - tokenExpiryAdjustmentSeconds > nowSeconds) {
        return token;
      }
    } catch (error) {
      console.log(`Error while verifying stored token`, error);
    }

    const refreshToken = fetchRefreshToken();

    if (!refreshToken) {
      return null;
    }
    
    try {
      let url = this.refreshTokenUrl;
      url += '?grant_type=refresh_token';
      url += '&redirect_uri=' + encodeURIComponent(this.redirectUrl);
      url += '&client_id=' + encodeURIComponent(this.clientId);
      url += '&refresh_token=' + refreshToken;
      url += '&scope=' + encodeURIComponent(this.authScope);

      // Authenticate with ServiceAuth
      const refreshResponse = await axios.post(url, {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );

      const adminToken = await this.getAdminAuthTokenFromToken(refreshResponse.data.access_token, authType);

      if (adminToken) {
        storeToken(adminToken);
      }

      return adminToken;
    } catch (error) {
      console.log(`Error while refreshing token`, error);
      return null;
    }
  }

  public async storeRedirect(returnTo: string) {
    sessionStorage.setItem(this.returnToKey, returnTo);
  }

  public async checkSession() {
    try {
      let token = fetchToken();
      if (token) return token;
      await this.loginWithRedirect();
    } catch (error) {
      console.log('check session error: ', error);
    }
  }

  public async signOut() {
    deleteToken();
    deleteRefreshToken();
    window.location['assign'](this.logoutUrl);
  }
}