import { AgreementStatus } from "adobe-sign-types";
import { ParticipantSet } from "./participant-set";
import { WebhookLog } from "./webhook-log";

export class Agreement {
  adobeSignId: string;
  asuriteId?: string;
  adobeSignTemplateId: string;
  name: string;
  type: string;
  participantSetsInfo: ParticipantSet[];
  senderEmail: string;
  createdDate: string;
  lastEventDate: string;
  signatureType: string;
  status: AgreementStatus;
  s3Location: string;
  webhookLogs: WebhookLog[];
  formData: {[name: string]: string} | null;

  constructor (data?: Partial<Agreement>) {
    this.adobeSignId = data?.adobeSignId || '';
    this.asuriteId = data?.asuriteId || undefined;
    this.adobeSignTemplateId = data?.adobeSignTemplateId || '';
    this.name = data?.name || '';
    this.type = data?.type || '';
    this.participantSetsInfo = data?.participantSetsInfo?.map(x => new ParticipantSet(x)) || [];
    this.senderEmail = data?.senderEmail || '';
    this.createdDate = data?.createdDate || '';
    this.lastEventDate = data?.lastEventDate || '';
    this.signatureType = data?.signatureType || '';
    this.status = data?.status || AgreementStatus.OutForSignature;
    this.s3Location = data?.s3Location || '';
    this.webhookLogs = data?.webhookLogs?.map(x => new WebhookLog(x)) || [];
    this.formData = data?.formData || null;
  }
}