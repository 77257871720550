import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../components/Button";

const ButtonDiv = styled.div`
  text-align: center;
`;

const WrongSigner = () => {
  const [redirecting, setRedirecting] = useState(false);
  const [searchParams] = useSearchParams();
  const templateId = searchParams.get('templateId');

  const tryAgain = () => {
    setRedirecting(true);
    window.location.assign(`${process.env.REACT_APP_ADSIGN_API_URL}/agreements/create/${templateId}`);
  };

  return (
    <>
      <h1>Woah, aren't you a busy beaver!</h1>
      <p>We are processing your last signature. Please wait a few seconds and click <b>Try Again</b>.</p>
      <ButtonDiv>
        <Button disabled={redirecting} onClick={tryAgain}>{redirecting ? 'Redirecting...' : 'Try Again'}</Button>
      </ButtonDiv>
    </>
  )
};

export {WrongSigner}