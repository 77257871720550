import React from 'react';
import {useAuth} from "../auth/controller";

const Logout = () => {
  const auth = useAuth();
  auth.signOut();
  return (
      <div className={'container'}>
        <div className={'page-message'}>Signing out...</div>
      </div>
  );
}

export { Logout };