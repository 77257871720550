import { useEffect, useState } from "react";
import { useRemoveFormErrorOnUnmount } from "../../hooks/remove-form-error-on-unmount";
import { RadioButton, RadioButtonLabel } from "../Button";
import { RequiredAsterisk } from ".";
import { ToolTip } from "../ToolTip";
import { FieldAlert, FieldAlerts } from "./FieldAlerts";

interface Props<T> {
  id: string,
  label: string,
  value: T,
  onValueChange: (key: string, value: T) => void,
  onFormErrorsChange?: (formError: {[key:string]: boolean}) => void,
  checkedValue: T,
  uncheckedValue: T
  formKey?: string,
  required?: boolean,
  disabled?: boolean,
  toolTip?: string
}

const CheckboxField = <T extends unknown>({
  id,
  label,
  value,
  onValueChange,
  onFormErrorsChange,
  checkedValue,
  uncheckedValue,
  formKey = '',
  required = false,
  disabled = false,
  toolTip = '',
}: Props<T>) => {
  const [errors, setErrors] = useState<FieldAlert[]>([]);
  const [fieldAttempted, setFieldAttempted] = useState(false);
  useRemoveFormErrorOnUnmount(id, onFormErrorsChange);

  const updateValue = (checked: boolean) => {
    setFieldAttempted(true);
    const value = checked ? checkedValue : uncheckedValue;
    onValueChange(formKey, value);
  };

  useEffect(() => {
    const newErrors: FieldAlert[] = [];

    if (required && value !== checkedValue) {
      newErrors.push({id: id, message: `${label} is required`});
    }

    setErrors(newErrors);
    if (onFormErrorsChange) {
      onFormErrorsChange({
        [id]: !!newErrors.length
      });
    }
  }, [checkedValue, id, label, onFormErrorsChange, required, uncheckedValue, value]);

  return (
    <div className={'form-group'}>
      <fieldset className={errors.length && fieldAttempted ? 'error-field' : ''}>
        <RadioButtonLabel htmlFor={id}>
          <RadioButton
              id={id}
              type="checkbox"
              required={required}
              checked={value === checkedValue}
              onChange={(event) => updateValue(event.target.checked)}
              disabled={disabled}
              onBlur={() => setFieldAttempted(true)}
          />
          <RequiredAsterisk required={required} />{label} {toolTip ? <ToolTip text={toolTip} /> : null}
        </RadioButtonLabel>
      </fieldset>
      {!disabled ? (
        <FieldAlerts alerts={errors} showAlerts={fieldAttempted} />
      ) : null}
    </div>
  );
};

export { CheckboxField }