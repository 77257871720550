import React, {useEffect, useState} from 'react';
import {useIntegrationDispatch, useIntegrationStore} from "../integration-store";
import {TextButton} from "../../Button";
import styled from "styled-components";
import { Styles } from '../../../enums/Styles';

const ToggleContainer = styled.div`
  display: flex;
  gap: 20px;
  
  .disabled {
    color: ${Styles.AsuGray3};
    cursor: default;
    opacity: 100% !important;
  }
`;

const WorkflowConfigContainer = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-left: 20px;
  
  div {
    margin-bottom: 10px;
  }
  
  input {
    width: 505px;
    margin-bottom: 10px;
  }
  
`;

const TemplateWorkflow = () => {
  const dispatch = useIntegrationDispatch();
  const {config} = useIntegrationStore();
  const [linkWorkflow, setLinkWorkflow] = useState(false);

  useEffect(() => {
    if (!linkWorkflow && config.workflowId) {
      setLinkWorkflow(true);
    }
  }, [config.workflowId, linkWorkflow])

  const handleIdChange = (id: string) => {
    dispatch({type: 'setConfig', payload: {
      config: {
        ...config,
        workflowId: id
      }
    }});
  }

  const handleSignerLabelChange = (label: string) => {
    dispatch({type: 'setConfig', payload: {
      config: {
        ...config,
        workflowLabel: label
      }
    }});
  }

  const disableWorkflowLink = () => {
    dispatch({type: 'setConfig', payload: {
      config: {
        ...config,
        workflowId: undefined,
        workflowLabel: undefined
      }
    }});
    setLinkWorkflow(false);
  }

  return (
      <div className={'form-group'}>
        <ToggleContainer>
          <label>Link Workflow: </label>
          <TextButton
              aria-label={'enable-workflow'}
              className={linkWorkflow ? 'disabled' : ''}
              disabled={linkWorkflow}
              onClick={()=>setLinkWorkflow(true)}>Enable{linkWorkflow ? 'd' : ''}</TextButton>
          <TextButton
              aria-label={'disable-workflow'}
              className={linkWorkflow ? '' : 'disabled'}
              disabled={!linkWorkflow}
              onClick={disableWorkflowLink}>Disable{!linkWorkflow ? 'd' : ''}</TextButton>
        </ToggleContainer>
        {linkWorkflow ? (
          <WorkflowConfigContainer>
            <div style={{paddingLeft: 3}}>
              Please enter the workflow ID and the label of the first signer in the workflow
            </div>
            <div>
              <label htmlFor={'integration-workflow-id'}>Workflow ID:</label>
              <input
                  id={"integration-workflow-id"}
                  inputMode={'text'}
                  value={config.workflowId || ''}
                  onChange={(event) => handleIdChange(event.target.value)}
              />
            </div>
            <div>
              <label htmlFor={'integration-workflow-signer-label'}>Template Label:</label>
              <input
                  id={"integration-workflow-signer-label"}
                  inputMode={'text'}
                  value={config.workflowLabel || ''}
                  onChange={(event) => handleSignerLabelChange(event.target.value)}
              />
            </div>
          </WorkflowConfigContainer>
        ) : null}
      </div>
  );
}

export { TemplateWorkflow };