import {createContext, useContext, useReducer} from "react";

export default function makeStore(reducer, initialState, displayName) {
  const dispatchContext = createContext(undefined);
  dispatchContext.displayName = displayName + 'DispatchContext';

  const storeContext = createContext(initialState);
  storeContext.displayName = displayName + 'StoreContext';

  const StoreProvider = ({children}) => {
    const [store, dispatch] = useReducer(reducer, initialState);

    return (
      <dispatchContext.Provider value={dispatch}>
        <storeContext.Provider value={store}>
          {children}
        </storeContext.Provider>
      </dispatchContext.Provider>
    )
  }

  function useStore() {
    const context = useContext(storeContext);
    if(!context) {
      throw new Error(`${displayName}StoreContext must be used within a ${displayName}Provider.`)
    }
    return context;
  }

  function useDispatch() {
    const context = useContext(dispatchContext);
    if(!context) {
      throw new Error(`${displayName}DispatchContext must be used within a ${displayName}Provider.`)
    }
    return context;
  }

  return [StoreProvider, useStore, useDispatch];
}