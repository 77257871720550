import { useEffect, useState } from "react";
import { useRemoveFormErrorOnUnmount } from "../../hooks/remove-form-error-on-unmount";
import { RadioButton, RadioButtonLabel } from "../Button";
import { RequiredAsterisk } from ".";
import { FieldAlert, FieldAlerts } from "./FieldAlerts";

interface Props {
  id: string,
  label: string,
  formKey: string,
  value: string,
  onValueChange: (key: string, value: string) => void,
  required: boolean,
  disabled: boolean,
  options: [string, string][],
  onFormErrorsChange: (formError: {[key:string]: boolean}) => void
}

const RadioField: React.FC<Props> = ({id, label, formKey, value, onValueChange, required, disabled, options, onFormErrorsChange}) => {
  const [errors, setErrors] = useState<FieldAlert[]>([]);
  const [fieldAttempted, setFieldAttempted] = useState(false);
  useRemoveFormErrorOnUnmount(id, onFormErrorsChange);

  const updateValue = (value: string, checked: boolean) => {
    setFieldAttempted(true);
    if (checked) {
      onValueChange(formKey, value);
    }
  };

  useEffect(() => {
    const newErrors: FieldAlert[] = [];

    if (required && !value) {
      newErrors.push({id: id, message: `${label} is required`});
    }

    setErrors(newErrors);
    onFormErrorsChange({
      [id]: !!newErrors.length
    });
  }, [id, label, onFormErrorsChange, required, value]);

  useEffect(() => {
    /**
     * On unmount we want to remove any errors, we want this
     * to happen only on unmount so we have an empty dependency
     * list and ignore the react-hooks/exhaustive-deps warning
     */
    return () => {
      onFormErrorsChange({
        [id]: false
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={'form-group'}>
      <fieldset className={errors.length && fieldAttempted ? 'error-field' : ''}>
        <legend><RequiredAsterisk required={required} />{label}</legend>
        {options
          .map(([key, display]) =>
            <div key={`${id}-${key}`}>
              <RadioButtonLabel htmlFor={`${id}-${key}`}>
                <RadioButton
                    id={`${id}-${key}`}
                    type="radio"
                    checked={value === key}
                    name={id}
                    onChange={(event) => updateValue(key, event.target.checked)}
                    disabled={disabled}
                    onBlur={() => setFieldAttempted(true)}
                />
                {display}
              </RadioButtonLabel>
            </div>
        )}
      </fieldset>
      {!disabled ? (
        <FieldAlerts alerts={errors} showAlerts={fieldAttempted} />
      ) : null}
    </div>
  );
};

export { RadioField }