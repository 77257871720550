import styled from "styled-components";
import { QuestionCircle } from "./ActionIcon";

const ToolTipDiv = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 10px;
  outline: 0;

  :hover .tooltiptext {
    visibility: visible;
  }

  :focus .tooltiptext {
    visibility: visible;
  }

  :focus-visible, :focus {
    outline: solid 2px black;
  }
`;

const ToolTip = ({text}: {text: string}) => {
  return (
    <ToolTipDiv tabIndex={0}><span className="circle-span"><QuestionCircle /></span>
      <span className="tooltiptext">{text}</span>
    </ToolTipDiv>
  );
};

export { ToolTip };