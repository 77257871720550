import React from 'react';
import {useIntegrationDispatch, useIntegrationStore} from "../integration-store";
import { FormDataMappingMode as FormDataMappingModes } from '../../../hooks/use-templates/enums';
import { RadioField } from '../../CommonElements/RadioField';

const FormDataMappingMode = () => {
  const dispatch = useIntegrationDispatch();
  const {formDataMappingMode} = useIntegrationStore();

  const handleChange = (_key: string, value: string) => {
    dispatch({type: 'setFormDataMappingMode', payload: {
      formDataMappingMode: parseInt(value)
    }});
  }

  const modesAndLabels: [string, string][] = [
    [`${FormDataMappingModes.None}`, "None"],
    [`${FormDataMappingModes.ODS}`, "Employee Data"],
    [`${FormDataMappingModes.Custom}`, "Custom"]
  ];

  return (
      <RadioField
        id='integration-form-data-mapping-mode'
        label='Form Field Mapping Source'
        formKey=''
        value={`${formDataMappingMode}`}
        onValueChange={handleChange}
        required={true}
        onFormErrorsChange={() => {}}
        options={modesAndLabels}
        disabled={false}
      />
  );
}

export { FormDataMappingMode };