import { useState } from "react";
import { CopyIcon, SaveIcon } from "../ActionIcon";
import { ToolTipIconButton } from "../Button";

interface Props {
  content: string
  toolTip: string
}

const CopyContent: React.FC<Props> = ({content, toolTip}) => {
  const [contentCopied, setContentCopied] = useState(false);

  const copyUrl = () => {
    navigator.clipboard.writeText(content);
    setContentCopied(true);
    setTimeout(() => {
      setContentCopied(false);
    }, 1000);
  };

  return (
    <ToolTipIconButton
      toolTip={toolTip}
      disabled={contentCopied}
      onClick={copyUrl}
    >{contentCopied ? <SaveIcon /> : <CopyIcon />}</ToolTipIconButton>
  );
};

export { CopyContent };