import { useParams } from "react-router-dom";
import { useAuth } from "../../auth/controller";
import { CenterMessage } from "../../components/CommonElements";
import { PageTitle } from "../../components/Layout";
import { SenderForm } from "../../components/SenderForm/SenderForm";
import { useGetWorkflow } from "../../hooks/workflows/use-workflows";

const SsoSenderFormPage = () => {
  const {workflowId} = useParams();
  const {data: workflow, isLoading, isError, error} = useGetWorkflow(workflowId || '', true);
  const {userEmail} = useAuth();
  
  if (isError) {
    return <CenterMessage message={error!.message} />
  }
  else if (isLoading) {
    return <CenterMessage message="Loading workflow..." />
  }
  else if (!workflow || !userEmail) {
    return <CenterMessage message="An error occurred while loading the workflow.  Please make sure you have a valid sender form link." />
  }
  else {
    return (
      <main id="skip-to-content">
        <PageTitle>{workflow.adobeSignWorkflow.displayName}</PageTitle>
        <SenderForm workflowConfig={workflow} senderEmail={userEmail} />
      </main>
    );
  }
};

export { SsoSenderFormPage };