import React, {useEffect} from 'react';
import {Navigate, Outlet, useLocation, useSearchParams} from "react-router-dom";
import {useAuth} from "../controller";
import useErrorHandler from "../../hooks/use-error-handler";
import { AuthType } from '../client';
import { CenterMessage } from '../../components/CommonElements';

const RequireAuth = ({authType}: {authType: AuthType}) => {
  const auth = useAuth();
  const location = useLocation();
  const errorHandler = useErrorHandler();
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get('code');
  const redirectTo = searchParams.get('state');


  useEffect(() => {
    // Don't reset the auth type if there is an authCode
    // because service auth needs to redirect to admin home page
    // and we do not want to overwrite authCode from a generic auth page
    if (!authCode && auth.authType !== authType) {
      auth.signOut();
      auth.setAuthType(authType);
      return;
    }

    if (!auth.error) {
      if (!auth.userId) {
        auth.storeRedirect(location.pathname || '/');
        if (authCode) {
          auth.handleRedirectCallback(authCode);
        } else {
          if (!auth.checkSession()) {
            auth.redirectToSignIn();
          }
        }
      } else {
        auth.checkSession();
      }
    }
  }, [auth, authCode, authType, location.pathname])

  if (auth.error) {
    errorHandler.handleError(auth.error);
    return null;
  } else if (!auth.userId) {
    return (
      <CenterMessage message='Authenticating...' />
    )
  } else {
    if (redirectTo) {
      return <Navigate to={redirectTo} replace />;
    }

    return <Outlet />;
  }

}

export default RequireAuth;