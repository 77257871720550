import React from 'react';
import {useParams} from "react-router-dom";
import {useGetTemplate} from "../../hooks/use-templates";
import {IntegrationProvider} from "../../components/TemplateIntegration/integration-store";
import TemplateIntegration from "../../components/TemplateIntegration/TemplateIntegration";
import Breadcrumbs from "../../components/Breadcrumbs";
import { CenterMessage } from '../../components/CommonElements';
import { PageTitle } from '../../components/Layout';

const Edit = () => {
  const {templateId} = useParams();
  const template = useGetTemplate(templateId || '');

  return (
    <>
      <Breadcrumbs currentPageTitle={'Edit Integration'} parentPage="Templates" />
      <main id="skip-to-content">
        <PageTitle>
          Edit Template Integration
        </PageTitle>
        {template.status === 'loading' ? (
          <CenterMessage message='Loading template integration...' />
        ) : template.status === 'success' && template.data ? (
          <IntegrationProvider>
            <TemplateIntegration templateData={template.data} />
          </IntegrationProvider>
        ): (
          <div>Failed to load template integration</div>
        )}
      </main>
    </>
  );
}

export { Edit };