import { ParticipantSetConfig } from "asu-types";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ParticipantRole } from "../../../../hooks/use-templates/enums";
import { ToolTipButton } from "../../../Button";
import { SectionHeader } from "../../../CommonElements";
import { useParticipantSetDispatch, useParticipantSetStore } from "./participant-set-store";

const ParticipantSetDiv = styled.div`
  border: 1px solid black;
  padding: 10px;
`;

const ParticipantActionButtons = styled.div`
  text-align: center;
`;

interface ParticipantSetComponentInterface {
  objectIndex: number|'new';
  objectData: ParticipantSetConfig;
  saveObject(index: number|'new', objectData: ParticipantSetConfig): void;
  cancelEdit(): void;
  participantNumber: number;
}

const ParticipantSet: React.FC<ParticipantSetComponentInterface> = ({
  objectIndex,
  objectData,
  cancelEdit,
  saveObject,
  participantNumber
}) => {
  const participantSetDispatch = useParticipantSetDispatch();
  const {role, label, memberInfos, includeAuthenticatedUser, order}: ParticipantSetConfig = useParticipantSetStore();
  const [errorMessage, setErrorMessage] = useState<null|string>(null);

  useEffect(() => {
    participantSetDispatch({
      type: 'hydrateParticipantSet',
      payload: {
        role: objectData.role,
        label: objectData.label,
        memberInfos: objectData.memberInfos,
        includeAuthenticatedUser: objectData.includeAuthenticatedUser,
        order: objectData.order
      }
    });
  }, [participantSetDispatch, objectData]);

  const saveEdits = () => {
    if (!order || order < 1) {
      setErrorMessage('Order must be greater than or equal to 1');
    }
    else if (!memberInfos.length) {
      setErrorMessage('At least one participant email is required');
    }
    else {
      setErrorMessage(null);
      saveObject(objectIndex, {
        role,
        label,
        memberInfos,
        includeAuthenticatedUser,
        order
      });
    }
  }

  const cancelEdits = () => {
    cancelEdit();
    setErrorMessage(null);
  }

  const memberEmails = memberInfos.map(x => x.email).join(',');

  const setMemberEmails = (value: string) => {
    participantSetDispatch({type: 'setMemberInfos', payload: {
      memberInfos: value.split(',').map(email => {
        return {
          email
        }
      })
    }});
  };

  return (
    <ParticipantSetDiv>
      <SectionHeader>Participant {participantNumber}</SectionHeader>
      <div>
        <div className={'form-group'}>
          <label htmlFor={'participant-order'}>Order: </label>
          <input
            autoFocus
            id={"participant-order"}
            className={'textInput'}
            inputMode={'numeric'}
            value={order}
            onChange={(event) => participantSetDispatch({type: 'setOrder', payload: {order: event.target.value}})}
          />
        </div>
        <div className={'form-group'}>
          <label htmlFor={'participant-role'}>Role: </label>
          <select
            id={"participant-role"}
            className={'textInput'}
            value={role}
            onChange={(event) => participantSetDispatch({type: 'setRole', payload: {role: event.target.value}})}
          >
            {Object.keys(ParticipantRole).map((option: string) => {
              return <option key={option} value={option}>{option}</option>
            })};
          </select>
        </div>
        <div className={'form-group'}>
          <label htmlFor={'participant-email'}>Participant Email: </label>
          <input
            id={"participant-email"}
            className={'textInput'}
            inputMode={'text'}
            value={memberEmails}
            onChange={(event) => setMemberEmails(event.target.value)}
          />
        </div>
      </div>
      {errorMessage ? <div className={'error-info'}>{errorMessage}</div> : null}
      <ParticipantActionButtons>
        <ToolTipButton
          onClick={saveEdits}
          toolTip={`Confirm participant set edits`}
        >Confirm Edits</ToolTipButton>
        <ToolTipButton
          variant="secondary"
          onClick={cancelEdits}
          toolTip={`Cancel editing participant set`}
        >Cancel Edits</ToolTipButton>
      </ParticipantActionButtons>
    </ParticipantSetDiv>
  );
}

export { ParticipantSet }