import {useLocation, useNavigate} from "react-router-dom";

const useErrorHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handle401 = () => {
    navigate('/login', {state: {lastPage: location.pathname}});
  };

  const handleError = (error: Error) => {
    const parsedError = JSON.parse(JSON.stringify(error));
    switch (parsedError.status) {
      case 403:
        navigate('/unauthorized');
        break;
      case 401:
        handle401();
        break;
      default:
        break;
    }

    if (parsedError.message?.toLowerCase().includes('network error')) {
      navigate('/ad-block', {state: {lastPage: location.pathname}});
    }

    if (parsedError.message?.toLowerCase().includes('401')) {
      handle401();
    }

    return error;
  }

  return {
    handleError
  }
}

export default useErrorHandler;