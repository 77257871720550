import makeStore from "../../utils/make-store";
import { FormDataMappingMode } from "../../hooks/use-templates/enums";
import { Template } from "asu-types";

interface Action {
  type: 'hydrateIntegrationData'
      | 'setIntegrationName'
      | 'setFormDataMappingMode'
      | 'setFormDataMappings'
      | 'setConfig'
      | 'setIntegrationQueues'
      | 'setS3Dir'
      | 'setFormError'
      | 'setAvailabilityStart'
      | 'setAvailabilityEnd'
  payload: Template,
  errorField: {[key: string]: boolean};
}

interface FormStateInterface extends Template {
  formError: {[key:string]: boolean};
  hydrated: boolean;
}

const initialState: FormStateInterface = {
  ...(new Template()),
  formError: {},
  hydrated: false
};

const integrationReducer = (state: FormStateInterface, action: Action): FormStateInterface => {
  switch(action.type) {
    case 'hydrateIntegrationData':
      return {
        ...action.payload,
        formError: {},
        hydrated: true
      }
    case 'setIntegrationName':
      return {
        ...state,
        name: action.payload.name
      }
    case 'setFormDataMappingMode':
      return {
        ...state,
        formDataMappingMode: action.payload.formDataMappingMode,
        formDataMappings: action.payload.formDataMappingMode === FormDataMappingMode.Custom
          ? state.formDataMappings
          : []
      }
    case 'setFormDataMappings':
      return {
        ...state,
        formDataMappings: action.payload.formDataMappings
      }
    case 'setConfig':
      return {
        ...state,
        config: action.payload.config
      }
    case 'setIntegrationQueues':
      return {
        ...state,
        integrationQueues: action.payload.integrationQueues
      }
    case 'setS3Dir':
      return {
        ...state,
        s3Dir: action.payload.s3Dir
      }
    case 'setAvailabilityStart':
      return {
        ...state,
        availabilityStart: action.payload.availabilityStart
      }
    case 'setAvailabilityEnd':
      return {
        ...state,
        availabilityEnd: action.payload.availabilityEnd
      }
    case 'setFormError':
      const field = Object.keys(action.errorField)[0];
      let updatedErrors = {...state.formError};
      if (!action.errorField[field]) {
        delete updatedErrors[field];
      } else {
        updatedErrors[field] = true;
      }

      return {
        ...state,
        formError: updatedErrors
      }
    default:
      console.log(`Unhandled reducer action: ${action.type}`);
      return state;
  }
}

const [
  IntegrationProvider,
  useIntegrationStore,
  useIntegrationDispatch
] = makeStore(integrationReducer, initialState, 'TemplateIntegration');

export { IntegrationProvider, useIntegrationStore, useIntegrationDispatch };