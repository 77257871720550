import React, { useEffect, useState } from "react";
import { UseMutationResult } from "react-query";
import styled from "styled-components";
import { Button, ToolTipButton } from "../Button";
import { Integration } from "./ListRow";

const DeletePromptContainer = styled.div`
  display: flex; 
  justify-content: space-between;
  align-items: center; 
  width: 100%;
  gap: 5%;
`;

enum PromptType {
  None,
  Delete,
  Disable
}

interface Props {
  integration: Integration;
  promptForDelete(arg0: null|string): void;
  useDeleteIntegration(id: string): UseMutationResult<void, Error, void>;
  useDisableIntegration(id: string): UseMutationResult<void, Error, void>;
}

const DeleteListRow: React.FC<Props> = ({integration, promptForDelete, useDeleteIntegration, useDisableIntegration}) => {
  const [promptType, setPromptType] = useState(PromptType.None);
  const {
    mutate: deleteMutate,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    isLoading: isDeleteLoading,
    reset: resetDelete
  } = useDeleteIntegration(integration.id);
  const {
    mutate: disableMutate,
    isSuccess: isDisableSuccess,
    isError: isDisableError,
    isLoading: isDisableLoading,
    reset: resetDisable
  } = useDisableIntegration(integration.id);
  const promptTypeText = promptType === PromptType.Delete ? 'Delete' : 'Disable';

  const deleteIntegration = async () => {
    if (promptType === PromptType.Delete) {
      deleteMutate();
    }
    else {
      disableMutate();
    }
  };

  const reset = () => {
    resetDelete();
    resetDisable();
  };

  const promptTypeSelected = (promptType: PromptType) => {
    setPromptType(promptType);
    setTimeout(() => {
      document.getElementById(`confirm-prompt-button`)?.focus();
    }, 100);
  };

  useEffect(() => {
    if (isDeleteSuccess || isDisableSuccess) {
      setTimeout(() => {
        promptForDelete(null)
      }, 1000);
    }
  }, [isDeleteSuccess, isDisableSuccess, promptForDelete]);

  return (
    <tr key={`delete-${integration.id}`}>
      <td colSpan={4} style={{background: '#f7dddd', borderLeft: 'none'}}>
        <DeletePromptContainer>
          {promptType === PromptType.None ? (
            <div style={{width: "60%"}}>
              <b>Delete or disable {integration.name}?</b>
            </div>
          ) : (
            <div style={{width: "60%"}}>
              <b>Confirm {promptTypeText} {integration.name}?</b>
            </div>
          )}
          {isDeleteLoading || isDisableLoading ? (
            <div style={{width: "50%"}}>
              {promptTypeText} in progress...
            </div>
          ) : isDeleteSuccess || isDisableSuccess ? (
            <div style={{width: "50%"}}>
              {promptTypeText} successful
            </div>
          ) : isDeleteError || isDisableError ? (
            <>
              <div style={{width: "30%"}}>
                {promptTypeText} failed
              </div>
              <div style={{width: "20%"}}>
                <Button variant={'secondary'} aria-label={'Cancel'} onClick={reset}>Retry</Button>
              </div>
            </>
          ) : (
            <>
              {promptType === PromptType.None ? (
                <>
                  <div>
                    <ToolTipButton
                      autoFocus
                      variant="secondary"
                      toolTip={`Disable ${integration.name}`}
                      onClick={() => promptTypeSelected(PromptType.Disable)}
                    >Disable</ToolTipButton>
                  </div>
                  <div>
                    <ToolTipButton
                      toolTip={`Delete ${integration.name}`}
                      onClick={() => promptTypeSelected(PromptType.Delete)}
                    >Delete</ToolTipButton>
                  </div>
                  <div>
                    <ToolTipButton
                      variant="secondary"
                      toolTip={`Cancel deleting or disabling ${integration.name}`}
                      onClick={() => promptForDelete(null)}
                    >Cancel</ToolTipButton>
                  </div>
                </>
              ) : (
                <>
                  <div style={{width: "30%"}}>
                    <ToolTipButton
                      id="confirm-prompt-button"
                      toolTip={`${promptTypeText} ${integration.name}`}
                      onClick={deleteIntegration}
                    >Confirm {promptTypeText}</ToolTipButton>
                  </div>
                  <div style={{width: "20%"}}>
                    <ToolTipButton
                      variant={'secondary'}
                      toolTip={`Cancel ${promptTypeText} ${integration.name}`}
                      onClick={() => promptForDelete(null)}
                    >Cancel</ToolTipButton>
                  </div>
                </>
              )}
            </>
          )}
        </DeletePromptContainer>
      </td>
    </tr>
  );
};

export { DeleteListRow };