import React from 'react';
import {useIntegrationDispatch, useIntegrationStore} from "../integration-store";

const S3Dir = () => {
  const dispatch = useIntegrationDispatch();
  const {s3Dir} = useIntegrationStore();

  const handleDirChange = (value: string) => {
    dispatch({type: 'setS3Dir', payload: {
      s3Dir: value
    }});
  }

  return (
      <div className={'form-group'}>
        <label htmlFor={'integration-s3-dir-field'}>Archive S3 Bucket Path: </label>
        <input
            id={"integration-s3-dir-field"}
            inputMode={'text'}
            value={s3Dir}
            onChange={(event) => handleDirChange(event.target.value)}
        />
      </div>
  );
}

export { S3Dir };