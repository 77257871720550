import { ComparatorType } from "asu-types";

const comparatorDisplay: {[key: string]: string} = {
  [ComparatorType.StringEqualTo]: "EqualTo",
  [ComparatorType.StringNotEqualTo]: "NotEqualTo",
  [ComparatorType.NumberEqualTo]: "EqualTo",
  [ComparatorType.NumberGreaterThan]: "GreaterThan",
  [ComparatorType.NumberGreaterThanOrEqualTo]: "GreaterThanOrEqualTo",
  [ComparatorType.NumberLessThan]: "LessThan",
  [ComparatorType.NumberLessThanOrEqualTo]: "LessThanOrEqualTo",
  [ComparatorType.NumberNotEqualTo]: "NotEqualTo"
};

export const comparatorDisplayValue = (type: ComparatorType): string => {
  return comparatorDisplay[type] || type;
};