export enum ComparatorType {
  IsEmpty = "IsEmpty",
  IsNotEmpty = "IsNotEmpty",
  StringEqualTo = "StringEqualTo",
  StringNotEqualTo = "StringNotEqualTo",
  NumberEqualTo = "NumberEqualTo",
  NumberNotEqualTo = "NumberNotEqualTo",
  NumberGreaterThan = "NumberGreaterThan",
  NumberGreaterThanOrEqualTo = "NumberGreaterThanOrEqualTo",
  NumberLessThan = "NumberLessThan",
  NumberLessThanOrEqualTo = "NumberLessThanOrEqualTo",
  IsChecked = "IsChecked",
  IsUnchecked = "IsUnchecked"
}