import React, {useCallback, useEffect} from "react";
import {useIntegrationDispatch, useIntegrationStore} from "./integration-store";
import {
  IntegrationName, AdobeSignId, FormDataMappings, Owners,
  PostSignRedirect, TemplateWorkflow, IntegrationQueues, S3Dir, FormDataMappingMode, SenderEmail
} from "./components";
import {useUpdateTemplate} from "../../hooks/use-templates";
import { Navigate} from "react-router-dom";
import { FormDataMappingMode as FormDataMappingModes } from "../../hooks/use-templates/enums";
import { Participants } from "./components/ParticipantSets/ParticipantSets";
import { DisableEmails } from "./components/DisableEmails";
import { CenterMessage, IntegrationForm } from "../CommonElements";
import { IntegrationSaveCancel } from "../CommonElements/IntegrationSaveCancel";
import { Template } from "asu-types";
import { IntegrationAvailabilityRange } from "../CommonElements/IntegrationAvailabilityRange";

interface Props {
  templateData: Template;
  isNew?: boolean;
}

const TemplateIntegration: React.FC<Props> = ({templateData, isNew}) => {
  const dispatch = useIntegrationDispatch();
  const data = useIntegrationStore();
  const updateTemplate = useUpdateTemplate(templateData.adobeSignId || '');
  const hasErrors = Object.keys(data.formError).length > 0;

  const saveTemplate = async () => {
    if (!hasErrors) {
      updateTemplate.mutate(data);
    }
  }

  const onFormErrorsChange = useCallback((formError: {[key:string]: boolean}) => {
    dispatch({type: 'setFormError', errorField: formError});
  }, [dispatch]);

  const setStartDate = useCallback((value: number|null) => {
    dispatch({type: 'setAvailabilityStart', payload: {availabilityStart: value}});
  }, [dispatch]);

  const setEndDate = useCallback((value: number|null) => {
    dispatch({type: 'setAvailabilityEnd', payload: {availabilityEnd: value}});
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: 'hydrateIntegrationData',
      payload: templateData
    });
  }, [dispatch, templateData]);

  if (!data.hydrated) {
    return <CenterMessage message="Loading..."></CenterMessage>
  }

  return (
    <IntegrationForm>
      <IntegrationName/>
      <AdobeSignId/>
      <IntegrationAvailabilityRange
        startDate={data.availabilityStart}
        setStartDate={setStartDate}
        endDate={data.availabilityEnd}
        setEndDate={setEndDate}
        onFormErrorsChange={onFormErrorsChange}
      />
      <SenderEmail/>
      <Owners/>
      <FormDataMappingMode/>
      {data.formDataMappingMode === FormDataMappingModes.Custom ? <FormDataMappings/> : null}
      <PostSignRedirect/>
      <TemplateWorkflow />
      <Participants/>
      <S3Dir/>
      <IntegrationQueues />
      <DisableEmails />
      <div>
        <IntegrationSaveCancel
          onSaveClicked={saveTemplate}
          cancelLink='/templates'
          saveTooltip={hasErrors ? "Please complete all required fields" : "Save workflow changes"}
          cancelTooltip='Cancel editing workflow and revert changes'
          disableSave={hasErrors || updateTemplate.status === 'loading'}
          saveText={updateTemplate.status === 'loading' ? 'Saving...' : 'Save'}
        />
        <div style={{paddingTop: 10}}>
          {updateTemplate.status === 'success' ? (
            <Navigate to={'/templates'} state={{ alert: {message: 'Integration saved successfully', type: 'success' }}} replace={isNew} />
          ) : updateTemplate.status === 'error' ? (
            <div className={'error-info'}>Failed to {isNew ? 'create' : 'update'} template: {updateTemplate.error.message}</div>
          ) : null}
        </div>
      </div>
    </IntegrationForm>
  );
}

export default TemplateIntegration;