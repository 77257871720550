import { FormFieldValidation } from "adobe-sign-types";
import { WorkflowFullConfig } from "asu-types"
import { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSessionStorageState } from "../../auth/utils/session-storage";
import { SessionStorageKey } from "../../enums/SessionStorageKey";
import { useSaveWorkflow } from "../../hooks/workflows/use-workflows";
import { CenterMessage, IntegrationForm, SectionHeader } from "../CommonElements";
import { IntegrationAvailabilityRange } from "../CommonElements/IntegrationAvailabilityRange";
import { IntegrationSaveCancel } from "../CommonElements/IntegrationSaveCancel";
import { CheckboxField } from "../CommonElements/CheckboxField";
import { TextField } from "../CommonElements/TextField";
import { ConditionalRoutingConfig } from "./ConditionalRoutingConfig";
import { SenderFieldsConfig } from "./SenderFieldsConfig";
import { useWorkflowStore } from "./workflow-store";

interface Props {
  workflow: WorkflowFullConfig
}

const WorkflowIntegration: React.FC<Props> = ({workflow: initialWorkflow}) => {
  const [hasErrors, setHasErrors] = useState(false);
  const {
    mutate: saveWorkflowMutate,
    isLoading: workflowSaving,
    isSuccess: workflowSaveSuccessful,
    isError: workflowSaveErrored,
    error: workflowSaveError
  } = useSaveWorkflow(initialWorkflow.id);
  const [formErrors, setFormErrors] = useState<{[key: string]: boolean}>({});
  const [recipientConfigValid, setRecipientConfigValid] = useState(true);
  const [editingRecipient, setEditingRecipient] = useState(false);
  const [pendingWorkflowSavePayload, setPendingWorkflowSavePayload] =
    useSessionStorageState<WorkflowFullConfig | null>(SessionStorageKey.PendingWorkflowSavePayload, null);
  const {
    workflow,
    setRequireSSO,
    setRedirectSenderToSignerUrl,
    setHideRecipients,
    setSendOnBehalfOfSsoUser,
    setSendOnBehalfOf,
    setSenderFields,
    setRecipientConfigs,
    setIncludeODSData,
    setPostSignRedirect,
    setAvailabilityStart,
    setAvailabilityEnd
  } = useWorkflowStore(initialWorkflow);

  const setFormError = useCallback(() => (formError: {[key:string]: boolean}) => {
    setFormErrors(previousValue => ({
      ...previousValue,
      ...formError
    }));
  }, []);

  const updateSendOnBehalfOf = (_key: string, sendOnBehalfOf: string) => {
    setSendOnBehalfOf(sendOnBehalfOf);
  };

  const updatePostSignRedirect = (_key: string, postSignRedirect: string) => {
    setPostSignRedirect(postSignRedirect);
  };

  const saveWorkflow = () => {
    saveWorkflowMutate(workflow);
  };

  useEffect(() => {
    if (Object.keys(formErrors).find(key => formErrors[key]) || !recipientConfigValid) {
      setHasErrors(true);
      return;
    }

    setHasErrors(false);
  }, [formErrors, recipientConfigValid]);

  useEffect(() => {
    if (pendingWorkflowSavePayload) {
      const payload = pendingWorkflowSavePayload;
      setPendingWorkflowSavePayload(null);
      saveWorkflowMutate(payload);
    }
  }, [pendingWorkflowSavePayload, saveWorkflowMutate, setPendingWorkflowSavePayload]);

  if (workflowSaving || pendingWorkflowSavePayload) {
    return <CenterMessage message="Saving Workflow..." />;
  }
  else if (workflowSaveErrored) {
    return <CenterMessage message={workflowSaveError!.message} />
  }
  else if (workflowSaveSuccessful) {
    return <Navigate to={'/workflows'} state={{ alert: {message: 'Workflow saved successfully', type: 'success' }}} replace={true} />;
  }

  return (
    <IntegrationForm>
      <SectionHeader>General Configuration</SectionHeader>
      <div className={'form-group'}>
        <label htmlFor={'workflow-name'}>Workflow Name: </label>
        <input
          id={"workflow-name"}
          inputMode={'text'}
          value={workflow.adobeSignWorkflow.displayName}
          disabled={true}
        />
      </div>
      <IntegrationAvailabilityRange
        startDate={workflow.availabilityStart}
        setStartDate={setAvailabilityStart}
        endDate={workflow.availabilityEnd}
        setEndDate={setAvailabilityEnd}
        onFormErrorsChange={setFormError}
      />
      <TextField
        id={'workflow-sendOnBehalfOf'}
        label={'Send on Behalf of'}
        formKey={''}
        value={workflow.sendOnBehalfOf || ''}
        onValueChange={updateSendOnBehalfOf}
        required={false}
        validationType={FormFieldValidation.Email}
        onFormErrorsChange={setFormError}
        requireAsuriteEmail={true}
        disabled={workflow.sendOnBehalfOfSsoUser}
        toolTip={`This email will be used as the owner of the created agreements and those agreements will show up in their Adobe Sign account.${!workflow.sendOnBehalfOfSsoUser ? '' : ' Send on Behalf of Initiator must be disabled before you can enter this email.'}`}
      />
      <TextField
        id={'workflow-postSignRedirect'}
        label={'Post-Sign Redirect URL'}
        formKey={''}
        value={workflow.postSignRedirect || ''}
        onValueChange={updatePostSignRedirect}
        required={false}
        validationType={FormFieldValidation.None}
        onFormErrorsChange={setFormError}
        disabled={false}
        toolTip='Signer will be redirected to this url once they have finished signing the agreement in Adobe Sign.'
      />
      <SectionHeader>Initiator Options</SectionHeader>
      <CheckboxField
        id="workflow-requireSSO"
        label="Require SSO"
        value={workflow.requireSSO}
        onValueChange={(_key, value) => setRequireSSO(value)}
        onFormErrorsChange={setFormError}
        checkedValue={true}
        uncheckedValue={false}
        toolTip="If box is checked, the Initiator must log in with their ASURITE ID to view this form. Remember, if not checked anyone with the link can view and initiate this workflow."
      />
      <CheckboxField
        id="workflow-includeODSData"
        label="Prefill Template Fields with Initiator Data"
        value={workflow.includeODSData}
        onValueChange={(_key, value) => setIncludeODSData(value)}
        onFormErrorsChange={setFormError}
        checkedValue={true}
        uncheckedValue={false}
        disabled={!workflow.requireSSO}
        toolTip={`If box is checked, the Initiator's information will be used to prefill the workflow's template fields.${workflow.requireSSO ? '' : ' Require SSO must be enabled before you can enable this option.'}`}
      />
      <CheckboxField
        id="workflow-redirectSenderToSignerUrl"
        label="Initiator is First Participant"
        value={workflow.redirectSenderToSignerUrl}
        onValueChange={(_key, value) => setRedirectSenderToSignerUrl(value)}
        onFormErrorsChange={setFormError}
        checkedValue={true}
        uncheckedValue={false}
        toolTip="If box is checked, the Initiator will be automatically redirected to the agreement to sign as the first participant after submitting the workflow."
      />
      <CheckboxField
        id="workflow-hideRecipients"
        label="Hide Non-Editable Recipients"
        value={workflow.hideRecipients}
        onValueChange={(_key, value) => setHideRecipients(value)}
        onFormErrorsChange={setFormError}
        checkedValue={true}
        uncheckedValue={false}
        toolTip="If box is checked, non-editable recipients will not be visible to the Initiator on the initiator form."
      />
      <SectionHeader>Advanced Options</SectionHeader>
      <CheckboxField
        id="workflow-sendOnBehalfOfSsoUser"
        label="Send on Behalf of Initiator"
        value={workflow.sendOnBehalfOfSsoUser}
        onValueChange={(_key, value) => setSendOnBehalfOfSsoUser(value)}
        onFormErrorsChange={setFormError}
        checkedValue={true}
        uncheckedValue={false}
        disabled={!workflow.requireSSO}
        toolTip={`!!NOTE: If you enable this, all Initiators must have an Adobe Sign account and their account must be in the group that owns this workflow. If box is checked, the Initiator's email will be used as the owner of the created agreements and those agreements will show up in their Adobe Sign account.${workflow.requireSSO ? '' : ' Require SSO must be enabled before you can enable this option.'}`}
      />
      <SenderFieldsConfig
        formFields={workflow.formFields}
        senderFields={workflow.senderFields}
        onSenderFieldsChange={setSenderFields}
        workflowConfig={workflow}
      />
      <ConditionalRoutingConfig
        recipients={workflow.adobeSignWorkflow.recipientsListInfo}
        recipientConfigs={workflow.recipientConfigs || []}
        onRecipientConfigsChanged={setRecipientConfigs}
        formFields={workflow.formFields}
        recipientConfigValid={recipientConfigValid}
        onRecipientConfigValidChanged={setRecipientConfigValid}
        onEditingRecipientChanged={setEditingRecipient}
        senderFields={workflow.senderFields}
      />
      {!editingRecipient ? (
        <>
          <hr />
          <IntegrationSaveCancel
            onSaveClicked={saveWorkflow}
            cancelLink='/workflows'
            saveTooltip={hasErrors ? "Please complete all required fields" : "Save workflow changes"}
            cancelTooltip='Cancel editing workflow and revert changes'
            disableSave={hasErrors}
          />
        </>
      ) : null}
      
    </IntegrationForm>
  )
};

export { WorkflowIntegration }