import { Agreement, AgreementStatus, Webhook as IWebhook, WebhookEvent } from 'adobe-sign-types';
export class Webhook implements IWebhook {
  event: WebhookEvent;
  agreement: Agreement;
  dedupeId: number;

  constructor (data?: Partial<Webhook>) {
    this.event = data?.event || WebhookEvent.AgreementCreated;
    this.agreement = data?.agreement || {
      id: '',
      name: '',
      groupId: '',
      type: '',
      participantSetsInfo: [],
      senderEmail: '',
      createdDate: '',
      lastEventDate: '',
      signatureType: '',
      status: AgreementStatus.OutForSignature
    };
    this.dedupeId = data?.dedupeId || 0;
  }
}