export enum ParticipantStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  NOT_YET_VISIBLE = 'NOT_YET_VISIBLE',
  WAITING_FOR_NOTARIZATION = 'WAITING_FOR_NOTARIZATION',
  WAITING_FOR_OTHERS = 'WAITING_FOR_OTHERS',
  WAITING_FOR_MY_APPROVAL = 'WAITING_FOR_MY_APPROVAL',
  WAITING_FOR_AUTHORING = 'WAITING_FOR_AUTHORING',
  WAITING_FOR_MY_ACKNOWLEDGEMENT = 'WAITING_FOR_MY_ACKNOWLEDGEMENT',
  WAITING_FOR_MY_ACCEPTANCE = 'WAITING_FOR_MY_ACCEPTANCE',
  WAITING_FOR_MY_FORM_FILLING = 'WAITING_FOR_MY_FORM_FILLING',
  WAITING_FOR_MY_DELEGATION = 'WAITING_FOR_MY_DELEGATION',
  WAITING_FOR_MY_SIGNATURE = 'WAITING_FOR_MY_SIGNATURE',
  WAITING_FOR_MY_VERIFICATION = 'WAITING_FOR_MY_VERIFICATION',
  WAITING_FOR_PREFILL = 'WAITING_FOR_PREFILL'
}