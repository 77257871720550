import {createContext, useCallback, useContext, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Constants } from "../enums/Constants";
import { SessionStorageKey } from "../enums/SessionStorageKey";
import {AuthClientService, AuthType} from "./client";
import { useSessionStorageState } from "./utils/session-storage";

export interface AuthContextInterface {
  userId: string | null;
  userEmail: string | null;
  redirectToSignIn(): void;
  handleRedirectCallback(authCode: string): void;
  checkSession(): Promise<boolean>;
  storeRedirect(redirectTo: string): void;
  getToken(): Promise<null|string>;
  signOut(): void;
  error?: Error;
  authType: AuthType | null;
  setAuthType(authType: AuthType): void;
}

const AuthContext = createContext<AuthContextInterface>({
  userId: null,
  userEmail: null,
  redirectToSignIn: ()=>{},
  handleRedirectCallback: ()=>{},
  checkSession: async()=>false,
  storeRedirect: ()=>{},
  getToken: async()=>null,
  signOut: ()=>{},
  error: undefined,
  authType: null,
  setAuthType: ()=>undefined
});

export const AuthProvider = ({children}: any) => {
  const auth = useProvideAuth();
  return (
      <AuthContext.Provider value={auth}>
        {children}
      </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext);
}

const useProvideAuth = () => {
  const [client] = useState(() => new AuthClientService())
  const [userId, setUserId] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [error, setError] = useState<any>(undefined);
  const [authType, setAuthType] = useSessionStorageState<AuthType | null> (SessionStorageKey.AuthType, null);
  const navigate = useNavigate();
  const location = useLocation();

  const redirectToSignIn = useCallback( async () => {
    await client.loginWithRedirect();
  }, [client]);

  const setUser = (user: string | null) => {
    if (!user) {
      setUserId(null);
      setUserEmail(null);
      return;
    }

    const parts = user.split(Constants.AsuDomain);

    setUserId(parts[0]);
    setUserEmail(`${parts[0]}${Constants.AsuDomain}`);
  };

  const handleRedirectCallback = useCallback(async (authCode: string) => {
    try {
      if (!authType) {
        throw new Error('authType is required to call handleRedirectCallback');
      }
      await client.handleRedirectCallback(authCode, authType);
      let user = await client.getUser();
      setUser(user);
    } catch (err) {
      setError(err);
    }
  }, [authType, client]);

  const checkSession = async () => {
    if(await client.checkSession()) {
      let user = await client.getUser();
      setUser(user);
      return true;
    } else {
      setUser(null);
      return false;
    }
  }

  const storeRedirect = async (returnTo: string) => await client.storeRedirect(returnTo);

  const getToken = async () => {
    let token;

    try {
      token = await client.getToken(authType);
    } catch (error) {
      console.log(`Error while fetching token:`, error);
    }

    if (!token) {
      navigate('/login', {state: {lastPage: location.pathname}});
      return null;
    }

    return token;
  };

  const signOut = async () => {
    await client.signOut();
    setUser(null);
  }

  return {
    userId,
    userEmail,
    redirectToSignIn,
    handleRedirectCallback,
    checkSession,
    storeRedirect,
    getToken,
    signOut,
    error,
    authType,
    setAuthType
  }
}