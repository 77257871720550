import { FormFieldValidation, PhoneFormat, ZipFormat } from "adobe-sign-types";
import { useEffect, useState } from "react";
import { Constants } from "../../enums/Constants";
import { useRemoveFormErrorOnUnmount } from "../../hooks/remove-form-error-on-unmount";
import { adobeDateFormatFactory } from "../../utils/date-format-factory";
import { RequiredAsterisk } from ".";
import { ToolTip } from "../ToolTip";
import { FieldAlert, FieldAlerts, FieldAlertsRole } from "./FieldAlerts";

interface Props {
  id: string,
  label: string,
  formKey?: string,
  value: string,
  onValueChange: (key: string, value: string) => void,
  required?: boolean,
  validationType?: FormFieldValidation,
  disabled?: boolean,
  preferAsuriteEmail?: boolean
  requireAsuriteEmail?: boolean
  onFormErrorsChange?: (formError: {[key:string]: boolean}) => void
  toolTip?: string,
  minLength?: number,
  maxLength?: number,
  minValue?: number,
  maxValue?: number,
  numberOfRows?: number,
  autoFocus?: boolean,
  validationData?: string,
  onValidValueChange?: (key: string, value: string) => void,
}

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const numberRegex = /^-?[0-9]+(\.[0-9]*)?$/;
const currencyRegex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
const usZipRegex = /^\d{5}$/;
const usZipPlus4Regex = /^\d{5}-\d{4}$/;
const ukZip = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s[0-9][A-Za-z]{2})$/;
const ukPhone = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/;
const usPhone = /^\d{3}-\d{3}-\d{4}$/;
const usSsn = /^\d{3}-\d{2}-\d{4}$/;

const TextField: React.FC<Props> = ({
  id,
  label,
  formKey = '',
  value,
  onValueChange,
  required = false,
  validationType = FormFieldValidation.None,
  disabled = false,
  onFormErrorsChange,
  preferAsuriteEmail = false,
  requireAsuriteEmail = false,
  toolTip,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numberOfRows = 1,
  autoFocus = false,
  validationData = '',
  onValidValueChange
}) => {
  const [errors, setErrors] = useState<FieldAlert[]>([]);
  const [warnings, setWarnings] = useState<FieldAlert[]>([]);
  const [fieldAttempted, setFieldAttempted] = useState(false);
  const isNumber = [FormFieldValidation.Currency, FormFieldValidation.Number].includes(validationType);
  const {getParsableDate, getFormattedDate, formatDisplay} = adobeDateFormatFactory([FormFieldValidation.Date, FormFieldValidation.Time].includes(validationType) ? validationData : '');
  useRemoveFormErrorOnUnmount(id, onFormErrorsChange);

  if (!maxLength || maxLength > 16000 || maxLength === -1) {
    maxLength = 16000;
  }

  const updateValue = (value: string) => {
    setFieldAttempted(true);
    onValueChange(formKey, value);
  };

  useEffect(() => {
    const newErrors: FieldAlert[] = [];
    const newWarnings: FieldAlert[] = [];
    const numberValue = parseFloat(value.replace(',', ''));

    if (required && !value) {
      newErrors.push({id: id, message: `${label} is required`});
    }

    if (preferAsuriteEmail) {
      newWarnings.push({id: id, message: `If external, enter their contact email. If ASU affiliate, enter ASURITE@asu.edu.`});
    }

    if (value) {
      if (validationType === FormFieldValidation.Email) {
        if (!emailRegex.test(value)) {
          newErrors.push({id: id, message: `${label} can only contain a valid email address. e.g jhams@asu.edu`});
        }
        else if (!value.includes(Constants.AsuDomain) || value.split(Constants.AsuDomain)[0].includes('.')) {
          if (requireAsuriteEmail) {
            newErrors.push({id: id, message: `${label} email must be associated with an Adobe Sign account. Please enter an ASURITE email address. ([ASURITE ID]@asu.edu)`});
          }
        }
      }
      else if (validationType === FormFieldValidation.Number) {
        if (!numberRegex.test(value)) {
          newErrors.push({id: id, message: `${label} can only contain numbers. e.g. 1004, 1.04, -1`});
        }
      }
      else if (validationType === FormFieldValidation.Currency) {
        if (!currencyRegex.test(value)) {
          newErrors.push({id: id, message: `${label} can only contain a currency value. e.g. 1,009.50`});
        }
      }
      else if (validationType === FormFieldValidation.Date || validationType === FormFieldValidation.Time) {
        const formatExample = getFormattedDate(new Date());

        const pushDateError = () => {
          newErrors.push({id: id, message: `${label} must be in the following format: ${formatDisplay}, e.g. ${formatExample}`});
        };

        try {
          const dateObj = new Date(getParsableDate(value));
          const valueFormatted = getFormattedDate(dateObj);
          if (!(dateObj instanceof Date) || value !== valueFormatted) {
            pushDateError();
          }
        } catch (error) {
          pushDateError();
        }
      }
      else if (validationType === FormFieldValidation.Zip && validationData === ZipFormat.US) {
        if (!usZipRegex.test(value)) {
          newErrors.push({id: id, message: `${label} should be a valid five digit US zip code. e.g. 85281`});
        }
      }
      else if (validationType === FormFieldValidation.Zip && validationData === ZipFormat.US4) {
        if (!usZipPlus4Regex.test(value)) {
          newErrors.push({id: id, message: `${label} should be a valid nine digit US zip code. e.g. 85281-4111`});
        }
      }
      else if (validationType === FormFieldValidation.Zip && validationData === ZipFormat.GB) {
        if (!ukZip.test(value)) {
          newErrors.push({id: id, message: `${label} should be a valid nine digit UK zip code. e.g. DN3 3NN`});
        }
      }
      else if (validationType === FormFieldValidation.Phone && validationData === PhoneFormat.GB) {
        if (!ukPhone.test(value)) {
          newErrors.push({id: id, message: `${label} should be a valid UK phone number. e.g. 01222 555 555`});
        }
      }
      else if (validationType === FormFieldValidation.Phone && validationData === PhoneFormat.US) {
        if (!usPhone.test(value)) {
          newErrors.push({id: id, message: `${label} should be a valid US phone number. e.g. 430-903-0099`});
        }
      }
      else if (validationType === FormFieldValidation.UsSsn) {
        if (!usSsn.test(value)) {
          newErrors.push({id: id, message: `${label} should be a valid US Social Security Number. e.g. 430-90-0099`});
        }
      }

      if (minValue !== undefined && isNumber && numberValue < minValue) {
        newErrors.push({id: id, message: `${label} has a minimuim value of ${minValue}.`});
      }

      if (maxValue !== undefined && isNumber && numberValue > maxValue) {
        newErrors.push({id: id, message: `${label} has a maximum value of ${maxValue}.`});
      }

      if (minLength !== undefined && minLength !== -1 && value.length < minLength) {
        newErrors.push({id: id, message: `${label} has a minimuim length of ${minLength} characters.`});
      }

      if (maxLength !== undefined && maxLength !== -1 && value.length > maxLength) {
        newErrors.push({id: id, message: `${label} has a maximum length of ${maxLength} characters.`});
      }
    }

    setWarnings(newWarnings);
    setErrors(newErrors);
    if (onFormErrorsChange) {
      onFormErrorsChange({
        [id]: !!newErrors.length
      });
    }

    if (!newErrors.length && onValidValueChange) {
      onValidValueChange(formKey, value);
    }
  }, [formKey, formatDisplay, getFormattedDate, getParsableDate, id, isNumber, label, maxLength, maxValue, minLength, minValue, onFormErrorsChange, onValidValueChange, preferAsuriteEmail, requireAsuriteEmail, required, validationData, validationType, value]);

  return (
    <div className={'form-group'}>
      <label htmlFor={id}><RequiredAsterisk required={required} />{label}{toolTip ? <ToolTip text={toolTip} /> : null}</label>
      {numberOfRows === 1 ? (
        <input
          autoFocus={autoFocus}
          id={id}
          inputMode={'text'}
          required={required}
          value={value || ''}
          onChange={(event) => updateValue(event.target.value)}
          disabled={disabled}
          onBlur={() => setFieldAttempted(true)}
          className={errors.length && fieldAttempted ? 'error-field' : ''}
        />
      ) : (
        <textarea
          autoFocus={autoFocus}
          id={id}
          inputMode={'text'}
          required={required}
          value={value || ''}
          onChange={(event) => updateValue(event.target.value)}
          disabled={disabled}
          onBlur={() => setFieldAttempted(true)}
          rows={numberOfRows}
          className={errors.length && fieldAttempted ? 'error-field' : ''}
        />
      )}
      {!disabled ? (
        <>
          <FieldAlerts alerts={warnings} role={FieldAlertsRole.Warning} />
          <FieldAlerts alerts={errors} showAlerts={fieldAttempted} />
        </>
      ) : null}
    </div>
  );
};

export { TextField }