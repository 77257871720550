import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {IntegrationProvider} from "../../components/TemplateIntegration/integration-store";
import TemplateIntegration from "../../components/TemplateIntegration/TemplateIntegration";
import {useGetAdobeTemplate} from "../../hooks/use-templates";
import Breadcrumbs from "../../components/Breadcrumbs";
import { FormDataMappingMode } from '../../hooks/use-templates/enums';
import { CenterMessage } from '../../components/CommonElements';
import { PageTitle } from '../../components/Layout';
import { Template, TemplateConfig } from 'asu-types';
import { useAuth } from '../../auth/controller';

interface LocationState {
  templateId: string;
}

const Create = () => {
  const location = useLocation();
  const {templateId} = location.state as LocationState || '';
  const template = useGetAdobeTemplate(templateId);
  const {userEmail} = useAuth();

  if (!templateId) {
    return <Navigate to={`/templates`} />
  }

  let templateData = new Template({
    formDataMappingMode: FormDataMappingMode.ODS,
    enabled: true,
    config: new TemplateConfig({
      senderEmail: userEmail
    })
  });

  if (template.status === 'success' && template.data) {
    templateData.adobeSignId = template.data.id;
    templateData.name = template.data.name;
  }

  return (
    <>
      <Breadcrumbs currentPageTitle={'Create New Integration'} parentPage="Templates" />
      <main>
        <PageTitle>Create New Template Integration</PageTitle>
        {template.status === 'loading' ? (
          <CenterMessage message='Loading template integration...' />
        ) : template.status === 'success' && template.data ? (
          <IntegrationProvider>
            <TemplateIntegration templateData={templateData} isNew={true}/>
          </IntegrationProvider>
        ): (
          <div>Failed to load template integration</div>
        )}
      </main>
    </>
  );
}

export { Create };