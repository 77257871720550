import { ConditionalRoutingMember } from "./conditional-routing-member";

export class RecipientConfig {
  label: string;
  conditionalMembers: ConditionalRoutingMember[];

  constructor(data: RecipientConfig) {
    this.label = data.label;
    this.conditionalMembers = data.conditionalMembers;
  }
}