import React, {useState} from 'react';
import {useIntegrationDispatch, useIntegrationStore} from "../integration-store";
import {TextButton, ToolTipButton, ToolTipIconButton} from "../../Button";
import styled from "styled-components";
import {CancelIcon, DeleteIcon, SaveIcon} from "../../ActionIcon";
import { setFocusOn } from '../../../utils/document-interactions';

const DisplayOwnerRow = styled.div`
  display: flex;
  gap: 10px;
  input {
    width: 50%; 
    margin-bottom: 3px;
  }
`;

const AddOwnerFormContainer = styled.div`
  display: flex; 
  gap: 10px;
  input {
    width: 50%;
  }
  
  .form-buttons {
    display: flex; 
    gap: 15px; 
    justify-content: space-between;
  }
`;

const ConfirmDeletePrompt = styled.div`
  display: flex; 
  align-items: center; 
  gap: 20px;
`;

const Owners = () => {
  const dispatch = useIntegrationDispatch();
  const {config} = useIntegrationStore();
  const owners = config.owners || [];
  const [addOwner, setAddOwner] = useState(false);
  const [ownerEmail, setOwnerEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<null|string>(null);
  const [confirmDelete, setConfirmDelete] = useState(-1);

  const resetAddOwner = () => {
    setFocusOn('add-owner');
    setAddOwner(false);
    setOwnerEmail('');
    setErrorMessage(null);
  }

  const saveNewOwner = () => {
    if (ownerEmail.trim() === '') {
      setErrorMessage('Cannot be empty');
    } else {
      owners.push(ownerEmail);
      dispatch({type: 'setConfig', payload: {
        config: {
          ...config,
          'owners': owners
        }
      }});
      resetAddOwner();
    }
  }

  const deleteOwner = (index: number) => {
    setConfirmDelete(-1);
    owners.splice(index, 1);
    dispatch({type: 'setConfig', payload: {
      config: {
        ...config,
        'owners': owners
      }
    }});
  }

  const cancelDelete = () => {
    setFocusOn(`owner-${confirmDelete}-delete-button`);
    setConfirmDelete(-1);
  }

  return (
    <div className={'form-group'}>
      <label aria-label={'Owners'} id='owners-label'>Owners: </label>
      {owners.map((owner: string, index: number) => {
        return (
          <DisplayOwnerRow key={index+'div'}>
            <input
              id={`owner-${index}-field`}
              key={owner+'-'+index}
              inputMode={'text'}
              value={owner}
              readOnly
              aria-labelledby='owners-label'
            />
            { confirmDelete === index ? (
                <ConfirmDeletePrompt>
                  <div>Remove owner {owner}?</div>
                  <TextButton autoFocus textColor={'orange'} onClick={() => deleteOwner(index)} aria-label={`Confirm remove owner ${owner}`}>Yes</TextButton>
                  <TextButton textColor={'blue'} onClick={cancelDelete} aria-label={`Cancel remove owner ${owner}`}>No</TextButton>
                </ConfirmDeletePrompt>
              ) : (
                <ToolTipIconButton
                  id={`owner-${index}-delete-button`}
                  key={owner+'-'+index+'-delete'}
                  onClick={() => setConfirmDelete(index)}
                  toolTip={`Remove owner ${owner}`}
                ><DeleteIcon /></ToolTipIconButton>
              )}
          </DisplayOwnerRow>
        )
      })}
      {addOwner ? (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <AddOwnerFormContainer>
              <input
                  autoFocus
                  id={`owner-new-field`}
                  inputMode={'text'}
                  value={ownerEmail}
                  onChange={(event) => setOwnerEmail(event.target.value)}
                  aria-labelledby='owners-label'
              />
              <div className={'form-buttons'}>
                <ToolTipIconButton
                  onClick={saveNewOwner}
                  toolTip={`Save Owner`}
                ><SaveIcon /></ToolTipIconButton>
                <ToolTipIconButton
                  onClick={resetAddOwner}
                  toolTip={`Cancel Adding Owner`}
                ><CancelIcon /></ToolTipIconButton>
              </div>
            </AddOwnerFormContainer>
            {errorMessage ?
                <div className={'error-info'}>{errorMessage}</div>
                : null
            }
          </div>
      ) : null }
      <div className={'add-button-container'}>
        <ToolTipButton
            id='add-owner'
            toolTip='Add an owner to this integration. Owners are simply informational and do not affect functionality.'
            variant={'secondary'}
            className={addOwner ? 'disabled' : ''}
            disabled={addOwner}
            onClick={() => setAddOwner(true)}>Add Owner</ToolTipButton>
      </div>
    </div>
  );
}

export { Owners };