import {useAuth} from "../auth/controller";
import {useLocation} from "react-router-dom";
import { Button } from "../components/Button";

const AdBlock = () => {
  const auth = useAuth();
  const location = useLocation();
  const {lastPage} = location.state as {lastPage?: string} || {lastPage: null};
  auth.storeRedirect(lastPage || '/');

  return (
    <div className={'container'} style={{display: 'flex', flexDirection: 'column', gap: 50}}>
      <div className={'page-message'}>Encountered Error: If you have Adblock installed, please disable it for this site and click refresh below.  If this does not resolve your issue, please reach out to the help desk for further assistance.</div>
      <Button onClick={() => auth.redirectToSignIn()}>Refresh</Button>
    </div>
  )
}

export { AdBlock };