import React, { useEffect } from 'react';
import {useAuth} from "../auth/controller";
import {useLocation} from "react-router-dom";

const Login = () => {
  const auth = useAuth();
  const location = useLocation();
  const {lastPage} = location.state as {lastPage?: string};
  auth.storeRedirect(lastPage || '/');

  useEffect(() => {
    setTimeout(() => {
      auth.redirectToSignIn();
    }, 2000);
  }, [auth]);
  

  return (
      <div className={'container'}>
        <div className={'page-message'}>Unauthenticated. Redirecting to sign in...</div>
      </div>
  )
}

export { Login };