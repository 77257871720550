/**
 * Special logic for participantSet.hasConditionalRouting
 * 
 * When a participant set has conditional routing, we assume that all members
 * that are going to be part of the participant set are configured through conditional
 * routing and therefore we do not add a blank member if it is an empty list of members
 * or if it is a group that could use another member.
 * 
 * We also make sure that all members of a group are required instead of just the first
 * member.
 */

import { FormFieldValidation, MemberInfoBase } from "adobe-sign-types";
import { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { SenderFormMemberInfo, SenderFormParticipantSet } from "../../../hooks/use-templates/types";
import { TextField } from "../../CommonElements/TextField";

const MembersDiv = styled.div`
  padding: 10px;

  h4 {
    margin-top: 0px;
    padding-top: 3px;
  }
`;
interface Props {
  participantSet: SenderFormParticipantSet,
  onMembersChange: (participantLabel: string, memberInfos: SenderFormMemberInfo[]) => void
  onFormErrorsChange: (formError: {[key:string]: boolean}) => void,
  hideNonEditableMembers: boolean
}

const Participant: React.FC<Props> = ({participantSet, onMembersChange, onFormErrorsChange, hideNonEditableMembers}) => {
  const isGroup = participantSet.maxMemberCount > 1;

  const groupNeedsAnotherEditableMemeber = useCallback((memberInfos: MemberInfoBase[]) => {
    return !participantSet.hasConditionalRouting && memberInfos.length < participantSet.maxMemberCount && memberInfos[memberInfos.length - 1].email;
  }, [participantSet.hasConditionalRouting, participantSet.maxMemberCount]);

  // Need to add an empty member for editing if there are no members
  // or participant set is a group and the maxMemberCount has not been reached.
  useEffect(() => {
    if (groupNeedsAnotherEditableMemeber(participantSet.memberInfos)) {
      onMembersChange(participantSet.label!, [...participantSet.memberInfos, {email: '', editable: true}]);
    }
  }, [groupNeedsAnotherEditableMemeber, onMembersChange, participantSet.label, participantSet.memberInfos]);

  const updateMemberEmail = (indexString: string, value: string) => {
    const index = parseInt(indexString);
    const newMembers = [...participantSet.memberInfos];

    newMembers[index].email = value;

    if (groupNeedsAnotherEditableMemeber(newMembers)) {
      newMembers.push({email: '', editable: true});
    }

    onMembersChange(participantSet.label!, newMembers);
  };

  const visibleMembers = useMemo(() => {
    return !hideNonEditableMembers
      ? participantSet.memberInfos
      : participantSet.memberInfos.filter(member => member.editable);
  }, [hideNonEditableMembers, participantSet.memberInfos]);
  
  return (
    <>
      {visibleMembers.length ? (
        <MembersDiv className={isGroup ? 'group' : ''}>
          {isGroup ? <h4>{participantSet.label} Group</h4> : null}
          {visibleMembers.map((memberInfo, index) => {
            const id = `${participantSet.label}-${index}`;
            let labelText: string = isGroup
              ? `Group Member ${index + 1}`
              : participantSet.label!;

            if (participantSet.isSender) {
              labelText = `${labelText} (Please enter your email)`;
            }

            return (
              <TextField
                key={id}
                id={id}
                label={labelText}
                formKey={`${index}`}
                value={memberInfo.email}
                onValueChange={updateMemberEmail}
                required={participantSet.required && (participantSet.hasConditionalRouting || index === 0)}
                validationType={FormFieldValidation.Email}
                onFormErrorsChange={onFormErrorsChange}
                disabled={!memberInfo.editable}
                preferAsuriteEmail={true}
              />
            )
          })}
        </MembersDiv>
      ) : null}
    </>
  );
};

export { Participant };