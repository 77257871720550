export enum Styles {
  AsuMaroon = '#8C1D40',
  AsuBlueDarker = '#007FAD',
  AsuGold = '#FFC627',
  AsuGoldDarker = '#BD8A00',
  AsuOrange = '#FF7F32',
  AsuOrangeDarker = '#CC4E00',
  AsuGray1 = '#191919',
  AsuGray2 = '#484848',
  AsuGray3 = '#747474',
  AsuGray4 = '#BFBFBF',
  AsuGray5 = '#D0D0D0',
  AsuGray6 = '#E8E8E8',
  AsuGray7 = '#FAFAFA',
  AsuWhite = '#FFFFFF',
  AsuBlack = '#000000',
  AsuGreen = '#78BE20',
  AsuGreenDarker = '#4F7E15',
  ErrorRed = '#AD0000'
}