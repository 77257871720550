import './App.css';
import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import {Home, Edit, Create, Login, Logout, Unauthorized} from "./pages";
import RequireAuth from "./auth/RequireAuth";
import {Navbar} from "./components/Navbar";
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";
import styled from "styled-components";
import {TemplatesMain} from './pages/Templates/Main/TemplatesMain';
import { Workflows } from './pages/Workflows/Workflows';
import { WorkflowEdit } from './pages/Workflows/WorkflowEdit';
import { WrongSigner } from './pages/Templates/WrongSigner';
import { SenderFormPage } from './pages/Workflows/SenderFormPage';
import { AuthType } from './auth/client';
import { SsoSenderFormPage } from './pages/Workflows/SsoSenderFormPage';
import { AdBlock } from './pages/AdBlock';
import { TemplateUnavailable } from './pages/Templates/TemplateUnavailable';
import { AgreementsStatus } from './pages/AgreementsStatus';

const Content = styled.div`
  width: 60%;
  max-width: 950px;
  padding-bottom: 100px;
  .page-message {
    padding-top: 100px;
    text-align: center;
  }

  @media only screen and (max-width: 740px) {
    margin: 20px;
    width: 100%;
  }
`;

function App() {
  return (
    <div className="app">
      <Navbar />
      <div className={'container'}>
        <Content>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Routes>
              <Route element={<RequireAuth authType={AuthType.Admin} />}>
                <Route path="/" element={<Home />} />
                <Route path="/templates" element={<TemplatesMain />} />
                <Route path="/template/new" element={<Create />} />
                <Route path="/template/:templateId/edit" element={<Edit />} />
                <Route path="/workflows" element={<Workflows />} />
                <Route path="/workflow/:workflowId/edit" element={<WorkflowEdit />} />
              </Route>
              <Route element={<RequireAuth authType={AuthType.General} />}>
                <Route path="/workflow/:workflowId/sender-form/sso" element={<SsoSenderFormPage />} />
                <Route path="/agreements/status" element={<AgreementsStatus />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/wrong-signer" element={<WrongSigner />} />
              <Route path="/template-unavailable" element={<TemplateUnavailable />} />
              <Route path="/workflow/:workflowId/sender-form" element={<SenderFormPage />} />
              <Route path="/ad-block" element={<AdBlock />} />
              <Route path="*" element={<Navigate to={'/'} replace />}/>
            </Routes>
          </ErrorBoundary>
        </Content>
      </div>
    </div>
  );
}

export default App;
