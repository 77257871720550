import React from 'react';
import {useIntegrationStore} from "../integration-store";

const AdobeSignId = () => {
  const {adobeSignId} = useIntegrationStore();
  return (
    <div className={'form-group'}>
      <label htmlFor={'integration-adobe-sign-id-field'}>Adobe Sign ID: </label>
      <input
        id={"integration-adobe-sign-id-field"}
        inputMode={'text'}
        value={adobeSignId}
        disabled
      />
    </div>
  );
}

export { AdobeSignId };