import { AgreementCreate } from "adobe-sign-types";
import { AgreementCreateResponse, Workflow, WorkflowFullConfig, WorkflowListItem } from "asu-types";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth } from "../../auth/controller";
import { useSessionStorageState } from "../../auth/utils/session-storage";
import { SessionStorageKey } from "../../enums/SessionStorageKey";
import useErrorHandler from "../use-error-handler";

const API_URL = process.env.REACT_APP_ADSIGN_API_URL;

const useGetWorkflows = () => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  return useQuery<WorkflowListItem[], Error>(["workflows"], async () => {
    const {data} = await axios.get(`${API_URL}/workflows`, {
      headers: {
        'Authorization': 'Bearer ' + await auth.getToken()
      }
    });
    return data;
  }, {
    retry: ((failureCount) => failureCount < 1),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onError: errorHandler.handleError
  });
};

const useExportWorkflows = () => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  return useQuery<string, Error>(["exportWorkflows"], async () => {
    const {data} = await axios.get(`${API_URL}/workflows/export?timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, {
      headers: {
        'Authorization': 'Bearer ' + await auth.getToken()
      }
    });
    return data;
  }, {
    onError: errorHandler.handleError,
    enabled: false
  });
};

const useGetWorkflow = (id: string, fromSenderForm: boolean = false) => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  return useQuery<WorkflowFullConfig, Error>(["workflow", id], async () => {
    const {data} = await axios.get(`${API_URL}/workflows/${id}?forSenderForm=${fromSenderForm}`, {
      headers: {
        'Authorization': `Bearer ${auth.authType ? await auth.getToken() : null}`
      }
    }).catch(error => {
      if (error.response.data.includes('not available') && fromSenderForm) {
        throw new Error('This link is no longer available.');
      }
      throw error;
    });
    return data;
  }, {
    retry: ((failureCount) => failureCount < 1),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: errorHandler.handleError
  });
}

const useSaveWorkflow = (id: string) => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();
  const [, setPendingWorkflowSavePayload] =
    useSessionStorageState<WorkflowFullConfig | null>(SessionStorageKey.PendingWorkflowSavePayload, null);
  return useMutation<void, Error, Workflow>( 
    async (mutationData: Workflow) => {
      await axios.put(`${API_URL}/workflows`, mutationData, {
        headers: {
          'Authorization': 'Bearer ' + await auth.getToken()
        }
      }).catch(error => {
        if (error.message.includes('401')) {
          setPendingWorkflowSavePayload(mutationData);
        }
        throw error;
      });
    }, 
    {
      onError: (error) => {
        errorHandler.handleError(error);
        return;
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["workflow", id]);
        queryClient.invalidateQueries(["workflows"]);
      }
    }
  );
}

const useEnableWorkflow = (id: string) => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();
  return useMutation<void, Error, void>( async () =>
    axios.post(`${API_URL}/workflows/${id}/enable`, null, {
      headers: {
        'Authorization': 'Bearer ' + await auth.getToken()
      }
    }), {
      onError: (error) => {
        errorHandler.handleError(error);
        return;
      },
      onSuccess: () => {
        queryClient.removeQueries(["workflow", id]);
        queryClient.invalidateQueries(["workflow", id]);
        queryClient.invalidateQueries(["workflows"]);
      }
    }
  );
}

const useDeleteWorkflow = (id: string) => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();
  return useMutation<void, Error>( async () => axios.delete(`${API_URL}/workflows/${id}`, {
    headers: {
      'Authorization': 'Bearer ' + await auth.getToken()
    }
  }), {
    onError: (error) => {
      errorHandler.handleError(error);
      return;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["workflow", id]);
      queryClient.invalidateQueries(["workflows"]);
    }
  });
}

const useDisableWorkflow = (id: string) => {
  const auth = useAuth();
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();
  return useMutation<void, Error, void>( 
    async () => {
      const {data}: {data: WorkflowFullConfig} = await axios.get(`${API_URL}/workflows/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + await auth.getToken()
        }
      });
      await axios.put(`${API_URL}/workflows`, {...data, enabled: false}, {
        headers: {
          'Authorization': 'Bearer ' + await auth.getToken()
        }
      });
    },
    {
      onError: (error) => {
        errorHandler.handleError(error);
        return;
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["workflow", id]);
        queryClient.invalidateQueries(["workflows"]);
      }
    }
  );
}

const useSendWorkflow = () => {
  const auth = useAuth();
  return useMutation<AgreementCreateResponse, Error, AgreementCreate>( async (mutationData: AgreementCreate) => {
    const {data} = await axios.post(`${API_URL}/workflows/send`, mutationData, {
      headers: {
        'Authorization': `Bearer ${auth.authType ? await auth.getToken() : null}`
      }
    });

    return data as AgreementCreateResponse;
  }, {});
}

export { useGetWorkflows, useGetWorkflow, useSaveWorkflow, useEnableWorkflow, useSendWorkflow, useDisableWorkflow, useDeleteWorkflow, useExportWorkflows };