import { useEffect, useRef, useState } from "react";

const  useSessionStorageState = <T,>(
  key: string,
  defaultValue: T,
  {serialize = JSON.stringify, deserialize = JSON.parse} = {},
) => {
  const [state, setState] = useState(() => {
    const valueInSessionStorage = sessionStorage.getItem(key)
    if (valueInSessionStorage) {
      try {
        return deserialize(valueInSessionStorage)
      } catch (error) {
       sessionStorage.removeItem(key)
      }
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue
  })

  const prevKeyRef = useRef(key)

  useEffect(() => {
    const prevKey = prevKeyRef.current
    if (prevKey !== key) {
      sessionStorage.removeItem(prevKey)
    }
    prevKeyRef.current = key
    sessionStorage.setItem(key, serialize(state))
  }, [key, state, serialize])

  return [state, setState]
}

export {useSessionStorageState};