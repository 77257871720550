import React from 'react';
import styled from 'styled-components';
import { ASUHeader } from '../asu-design-system/component-header';
import {useAuth} from "../auth/controller";
import desktopLogo from "../assets/img/arizona-state-university-logo-vertical.png";
import mobileLogo from "../assets/img/arizona-state-university-logo.png";

const HeaderSection = styled.div`
  header {
    position: sticky;

    .content-container {
      max-height: fit-content;
      min-height: fit-content;
    }

    a {
      cursor: pointer;
    }
  }
`;

const getSsoNameFromCookie = (): string => {
  let name = '';

  const cookies = document.cookie.split('; ');
  if (cookies) {
   let found = cookies.find(row => row.startsWith('SSONAME='))
   if (found) {
     name = found.split('=')[1];
   }
  }

  return name;
}

function Navbar() {
  const { userId } = useAuth();
  const ssoName = getSsoNameFromCookie() || userId;

  return (
    <HeaderSection>
      <ASUHeader
        logo={{
          alt: 'logo',
          src: desktopLogo,
          mobileSrc: mobileLogo,
        }}
        loggedIn={userId}
        userName={ssoName}
        loginLink={'/login'}
        logoutLink={'/logout'}
        title="Adobe Sign Integrations"
      />
    </HeaderSection>
  );
}

export { Navbar };
