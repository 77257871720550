import React from 'react';
import {useIntegrationDispatch, useIntegrationStore} from "../integration-store";

const IntegrationName = () => {
  const dispatch = useIntegrationDispatch();
  const {name, formError} = useIntegrationStore();

  const handleNameChange = (value: string) => {
    validateName(value);
    dispatch({type: 'setIntegrationName', payload: {
      name: value
    }});
  }

  const validateName = (value: string) => {
    dispatch({type: 'setFormError', errorField: {
      name: (value.trim() === "")
    }});
  }

  return (
    <div className={'form-group'}>
      <label htmlFor={'integration-name-field'}>Integration Name: </label>
      <input
        id={"integration-name-field"}
        inputMode={'text'}
        value={name}
        onChange={(event) => handleNameChange(event.target.value)}
        onBlur={() => validateName(name)}
      />
      {formError.name ? <div className={'error-info'}>Name cannot be empty.</div> : null}
    </div>
  );
}

export { IntegrationName };