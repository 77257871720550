import React from 'react';
import {useAuth} from "../auth/controller";
import {Button} from "./Button";

interface Props {
  error: Error
}

const ErrorFallback: React.FC<Props> = ({error}) => {
  const auth = useAuth();
  return (
      <div className={'container'} style={{display: 'flex', flexDirection: 'column', gap: 50}}>
        <div className={'page-message'}>Encountered Error: {error.message}</div>
        <Button onClick={() => auth.redirectToSignIn()}>Refresh App</Button>
      </div>
  )
}

export default ErrorFallback;