import { useMemo } from "react";
import styled from "styled-components";
import { SenderFormMemberInfo, SenderFormParticipantSet } from "../../../hooks/use-templates/types";
import { SectionHeader } from "../../CommonElements";
import { Participant } from "./Participant";

const ParticipantsContainer = styled.div`
  .participant-container {
    border: 1px solid black;
    border-bottom: 0px;
  }

  .participant-container:last-child {
    border-bottom: 1px solid black;
  }
`;

interface Props {
  participantSets: SenderFormParticipantSet[],
  onFormErrorsChange: (formError: {[key:string]: boolean}) => void,
  onMembersChange: (participantLabel: string, memberInfos: SenderFormMemberInfo[]) => void,
  hideNonEditableMembers: boolean
}

const Participants: React.FC<Props> = ({participantSets, onFormErrorsChange, onMembersChange, hideNonEditableMembers}) => {
  const visibleParticipants = useMemo(() => {
    return !hideNonEditableMembers
      ? participantSets.filter(participantSet => participantSet.memberInfos.length)
      : participantSets.filter(participantSet => participantSet.memberInfos.some(member => member.editable));
  }, [hideNonEditableMembers, participantSets]);
  
  return (
    <>
      {visibleParticipants.length ? (
        <>
          <SectionHeader>Participants</SectionHeader>
          <ParticipantsContainer>
            {visibleParticipants.map(participantSet =>
              <div className="participant-container" key={participantSet.label}>
                <Participant
                  participantSet={participantSet}
                  onMembersChange={onMembersChange}
                  onFormErrorsChange={onFormErrorsChange}
                  hideNonEditableMembers={hideNonEditableMembers}
                />
              </div>
            )}
          </ParticipantsContainer>
        </> 
      ) : null}
    </>
  );
};

export { Participants };